import { Record } from 'immutable';
import {
  ProductDTO,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  ExpenseDiscountType,
  ExpenseWorkableType,
  ServiceDTO,
  ServiceModel,
  ProductModel,
  ExpenseDTO,
  ProductMapper,
  ServiceMapper,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IExpenseModelReturnType {
  uuid: string;
  name: string;
  amount: number;
  price: string;
  discount: number;
  total: string;
  discount_type: ExpenseDiscountType;
  comment: string;
  workable_type: ExpenseWorkableType;
  created_at: string;
  updated_at: string;
  workable: ProductDTO | ProductModel | ServiceDTO | ServiceModel;
  created_by: EmployeeModel | EmployeeDTO;
  manager: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class ExpenseModel extends Record<IExpenseModelReturnType>({
  uuid: '',
  name: '',
  amount: 0,
  price: '',
  discount: 0,
  total: '',
  discount_type: ExpenseDiscountType.Fixed,
  comment: '',
  workable_type: ExpenseWorkableType.Product,
  created_at: '',
  updated_at: '',
  workable: {} as ProductModel,
  company: {} as CompanyModel,
  created_by: {} as EmployeeModel,
  manager: {} as EmployeeModel,
}) {
  constructor(
    props: ExpenseDTO = {} as ExpenseDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IExpenseModelReturnType = {
      ...props,
      workable:
        props?.workable_type === ExpenseWorkableType.Product
          ? ProductMapper.toProductModel(props?.workable as ProductDTO)
          : ServiceMapper.toServiceModel(props?.workable as ServiceDTO),
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(props?.manager, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(
              props?.company as unknown as CompanyDTO,
              [WithoutRecursion.profile, ...withoutRecursion],
            ),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }
  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }
}
