import { List } from 'immutable';
import { Tooltip } from 'antd';
import { useAcl } from '@contex';
import styled, { useTheme } from 'styled-components';
import { useStoredCompanies } from '@hooks';
import { useTranslation } from 'react-i18next';
import { isListToArray } from '@services/helpers';
import React, { useCallback, useState } from 'react';
import { PriceItem } from '@components/lib/DataDisplay';
import { Routes, TIME_LIST_SERVICES } from '@services/types';
import { TableLinkCell } from '@components/lib/libV2/DataDisplay';
import { EmployeeServicesEditButtons } from '../../EmployeeService';
import { Table, TableActionCell } from '@components/lib/libV2/DataDisplay';
import { IUseServiceEmployeeListProps } from '@hooks/useServiceEmployeeList';

import {
  EmployeeListModel,
  EmployeeMapper,
  EmployeeModel,
  IEmployeeServiceFormDTOProps,
  ServiceModel,
  CompanyOrderBonusGroupType,
} from '@structure';

export interface IEmployeeServiceListProps {
  employees: List<EmployeeModel> | null;
  loading: boolean;
  handleUpdate: (service: IEmployeeServiceFormDTOProps) => Promise<void>;
  handleAdd: (employee: EmployeeModel) => Promise<void>;
  rowSelection: any;
  handleDelete: (employee: EmployeeModel) => Promise<void>;
  pageSize: number;
  total: number;
  refresh: (
    value: Partial<IUseServiceEmployeeListProps> & { page: number },
  ) => Promise<EmployeeListModel | void>;
  price: string | undefined;
  period: TIME_LIST_SERVICES;
  service: ServiceModel | null;
  page: number;
  daleyFocus: (ms?: number) => void;
}

const StyledPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  & > span:first-of-type {
    margin-right: 5px;
  }
`;

export default function ServiceEmployeeList({
  employees,
  loading,
  handleUpdate,
  handleDelete,
  rowSelection,
  handleAdd,
  total,
  pageSize,
  price,
  refresh,
  period,
  service,
  page,
  daleyFocus,
}: IEmployeeServiceListProps) {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const { employee, service: serviceAccess } = useAcl((acl) => acl);
  const { defaultCompany } = useStoredCompanies();

  const [editingUuid, setEditingUuid] = useState<string>('');

  const isEditingCell = useCallback(
    (serviceEmployee: EmployeeModel): boolean =>
      serviceEmployee.uuid === editingUuid,
    [editingUuid],
  );

  const columns = [
    {
      width: '30%',
      title: t('Employee'),
      key: 'employee',
      render: (serviceEmployee: EmployeeModel) =>
        employee?.read ? (
          <TableLinkCell
            tooltip="Open a detailed description of the employee"
            fullPath={`/${Routes.app}/${Routes.employees}/${serviceEmployee?.uuid}`}>
            {serviceEmployee?.fullName}
          </TableLinkCell>
        ) : (
          serviceEmployee?.fullName
        ),
    },
    {
      width: '25%',
      title: `${t('Base service price')}, ${defaultCompany?.currency_symbol}`,
      key: 'service-price',
      align: 'right' as any,
      render: () => <PriceItem price={Number(price || 0)} />,
      onCell: (serviceEmployee: EmployeeModel) => ({
        inputType: 'number',
        formItemName: 'price',
        title: t('The service`s price'),
        editing: isEditingCell(serviceEmployee),
        autoFocus: true,
      }),
    },
    {
      width: '25%',
      title: `${t('Employee`s service price')}, ${
        defaultCompany?.currency_symbol
      }`,
      key: 'employee-price',
      align: 'right' as any,
      render: (serviceEmployee: EmployeeModel) => {
        let color: any = theme.colors.black;
        if (
          Number(serviceEmployee?.employee_service?.price || 0) > Number(price)
        ) {
          color = theme.colors.green;
        } else if (
          Number(serviceEmployee?.employee_service?.price || 0) < Number(price)
        ) {
          color = theme.colors.accent;
        }

        if (serviceEmployee?.employee_service?.price) {
          return (
            <PriceItem
              color={color}
              price={Number(serviceEmployee?.employee_service?.price || 0)}
            />
          );
        }

        return null;
      },
      onCell: (serviceEmployee: EmployeeModel) => ({
        inputType: 'number',
        formItemName: 'employee-price',
        title: t('The price of employee`s service'),
        editing: isEditingCell(serviceEmployee),
        autoFocus: true,
      }),
    },
    {
      width: '10%',
      align: 'center' as any,
      title: t('Actions'),
      key: 'actions',
      fixed: 'right' as any,
      render: (serviceEmployee: EmployeeModel) => {
        return (
          <TableActionCell>
            <EmployeeServicesEditButtons
              servicePeriod={period}
              disabled={!serviceAccess?.manage}
              loadingSubmit={false}
              item={EmployeeMapper.toEmployeeServiceFormDTO(serviceEmployee)}
              handleEdit={handleUpdate}
              onDelete={() => handleDelete(serviceEmployee)}
              handleAdd={() => handleAdd(serviceEmployee)}
              isOwnService={!!serviceEmployee?.employee_service?.updated_at}
              service={service}
              employeeServiceBonuses={(
                serviceEmployee?.bonus_list || []
              ).filter(({ groups }) =>
                groups?.includes(CompanyOrderBonusGroupType.Service),
              )}
              daleyFocus={daleyFocus}
            />
          </TableActionCell>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={isListToArray(employees as any)}
      columns={columns}
      rowSelection={rowSelection}
      total={total}
      pageSize={pageSize}
      onChange={refresh}
      page={page}
    />
  );
}
