import { Record } from 'immutable';
import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  PriceMarginDTO,
  ProfileMapper,
  ProfileModel,
  ProfileDTO,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IPriceMarginModelReturnType {
  uuid: string;
  price_name: string;
  price_description: string;
  price_margin: number;
  price_is_default: boolean;
  created_at: string;
  updated_at: string;
  created_by: ProfileModel | ProfileDTO;
  company: CompanyModel | CompanyDTO;
}

export class PriceMarginModel extends Record<IPriceMarginModelReturnType>({
  uuid: '',
  price_name: '',
  price_description: '',
  price_margin: 0,
  price_is_default: false,
  created_at: '',
  updated_at: '',
  company: {} as CompanyModel,
  created_by: {} as ProfileModel,
}) {
  constructor(
    props: PriceMarginDTO = {} as PriceMarginDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IPriceMarginModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
          ? props?.created_by
          : ProfileMapper.toProfileModel(props?.created_by, [
              WithoutRecursion.priceMargin,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(
              props?.company as unknown as CompanyDTO,
              [WithoutRecursion.priceMargin, ...withoutRecursion],
            ),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): ProfileModel {
    return this.created_by instanceof ProfileModel
      ? this.created_by
      : ProfileMapper.toProfileModel(this.created_by);
  }
}
