import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import Routes from './routes/Routes';
import { theme } from './themes/styled';
import { useAppInfo } from '@hooks';
import * as appContext from './contex';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import 'moment/locale/uk';
import locale from 'antd/locale/uk_UA';
import { languageCode } from './i18n/i18n';

import 'antd/dist/reset.css';

const { DropdownAlertManager } = appContext || {};

const Locale = {
  [languageCode.uk]: locale,
  [languageCode.en]: undefined,
};

const antdThemeConfig = {
  token: {
    colorPrimary: (theme as any).colors.link,
    colorLink: (theme as any).colors.link,
    // colorInfo: (theme as any).colors.info,
    colorSuccess: (theme as any).colors.link,
    colorWarning: (theme as any).colors.warning,
    colorError: (theme as any).colors.error,
    // colorTextHeading: (theme as any).ant.colorTextHeading,
    colorText: (theme as any).ant.colorText,
    // colorTextSecondary: (theme as any).ant.colorTextSecondary,
    // colorTextDisabled: (theme as any).ant.colorTextDisabled,
    fontSize: 14,
    fontFamily:
      '-apple-system, "system-ui", "Segoe UI", Roboto, "Helvetica Neue"',
  },
  components: {
    Message: {
      // zIndexBase: 100010,
    },
    Modal: {
      zIndexPopupBase: 100,
    },
    Notification: {
      zIndexPopup: 110,
    },
    Popover: {
      zIndexPopup: 120,
    },
    Popconfirm: {
      zIndexPopup: 130,
    },
    Tooltip: {
      zIndexPopup: 500,
    },

    Drawer: {
      zIndexPopup: 100,
    },
    Tour: {
      zIndexPopup: 150,
    },
    DatePicker: {
      zIndexPopup: 500,
    },
    Input: {
      colorBorder: '#d9d9d9',
      colorIcon: (theme as any).colors.primary,
      colorTextPlaceholder: 'rgba(0, 0, 0, 0.80)',
      colorBgBase: 'transparent',
    },
    TreeSelect: {
      colorBorder: '#d9d9d9',
      colorIcon: (theme as any).colors.primary,
      colorTextPlaceholder: 'rgba(0, 0, 0, 0.80)',
      colorBgBase: 'transparent',
    },
    InputNumber: {
      colorBorder: '#d9d9d9',
      colorIcon: (theme as any).colors.primary,
      colorTextPlaceholder: 'rgba(0, 0, 0, 0.80)',
      colorBgBase: 'transparent',
    },
    Layout: {
      colorBgBase: (theme as any).colors.app,
      colorBgContainerDisabled: (theme as any).colors.app,
      bodyBg: 'transparent',
      colorBgHeader: (theme as any).colors.app,
      colorBgTrigger: (theme as any).colors.app,
    },
    Menu: {
      itemBg: 'transparent',
      subMenuItemBg: 'transparent',
      itemHoverBg: (theme as any).colors.link,
      itemActiveBg: (theme as any).colors.link,
      itemSelectedBg: (theme as any).colors.link,
      itemColor: (theme as any).colors.primary,
      itemHoverColor: (theme as any).colors.white,
      itemSelectedColor: (theme as any).colors.white,
      // colorItemTextDisabled: '#f5f5f5',
    },
    Table: {
      // colorBgContainer: '#fff',
      // colorBorderSecondary: 'rgb(225, 229,231)',
      // controlItemBgActive: (theme as any).colors.disabled,
      // controlItemBgActiveHover: (theme as any).colors.disabled,
    },
    Select: {
      // controlItemBgActive: (theme as any).colors.app,
      zIndexPopup: 500,
    },
    Button: {
      boxShadowSecondary: 'none',
      boxShadow: 'none',
      boxShadowTertiary: 'none',
    },
  },
};

export function App() {
  const { i18n, t } = useTranslation();

  useAppInfo();

  useEffect(() => {
    document.title = `.ServicitY: ${t('Corporate accounting system')}`;
  }, [t]);

  return (
    <ConfigProvider
      locale={Locale[(i18n as any).language]}
      theme={antdThemeConfig}>
      <div className="App" data-testid="app">
        {DropdownAlertManager ? (
          <DropdownAlertManager>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </DropdownAlertManager>
        ) : (
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        )}
      </div>
    </ConfigProvider>
  );
}
