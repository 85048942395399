import {
  IPriceTagModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  ProductDTO,
  IProductDTOProps,
  ProductMapper,
  ProductModel,
  ProfileModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IPriceTagDTOProps {
  uuid: string;
  created_at: string;
  updated_at: string;
  product: IProductDTOProps;
  created_by: IProfileDTOProps;
}

export class PriceTagDTO {
  public uuid: string;
  public created_at: string;
  public updated_at: string;
  public product: ProductDTO;
  public created_by: ProfileDTO;

  constructor(
    props: IPriceTagModelReturnType | IPriceTagDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(
            props?.created_by as unknown as ProfileModel,
            [WithoutRecursion.priceTag, ...withoutRecursion],
          );

    this.product =
      withoutRecursion.indexOf(WithoutRecursion.product) !== -1
        ? (props?.product as ProductDTO)
        : ProductMapper.toProductDTO(
            props?.product as unknown as ProductModel,
            [WithoutRecursion.storeDocument, ...withoutRecursion],
          );
  }
}
