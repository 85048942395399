import React from 'react';
import { useAcl } from '@contex';
import { Routes } from '@services/types';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ProductCategoryModel } from '@structure';
import { Link } from '@components/lib/DataDisplay';
import { TbDirectionSignFilled } from 'react-icons/tb';
import { StyledDescription, StyledTitle } from '@components/lib/Styled';

export interface IProductCategoryDetailsViewProps {
  category: ProductCategoryModel | null;
  icon?: React.ReactNode;
  titleColor?: string;
}

const StyledCategoryTitle = styled(StyledTitle)`
  display: block;
  color: ${({ theme }) => theme.colorsV2.black};
`;

const StyledCategoryContainer = styled.div`
  display: block;
`;

const StyledTitleContainer = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

  & > :first-child {
    margin-right: 5px;
  }
`;

export function ProductCategoryDetailsView({
  category,
  icon,
  titleColor,
}: IProductCategoryDetailsViewProps): JSX.Element {
  const { t } = useTranslation();
  const location = useLocation();
  const { manage } = useAcl(({ product }) => product);

  return (
    <StyledCategoryContainer>
      {category ? (
        <>
          {manage ? (
            <Link
              to={`/${Routes.app}/${Routes.goods}/${Routes.categories}/${category?.uuid}`}
              tooltip={t('Go to category details page')}
              state={{ goBack: location.pathname }}>
              <StyledTitleContainer $color={titleColor}>
                {icon || null}
                {category?.title}
                <TbDirectionSignFilled />
              </StyledTitleContainer>
            </Link>
          ) : (
            <StyledCategoryTitle>{category?.title}</StyledCategoryTitle>
          )}
          {category?.parent?.uuid ? (
            <StyledDescription>{`${t('Parent category')}: ${
              category?.parent?.title
            }`}</StyledDescription>
          ) : null}
          {category?.description ? (
            <StyledDescription>{category?.description}</StyledDescription>
          ) : null}
        </>
      ) : null}
    </StyledCategoryContainer>
  );
}
