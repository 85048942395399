import {
  EmployeeDTO,
  EmployeeMapper,
  IEmployeeDTOProps,
  CompanyOrderBonusDTO,
  FinancialSettingsMapper,
  ICompanyOrderBonusDTOProps,
} from '../internal';

import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export enum RewardType {
  Percentage = 'PERCENTAGE',
  Price = 'PRICE',
}

export interface IEmployeeServiceDTOProps {
  price: string;
  duration: number;
  reward_value: number;
  reward_type: RewardType;
  created_at: string;
  updated_at: string;
  employee?: IEmployeeDTOProps;
  bonuses: ICompanyOrderBonusDTOProps[];
}

export class EmployeeServiceDTO {
  public price: number;
  public duration: number;
  public reward_value: number;

  public created_at: string;

  public updated_at: string;

  public reward_type: RewardType;

  public employee?: EmployeeDTO;
  public bonuses: CompanyOrderBonusDTO[];

  constructor(
    props: IEmployeeServiceDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.price = Number(correctPrice(props?.price));
    this.created_at = props.created_at || '';
    this.updated_at = props.updated_at || '';
    this.duration = props.duration || 0;
    this.reward_type = props.reward_type || RewardType.Percentage;
    this.reward_value = props.reward_value || 0;
    this.bonuses = FinancialSettingsMapper.toCompanyOrderBonusListDTO(
      props?.bonuses || [],
    );

    // @ts-ignore
    this.employee = props?.employee
      ? withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? props?.employee
        : EmployeeMapper.toEmployeeDTO(props?.employee as IEmployeeDTOProps, [
            WithoutRecursion.employeeService,
            ...withoutRecursion,
          ])
      : undefined;
  }
}
