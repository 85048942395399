import { ApiAnswer } from '@services/types';
import { EmployeeDTO } from '@structure';

export interface IEmployeeAccruedSalaryStatsDTOProps {
  totalSum: number;
  totalWithPayoutSum: number;
  totalPayoutSumForPeriod: number;
}

export interface IEmployeeAccruedSalaryDTOProps {
  stats: IEmployeeAccruedSalaryStatsDTOProps;
  employee: EmployeeDTO;
}

export interface IEmployeeAccruedSalaryDTOListAnswer
  extends Partial<ApiAnswer> {
  accruals: IEmployeeAccruedSalaryDTOProps[];
}

export class EmployeeAccruedSalaryDTOListAnswer
  implements IEmployeeAccruedSalaryDTOListAnswer
{
  public readonly accruals: IEmployeeAccruedSalaryDTOProps[];

  public readonly total: number;

  constructor(props: IEmployeeAccruedSalaryDTOListAnswer) {
    this.accruals = props?.accruals || [];
    this.total = Number(props?.total || 0);
  }
}

export class EmployeeAccruedSalaryDTO {
  public stats: IEmployeeAccruedSalaryStatsDTOProps;

  public employee: EmployeeDTO;

  constructor(props: IEmployeeAccruedSalaryDTOProps) {
    this.stats = props?.stats;
    this.employee = props?.employee;
  }
}
