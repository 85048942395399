import { IUseRewardListProps } from '@hooks/useRewardList';
import { toDateByFormat } from '@services/helpers';
import { IEmployeeAccruedSalaryDTOListAnswer } from '@structure/dto/EmployeeAccruedSalaryDTO';
import { EmployeeAccruedSalaryMapper } from '@structure/mapper/EmployeeAccruedSalaryMapper';
//import { setRewardList as storeSetRewardList } from '@store/actions';
//import { List } from 'immutable';
import * as React from 'react';
import { IEntityProps, IListSearchProps } from '@services/types';
import { EmployeeAccrualSalaryModel } from '@models/EmployeeAccruedSalaryModel';
import { getEmployeeAccruedSalaryList } from '@services/api/employeeSalaryService';

import {
  useStateReducer,
  useCancellablePromise,
} from '@components/lib/libV2/hooks';

//import { useDispatch } from 'react-redux';

export interface IUseEmployeeSalaryListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
}

export interface IUseEmployeeSalaryListProps
  extends Partial<IListSearchProps>,
    IEntityProps {
  companyUuid: string;
  month?: number;
  year?: number;
  totalSumForPeriod?: number;
}

export interface IUseEmployeeSalaryReturnType {
  error?: null | any;
  loading: boolean;
  stats: any;
  month: number;
  year: number;
  entityList?: EmployeeAccrualSalaryModel[] | null;
  refresh?: (value: Partial<IUseEmployeeSalaryListProps>) => Promise<void>;
  handleSearchRewards: (keywords: string) => Promise<any>;
  handleRefreshRewards: (
    value: Partial<IUseEmployeeSalaryListProps> & {
      page: number;
      totalSumForPeriod?: number;
    },
  ) => Promise<void>;
  total: number;
  limit: number;
  offset: number;
  keywords: string;
  companyUuid: string;
}

export function useEmployeeSalary({
  loadOnInit = true,
  keywords = '',
  limit = 10,
  offset = 0,
  companyUuid,
  month = Number(toDateByFormat(new Date(), 'MM')),
  year = Number(toDateByFormat(new Date(), 'YYYY')),
}: IUseEmployeeSalaryListProps): IUseEmployeeSalaryReturnType {
  //const dispatch = useDispatch<any>();

  const { cancellablePromise, didCancel } = useCancellablePromise();
  const {
    entityList,
    loading,
    error,
    stats,
    limit: listLimit,
    offset: listOffset,
    total: listTotal,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
    month: listMonth,
    year: listYear,
    handleUpdate,
  } = useStateReducer<Omit<IUseEmployeeSalaryReturnType, 'refresh'>>({
    stats: {},
    entityList: null,
    total: 0,
    limit,
    offset,
    keywords,
    companyUuid,
    month,
    year,
    handleSearchRewards: () => Promise.resolve(),
    handleRefreshRewards: () => Promise.resolve(),
  });

  const refresh = React.useCallback(
    async ({
      showLoading = true,
      limit = listLimit,
      offset = listOffset,
      keywords = listKeywords,
      companyUuid = listCompanyUuid,
    }: Partial<IUseEmployeeSalaryListProps> = {}): Promise<void> => {
      try {
        handleUpdate({
          limit,
          offset,
          keywords,
          companyUuid,
          error: null,
          loading: showLoading,
        });

        const { accruals, total } =
          await cancellablePromise<IEmployeeAccruedSalaryDTOListAnswer>(
            getEmployeeAccruedSalaryList({
              companyUuid,
              limit,
              offset,
              keywords,
              month,
              year,
            }),
          );

        if (!didCancel.current) {
          handleUpdate({
            total,
            stats,
            month,
            year,
            loading: false,
            entityList: accruals?.map((item) =>
              EmployeeAccruedSalaryMapper.toEmployeeAccruedSalaryModel(item),
            ),
          });
        }
      } catch (e) {
        if (!didCancel.current) {
          handleUpdate({
            error,
            loading: false,
          });
        }
      }
    },
    [
      cancellablePromise,
      didCancel,
      error,
      month,
      year,
      stats,
      handleUpdate,
      listCompanyUuid,
      listKeywords,
      listLimit,
      listOffset,
    ],
  );

  const handleSearchRewards = React.useCallback(
    async (keywords: string) => {
      handleUpdate({ loading: true });

      const rewardListModel = await refresh({
        offset: 0,
        limit: 10,
        keywords,
      });

      // if (rewardListModel && List.isList(rewardListModel?.rewards)) {
      //   dispatch(
      //     storeSetRewardList(
      //       rewardListModel,
      //       employeeUuid,
      //       keywords,
      //       rewardListModel.total,
      //     ),
      //   );
      // }

      handleUpdate({ loading: false });
    },
    [handleUpdate, refresh],
  );

  const handleRefreshRewards = React.useCallback(
    async ({
      offset = 0,
      limit = 10,
      showLoading = true,
      page,
      month,
      year,
      totalSumForPeriod,
    }: Partial<IUseRewardListProps> & {
      page: number;
      totalSumForPeriod?: number;
    }) => {
      const resultList = await refresh({
        offset,
        limit,
        showLoading,
        month,
        year,
        totalSumForPeriod,
      });

      console.log(
        'Store result --> ',
        {
          offset,
          limit,
          showLoading,
          month,
          year,
        },
        resultList,
      );
      // if (rewardListModel && List.isList(rewardListModel?.rewards)) {
      //   dispatch(
      //     storeSetRewardList(
      //       rewardListModel,
      //       employeeUuid,
      //       storeKeywords,
      //       rewardListModel?.total,
      //       page,
      //     ),
      //   );
      // }
    },
    [refresh],
  );

  React.useEffect(() => {
    if (loadOnInit && companyUuid) {
      (async () =>
        Promise.all([
          handleUpdate({ companyUuid }),
          refresh({ companyUuid }),
        ]))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnInit, companyUuid]);

  return {
    stats,
    loading,
    entityList,
    error: null,
    month: listMonth,
    year: listYear,
    total: listTotal,
    limit: listLimit,
    offset: listOffset,
    handleSearchRewards,
    handleRefreshRewards,
    keywords: listKeywords,
    companyUuid: listCompanyUuid,
  };
}
