import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  ICompanyDTOProps,
  IPriceMarginModelReturnType,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  ProfileModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IPriceMarginDTOProps {
  uuid: string;

  price_name: string;
  price_description: string;
  price_margin: number;
  price_is_default: boolean;
  created_at: string;
  updated_at: string;
  created_by: IProfileDTOProps;
  company: ICompanyDTOProps;
}

export class PriceMarginDTO {
  public uuid: string;

  public price_name: string;

  public price_description: string;

  public price_margin: number;

  public price_is_default: boolean;

  public created_at: string;

  public updated_at: string;

  public created_by: ProfileDTO;

  public company: ICompanyDTOProps;

  constructor(
    props: IPriceMarginModelReturnType | IPriceMarginDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.price_name = props?.price_name || '';
    this.price_description = props?.price_description || '';
    this.price_margin = props?.price_margin || 0;
    this.price_is_default =
      typeof props?.price_is_default === 'boolean'
        ? props?.price_is_default
        : false;

    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(
            props?.created_by as unknown as ProfileModel,
            [WithoutRecursion.priceMargin, ...withoutRecursion],
          );

    // @ts-ignore
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(
            props?.company as unknown as CompanyModel,
            [WithoutRecursion.priceMargin, ...withoutRecursion],
          );
  }
}
