import React, { useCallback, ChangeEvent, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Form, Input, InputNumber, Row, Button, Space } from 'antd';
import { Moment } from 'moment';
import {
  sub,
  compose,
  getPercentage,
  multiply,
  curry,
} from '@services/helpers';
import { useDefaultForm } from '@contex';
import { useClientServiceTransitionHelper } from '@hooks';
import { InputDisabledView } from '@components/lib/Styled';
import { TextFormat } from '@components/lib/Format';
import { DatePicker } from '@components/lib/General';

import './ClientSubscriptionForm.less';

export default withTranslation()(ClientPaymentForm);

export interface IClientSubscriptionFormProps extends WithTranslation {
  isVisible?: boolean;
  editMode?: boolean;
  isDisabledAllFields?: boolean;
  handlePreviousClick?: () => void;
}

function ClientPaymentForm({
  t,
  editMode = false,
  isDisabledAllFields = false,
  handlePreviousClick,
}: IClientSubscriptionFormProps): JSX.Element {
  const {
    valid,
    loadingSubmit,
    getFieldValue,
    handlerUpdateFormState,
    formData,
  } = useDefaultForm();

  const setFieldTotal = useCallback(
    (total: number): void => {
      handlerUpdateFormState({ subscription_total: Number(total) || 0 });
    },
    [handlerUpdateFormState],
  );

  const getTotalValue = useCallback(
    (total: number): number => {
      const discount = getFieldValue('payment_discount') || 0;
      const numberOfPackages =
        getFieldValue('subscription_number_of_packages') || 1;

      return compose<number>(
        multiply(Number(numberOfPackages)),
        sub(Number(total)),
        getPercentage(Number(total)),
      )(Number(discount));
    },
    [getFieldValue],
  );

  const setTotalValue = curry<[any, string | number], void>(
    (setFunc: any, value: string | number): void =>
      compose<void>(setFunc, getTotalValue, Number)(value),
  );

  const getTransitionOfServices = useClientServiceTransitionHelper({
    isStartTransition: editMode,
    handleUpdateTotalField: setTotalValue(setFieldTotal),
  });

  const handleChangePackages = useCallback(
    async (value: number | null): Promise<void> => {
      if (typeof value === 'number') {
        const updated = multiply(value, formData?.total);

        handlerUpdateFormState({ subscription_number_of_packages: value });

        await getTransitionOfServices();

        setFieldTotal(updated);
      }
    },
    [
      handlerUpdateFormState,
      getTransitionOfServices,
      setFieldTotal,
      formData?.total,
    ],
  );

  const handleChangeStartDate = useCallback(
    (value: any): void =>
      handlerUpdateFormState({ subscription_start_date: value }),
    [handlerUpdateFormState],
  );

  const handleChangeDescription = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>): void =>
      handlerUpdateFormState({ payment_comment: event.target.value }),
    [handlerUpdateFormState],
  );

  useEffect(() => {
    setFieldTotal(formData?.total);
  }, [formData?.total, setFieldTotal]);

  return (
    <>
      {valid ? (
        <>
          <Row gutter={24} justify="end">
            <Col span={24}>
              <Form.Item>
                <TextFormat bold>{formData?.serviceTitle}</TextFormat>
                <Button size="small" type="link" onClick={handlePreviousClick}>
                  ( {t('change')} )
                </Button>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('Start date')}
                name="subscription_start_date"
                rules={[
                  {
                    required: true,
                    message: t('Start date must be specified'),
                  },
                ]}
                tooltip={{
                  title: t('Subscription start date'),
                }}>
                <DatePicker
                  showTime
                  className="client-subscription-start-date"
                  data-testid="client-input-start-date"
                  disabled={loadingSubmit || isDisabledAllFields}
                  placeholder={t('Start date')}
                  onChange={handleChangeStartDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('Number of packages')}
                name="subscription_number_of_packages"
                rules={[
                  {
                    required: true,
                    message: t('Number of packages must be specified'),
                  },
                  {
                    type: 'number',
                    min: 1,
                    message: t(
                      'Number of packages must be a number and bigger than 0',
                    ),
                  },
                ]}
                tooltip={{
                  title: t('Number of subscription packages'),
                }}>
                <InputNumber
                  onChange={handleChangePackages}
                  className="client-subscription-packages"
                  data-testid="client-services-packages"
                  disabled={loadingSubmit || isDisabledAllFields}
                  placeholder={t('Enter the number of packages')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('Total')}
                name="subscription_total"
                tooltip={{
                  title: t('Final price including the number of packages'),
                }}>
                <InputDisabledView
                  className="client-subscription-total"
                  data-testid="client-services-total"
                  disabled
                  addonAfter={t('UAH')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Description')}
                name="payment_comment"
                tooltip={{
                  title: t('Admin comment for subscription'),
                }}>
                <Input.TextArea
                  allowClear
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  data-testid="client-subscription-input-description"
                  disabled={loadingSubmit || isDisabledAllFields}
                  placeholder="Enter your comment"
                  onChange={handleChangeDescription}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
