import { Empty } from 'antd';
import { useAcl } from '@contex';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RewardDatePicker } from 'views/Rewards';
import { ListLayout } from '@components/lib/Layout';
import { textToUpperCase } from '@services/helpers';
import styled, { useTheme } from 'styled-components';
import { EmployeeHeader } from 'views/Employees/Show';
import { IEmployeeAccruedSalaryModel } from '@structure';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import { Table, TableLinkCell } from '@components/lib/libV2/DataDisplay';

import {
  useStopLoading,
  useEmployeeSalary,
  useStoredCompanies,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '@hooks';

import {
  HeaderLeft,
  ListActions,
  ListHeader,
  PriceItem,
} from '@components/lib/DataDisplay';

import {
  Routes,
  RoutesAcl,
  RoutesTitle,
  Routes as RoutesType,
} from '@services/types';

const StyledListSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function EmployeeSalaryListPage(): React.JSX.Element {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const navigate = useNavigate();
  const { defaultCompany, defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ employee }) => employee);

  const buildEmployeeViewUrl = useCallback(
    (uuid: string) => `/${Routes.app}/${Routes.employees}/${uuid}`,
    [],
  );
  const buildEmployeeSalaryViewUrl = useCallback(
    (uuid: string) =>
      `/${Routes.app}/${Routes.employees}/${uuid}/${Routes.rewards}`,
    [],
  );

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'employee-salary-list-event',
    disabled: !manage,
  });

  const {
    total,
    stats,
    refresh,
    keywords,
    month,
    year,
    limit: pageSize,
    handleSearchRewards,
    handleRefreshRewards,
    entityList: accruedSalaryList,
    error: errorEmployeeSalary,
    loading: loadingEmployeeSalary,
  } = useEmployeeSalary({ companyUuid: defaultCompanyUuid });

  const columns = [
    {
      title: t('Employee'),
      key: 'employee',
      className: 'reward-list-td',
      render: (item: IEmployeeAccruedSalaryModel) => (
        <StyledTitleContainer>
          <TableLinkCell fullPath={buildEmployeeViewUrl(item?.employee?.uuid)}>
            <EmployeeHeader employee={item?.employee} />
          </TableLinkCell>
        </StyledTitleContainer>
      ),
    },
    {
      title: `${t('Accrued')}, ${defaultCompany?.currency_symbol || 0}`,
      key: 'bonus',
      align: 'right' as any,
      className: 'reward-list-td',
      render: (item: IEmployeeAccruedSalaryModel) => {
        const sum: number = item?.stats?.totalAccrualSum;
        const path: any =
          sum > 0 ? buildEmployeeSalaryViewUrl(item?.employee?.uuid) : null;

        return (
          <PriceItem
            price={sum}
            path={path}
            pathTooltip={t('Go to employee rewards page')}
          />
        );
      },
    },
    {
      title: `${t('Paid of')}, ${defaultCompany?.currency_symbol || 0}`,
      key: 'bonus',
      align: 'right' as any,
      className: 'reward-list-td',
      render: (item: IEmployeeAccruedSalaryModel) => (
        <PriceItem price={item?.stats?.totalPayoutSumOnAccrual} />
      ),
    },
    {
      title: `${t('To pay for')}, ${defaultCompany?.currency_symbol || 0}`,
      key: 'bonus',
      align: 'right' as any,
      className: 'reward-list-td',
      render: (item: IEmployeeAccruedSalaryModel) => {
        const sum: number = item?.stats?.totalAccrualWithPayoutSum;
        const color: string = sum > 0 ? theme.colors.black : theme.colors.error;

        return (
          <PriceItem
            price={sum}
            color={color}
            pathTooltip={t('Go to employee rewards page')}
          />
        );
      },
    },
  ];

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.companies}/${defaultCompanyUuid}`);
  }, [defaultCompanyUuid, navigate]);

  const loading = useStopLoading({
    loading: loadingEmployeeSalary,
    error: errorEmployeeSalary,
    message: 'An error occurred during employee salary loading',
  });

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${Routes.employees}/${Routes.employeeSalary}`,
      breadcrumbName: 'Accrued salary',
    },
  ]);

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  return (
    <ListLayout
      ref={listRef}
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.employeeSalary]))}
      headerRight={null}
      routes={routes}
      empty={
        accruedSalaryList?.length ? null : (
          <Empty
            description={t(
              `It looks like you don't have accrued salary for the employee at the moment`,
            )}>
            Button wrapper
          </Empty>
        )
      }
      headerExtra={
        <ListHeader>
          <StyledListSearchContainer>
            <ListActions
              inputRef={inputRef}
              withoutPicker
              searchText={keywords}
              loading={loading}
              handleSearch={handleSearchRewards}
              inputTooltip={t('Search rewards')}
              inputLabel={t('Search rewards')}>
              {({ fullWidth }) => (
                <RewardDatePicker
                  fullWidth={fullWidth}
                  loading={loading}
                  month={month}
                  year={year}
                  handleRefreshRewards={handleRefreshRewards}
                />
              )}
            </ListActions>
          </StyledListSearchContainer>
        </ListHeader>
      }
      loading={loading}>
      <Table<IEmployeeAccruedSalaryModel>
        rowSelection={undefined}
        total={total}
        pageSize={pageSize}
        dataSource={accruedSalaryList}
        onChange={refresh}
        columns={columns}
        loading={loading}
        onRow={() => ({
          className: 'employee-salary-list--row',
        })}
      />
    </ListLayout>
  );
}
