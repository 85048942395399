import { EmployeeModel } from '../internal';
import { DATE_SHORT_FORMAT } from '@services/const';
import { GENDER, DateType, toDateByFormat, toMoment } from '@services/helpers';

export class EmployeeFormDTO {
  public uuid: string;

  public title: string;

  public email: string;

  public gender: GENDER;

  public phone: string;

  public birthday: DateType;

  public first_name: string;

  public last_name: string;

  public middle_name: string;

  public employment_contract_date: DateType;

  public employment_contract_number: string;

  constructor(props?: EmployeeModel) {
    this.last_name = props?.last_name || '';
    this.middle_name = props?.middle_name || '';
    this.first_name = props?.first_name || '';
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.email = props?.email || '';
    this.gender = props?.gender || GENDER.MALE;
    this.phone = props?.phone || '';
    this.birthday = props?.birthday ? toMoment(props?.birthday) : '';
    this.employment_contract_date = props?.employment_contract_date
      ? toMoment(props?.employment_contract_date)
      : '';
    this.employment_contract_number = props?.employment_contract_number || '';
  }

  birthdayToString(birthday: DateType) {
    return toDateByFormat(birthday, DATE_SHORT_FORMAT);
  }
}
