import { Record } from 'immutable';
import {
  AbonementDTO,
  AbonementGroupStatuses,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  AbonementGroupDTO,
  AbonementModel,
  AbonementMapper,
  IAbonementTimingList,
  AbonementGroupStatusesText,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IAbonementGroupModelReturnType {
  uuid: string;
  group_title: string;
  group_schedule_list: IAbonementTimingList;
  group_comment: string;
  group_status: AbonementGroupStatuses;
  group_status_text: AbonementGroupStatusesText;
  created_at: string;
  updated_at: string;
  manager: EmployeeModel | EmployeeDTO;
  company_abonement: AbonementModel | AbonementDTO;
}

/**
 * @class Abonement
 * @desc Клієнт
 */
export class AbonementGroupModel extends Record<IAbonementGroupModelReturnType>(
  {
    uuid: '',
    group_title: '',
    group_schedule_list: {},
    group_comment: '',
    group_status: AbonementGroupStatuses.Enable,
    group_status_text: AbonementGroupStatusesText.Enable,
    created_at: '',
    updated_at: '',
    manager: {} as EmployeeModel,
    company_abonement: {} as AbonementModel,
  },
) {
  constructor(
    props: AbonementGroupDTO = {} as AbonementGroupDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IAbonementGroupModelReturnType = {
      ...props,

      // @ts-ignore
      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(
              props?.manager as unknown as EmployeeDTO,
              [WithoutRecursion.abonement, ...withoutRecursion],
            ),
      company_abonement:
        withoutRecursion.indexOf(WithoutRecursion.abonement) !== -1
          ? props?.company_abonement
          : AbonementMapper.toAbonementModel(props?.company_abonement, [
              WithoutRecursion.clientAbonement,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }

  get companyAbonementModel(): AbonementModel {
    return this.company_abonement instanceof AbonementModel
      ? this.company_abonement
      : AbonementMapper.toAbonementModel(this.company_abonement);
  }
}
