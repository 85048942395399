import { LoadingOutlined } from '@ant-design/icons';
import { correctPrice } from '@services/helpers';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { BadgeItem } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IEmployeeBadgeSalaryItemProps {
  color?: string;
  loading?: boolean;
  accruedSumForPeriod?: number;
  payoutSumForPeriod?: number;
  totalEmployeeBalance?: number;
  dateOfPayroll?: Date | null;
}

const StyledPaidSumForPeriod = styled.span`
  font-size: 20px;
  font-weight: 500;
  padding-left: 3px;
  color: ${({ theme }) => theme.colors.darkenLink};
`;

export function EmployeeBadgeSalaryItem({
  color,
  dateOfPayroll,
  loading = false,
  accruedSumForPeriod = 0,
  payoutSumForPeriod = 0,
}: IEmployeeBadgeSalaryItemProps): React.JSX.Element {
  const { t } = useTranslation();
  const sumToPayForPeriod: number = useMemo(
    () => Number(accruedSumForPeriod) - Number(payoutSumForPeriod),
    [accruedSumForPeriod, payoutSumForPeriod],
  );

  if (loading) {
    return <LoadingOutlined />;
  }

  const month = dateOfPayroll
    ? t(
        dateOfPayroll
          ?.toLocaleString('default', { month: 'long' })
          ?.toLowerCase() || '',
      )?.toLowerCase()
    : 'N/A';

  return (
    <BadgeItem
      color={color}
      badgeStatsContent={
        <span>
          <Tooltip
            title={t(
              'Accrued "{{sum}}", paid "{{paidSum}}", left to pay "{{leftToPaySum}}" for "{{month}}"',
              {
                month,
                sum: correctPrice(accruedSumForPeriod),
                paidSum: correctPrice(payoutSumForPeriod),
                leftToPaySum: correctPrice(sumToPayForPeriod),
              },
            )}>
            {correctPrice(accruedSumForPeriod)}
          </Tooltip>
        </span>
      }
      badgeSubStatsComment={t('Accrued for {{month}}', { month })}
    />
  );
}
