import { Record } from 'immutable';
import { WithoutRecursion } from '../type';

import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  CashBoxDTO,
  CashBoxType,
  DepartmentModel,
  DepartmentDTO,
  DepartmentMapper,
} from '../internal';

interface IBaseCashBoxNotificationOptions {
  recipient_list?: any[];
}

interface IBaseCashBoxAutoTransferOptions {
  cashboxes?: any[];
}

export interface ICashBoxModelReturnType {
  uuid: string;
  box_type: CashBoxType;
  box_title: string;
  box_comment: string;
  box_utc_time_to_closed_at: string;
  box_notification_options?: IBaseCashBoxNotificationOptions;
  box_auto_transfer_options?: IBaseCashBoxAutoTransferOptions;
  box_date_and_time_to_closed_at?: string | null;
  box_is_email_notification_enabled?: boolean;
  box_pos: number;
  box_can_move_money_from: boolean;
  box_is_closed_automatically: boolean;
  box_can_move_money_to: boolean;
  box_can_in_money_to: boolean;
  box_can_out_money_from: boolean;
  box_is_default_for_company: boolean;
  box_balance: number;
  created_at: string;
  updated_at: string;
  created_by: EmployeeModel | EmployeeDTO;
  cashier: EmployeeModel | EmployeeDTO;
  company: CompanyModel | CompanyDTO;
  box_fiscal_cashbox_id: string;
  box_is_virtual: boolean;
  box_is_locational: boolean;
  location: DepartmentModel | DepartmentDTO;
}

/**
 * @class Product
 * @desc Клієнт
 */
export class CashBoxModel extends Record<ICashBoxModelReturnType>({
  uuid: '',
  box_type: CashBoxType.Cash,
  box_title: '',
  box_comment: '',
  box_utc_time_to_closed_at: '17:00',
  box_notification_options: {},
  box_auto_transfer_options: {},
  box_date_and_time_to_closed_at: null,
  box_pos: 0,
  box_can_move_money_from: false,
  box_is_closed_automatically: false,
  box_can_move_money_to: false,
  box_can_in_money_to: false,
  box_can_out_money_from: false,
  box_is_default_for_company: false,
  box_balance: 0,
  created_at: '',
  updated_at: '',
  company: {} as CompanyModel,
  created_by: {} as EmployeeModel,
  cashier: {} as EmployeeModel,
  box_fiscal_cashbox_id: '',
  box_is_virtual: false,
  box_is_locational: false,
  location: {} as DepartmentModel,
}) {
  constructor(
    props: CashBoxDTO = {} as CashBoxDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: ICashBoxModelReturnType = {
      ...props,
      created_by:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.created_by
          : EmployeeMapper.toEmployeeModel(props?.created_by, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      location:
        withoutRecursion.indexOf(WithoutRecursion.department) !== -1
          ? props?.location
          : DepartmentMapper.toDepartmentModel(props?.location, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      cashier:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.cashier
          : EmployeeMapper.toEmployeeModel(props?.cashier, [
              WithoutRecursion.employee,
              ...withoutRecursion,
            ]),
      // @ts-ignore
      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(
              props?.company as unknown as CompanyDTO,
              [WithoutRecursion.profile, ...withoutRecursion],
            ),
    };

    super(options);
  }

  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }

  get createdByModel(): EmployeeModel {
    return this.created_by instanceof EmployeeModel
      ? this.created_by
      : EmployeeMapper.toEmployeeModel(this.created_by);
  }
  get cashierModel(): EmployeeModel {
    return this.cashier instanceof EmployeeModel
      ? this.cashier
      : EmployeeMapper.toEmployeeModel(this.cashier);
  }

  get locationModel(): DepartmentModel {
    return this.location instanceof DepartmentModel
      ? this.location
      : DepartmentMapper.toDepartmentModel(this.location);
  }
}
