import React, { Suspense } from 'react';
import { EmployeeFormDTO } from '@structure';
import { useTranslation } from 'react-i18next';
import { useModal } from '@components/lib/libV2/hooks';

import {
  AddButton,
  IAddButtonProps,
  SuspenseEmpty,
} from '@components/lib/DataDisplay';

const EmployeeSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeSideWindow'),
);

export interface IEmployeeCreateButton extends IAddButtonProps {
  loading: boolean;
  onSuccess: (value: EmployeeFormDTO) => Promise<void>;
  onCancel?: () => void;
  title?: string;
}

export default function EmployeeCreateButton({
  loading = false,
  onSuccess,
  onCancel,
  ...rest
}: IEmployeeCreateButton) {
  const { t } = useTranslation();

  const { handleCancel, handleOnInit, handleSuccess, visible } = useModal({
    onCancel,
    onSuccess,
  });

  return (
    <>
      <AddButton
        tooltipTitle={t('Create a company employee')}
        onClick={handleOnInit}
        loading={loading}
        {...rest}
      />

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeSideWindowLazy
          onSuccess={handleSuccess}
          visible={visible}
          onCancel={handleCancel}
        />
      </Suspense>
    </>
  );
}
