import { IEmployeeListStatsDTOProps } from '@services/api/employee';
import {
  EmployeeShiftFormDTO,
  IEmployeeShiftFormDTOModel,
} from '@structure/dto/EmployeeShiftFormDTO';

import { List } from 'immutable';
import { WithoutRecursion } from '../type';
import { listToArray } from '@services/helpers';
import { EmployeeSalaryAndBonusesFormDTO } from '../dto/EmployeeSalaryAndBonusesFormDTO';

import {
  EmployeeDTO,
  EmployeeFormDTO,
  ServiceParametersFormDTO,
  IEmployeeDTOProps,
  ServiceModel,
  EmployeeModel,
  EmployeeListModel,
  EmployeeInviteFormDTO,
  EmployeeUserDataFormDTO,
  EmployeeRoleFormDTO,
  EmployeeSalaryDTO,
  EmployeeSalaryModel,
  IEmployeeSalaryDTOProps,
  EmployeeSalaryListModel,
  EmployeeSalaryFormDTO,
  EmployeeAccessManagementFormDTO,
} from '../internal';

import {
  EmployeeShiftDTO,
  IEmployeeShiftDTOModel,
  IEmployeeShiftDTOProps,
} from '../dto/EmployeeShiftDTO';

import {
  EmployeeShiftModel,
  IEmployeeShiftModelProps,
} from '@models/EmployeeShiftModel';

export interface IEmployeeListDTO {
  total: number;
  employees: EmployeeDTO[];
  stats?: IEmployeeListStatsDTOProps;
}

export interface IEmployeeSalaryListDTO {
  salary: EmployeeSalaryDTO[];
  total: number;
}

export class EmployeeMapper {
  public static toEmployeeDTO(
    employee: EmployeeModel | IEmployeeDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeDTO {
    return new EmployeeDTO(employee, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeShiftDTO(
    employeeShift: IEmployeeShiftDTOProps,
  ): IEmployeeShiftDTOModel {
    return new EmployeeShiftDTO(employeeShift);
  }

  public static toEmployeeShiftFormDTO(
    employeeShift?: IEmployeeShiftDTOModel,
  ): IEmployeeShiftFormDTOModel {
    return new EmployeeShiftFormDTO({
      shift_status: employeeShift?.status,
      shift_open_date: employeeShift?.openedAt,
      shift_comment: employeeShift?.comment,
      shift_number: employeeShift?.num,
      uuid: employeeShift?.uuid,
    });
  }

  public static toEmployeeShiftModel(
    employeeShift: IEmployeeShiftModelProps,
  ): EmployeeShiftModel {
    return new EmployeeShiftModel(employeeShift);
  }

  public static toEmployeeShiftModelFromDTO(
    employeeShiftDTO: IEmployeeShiftDTOModel,
  ): EmployeeShiftModel {
    return new EmployeeShiftModel({
      uuid: employeeShiftDTO.uuid,
      shift_comment: employeeShiftDTO.comment,
      shift_number: employeeShiftDTO.num,
      shift_status: employeeShiftDTO.status,
      shift_open_date: employeeShiftDTO.openedAt
        ? new Date(employeeShiftDTO.openedAt?.toISOString())
        : undefined,
    });
  }

  public static toEmployeeListDTO(
    employees: List<EmployeeModel> | IEmployeeDTOProps[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats: IEmployeeListStatsDTOProps = {},
  ): IEmployeeListDTO {
    const employeeList = List.isList(employees)
      ? listToArray<EmployeeModel>(employees)
      : employees;

    return {
      employees: employeeList?.map((employee) =>
        EmployeeMapper.toEmployeeDTO(employee, [
          WithoutRecursion.employee,
          ...withoutRecursion,
        ]),
      ),
      stats,
      total,
    };
  }

  public static toEmployeeModel(
    employeeDTO: EmployeeDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ): EmployeeModel {
    return new EmployeeModel(employeeDTO, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeListModel(
    employeeDTOs: EmployeeDTO[],
    total: number,
    withoutRecursion: WithoutRecursion[] = [],
    stats: IEmployeeListStatsDTOProps = {},
  ): EmployeeListModel {
    return new EmployeeListModel({ employees: employeeDTOs, total, stats }, [
      WithoutRecursion.employee,
      ...withoutRecursion,
    ]);
  }

  public static toEmployeeFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeFormDTO {
    return new EmployeeFormDTO(employeeModel);
  }

  public static toEmployeeAccessManagementFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeAccessManagementFormDTO {
    return new EmployeeAccessManagementFormDTO(employeeModel);
  }

  public static toEmployeeSalaryAndBonusesFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeSalaryAndBonusesFormDTO {
    return new EmployeeSalaryAndBonusesFormDTO(employeeModel);
  }

  public static toEmployeeInviteFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeInviteFormDTO {
    return new EmployeeInviteFormDTO(employeeModel);
  }
  public static toEmployeeUserDataFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeUserDataFormDTO {
    return new EmployeeUserDataFormDTO(employeeModel);
  }

  public static toEmployeeRoleFormDTO(
    employeeModel?: EmployeeModel,
  ): EmployeeRoleFormDTO {
    return new EmployeeRoleFormDTO(employeeModel);
  }

  public static toEmployeeServiceFormDTO(
    employee: EmployeeModel | ServiceModel,
  ): ServiceParametersFormDTO {
    return new ServiceParametersFormDTO(employee);
  }

  public static toEmployeeSalaryDTO(
    salary: EmployeeSalaryDTO | EmployeeSalaryModel,
  ): EmployeeSalaryDTO {
    return new EmployeeSalaryDTO(salary);
  }

  public static toEmployeeSalaryListDTO(
    salary: List<EmployeeSalaryModel> | IEmployeeSalaryDTOProps[],
    total: number,
  ): IEmployeeSalaryListDTO {
    const salaryList = List.isList(salary)
      ? listToArray<EmployeeSalaryModel>(salary)
      : salary;

    return {
      salary: salaryList?.map((sl) => EmployeeMapper.toEmployeeSalaryDTO(sl)),
      total,
    };
  }

  public static toEmployeeSalaryModel(
    salaryDTO: EmployeeSalaryDTO,
  ): EmployeeSalaryModel {
    return new EmployeeSalaryModel(salaryDTO);
  }

  public static toEmployeeSalaryListModel(
    salaryDTOs: EmployeeSalaryDTO[],
    total: number,
  ): EmployeeSalaryListModel {
    return new EmployeeSalaryListModel({ salary: salaryDTOs, total });
  }

  public static toEmployeeSalaryFormDTO(
    salary: EmployeeSalaryModel,
  ): EmployeeSalaryFormDTO {
    return new EmployeeSalaryFormDTO(salary);
  }
}
