import { Record } from 'immutable';
import {
  AbonementDTO,
  AbonementStatuses,
  AbonementStatusesText,
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  IAbonementTimingList,
  MaxPausedPeriodType,
  ServiceDTO,
  ServiceMapper,
  ServiceModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export interface IAbonementModelReturnType {
  uuid: string;
  abon_title: string;
  abon_period_amount: number;
  abon_price: string;
  abon_session_price: string;
  abon_can_timing_be_limited: boolean;
  abon_max_paused_period_type: MaxPausedPeriodType;
  abon_timing_list: IAbonementTimingList;
  abon_group_schedule: IAbonementTimingList;
  abon_can_visiting_be_limited: boolean;
  abon_visiting_limit: number;
  abon_can_move_unused_visiting: boolean;
  abon_can_refund_money: boolean;
  abon_can_be_paused: boolean;
  abon_max_paused_period: number;
  abon_manager_required: boolean;
  abon_comment: string;
  abon_status: AbonementStatuses;
  abon_status_text: AbonementStatusesText;
  created_at: string;
  updated_at: string;
  manager: EmployeeModel | EmployeeDTO;
  service: ServiceModel | ServiceDTO;
  company: CompanyModel | CompanyDTO;
}

/**
 * @class Abonement
 * @desc Клієнт
 */
export class AbonementModel extends Record<IAbonementModelReturnType>({
  uuid: '',
  abon_title: '',
  abon_period_amount: 1,
  abon_price: '0.00',
  abon_session_price: '0.00',
  abon_can_timing_be_limited: false,
  abon_max_paused_period_type: MaxPausedPeriodType.Day,
  abon_timing_list: {},
  abon_group_schedule: {},
  abon_can_visiting_be_limited: false,
  abon_visiting_limit: 1,
  abon_can_move_unused_visiting: false,
  abon_can_be_paused: false,
  abon_max_paused_period: 1,
  abon_manager_required: false,
  abon_can_refund_money: true,
  abon_comment: '',
  abon_status: AbonementStatuses.Enable,
  abon_status_text: AbonementStatusesText.Enable,
  created_at: '',
  updated_at: '',
  manager: {} as EmployeeModel,
  service: {} as ServiceModel,
  company: {} as CompanyModel,
}) {
  constructor(
    props: AbonementDTO = {} as AbonementDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    const options: IAbonementModelReturnType = {
      ...props,

      // @ts-ignore
      service:
        withoutRecursion.indexOf(WithoutRecursion.service) !== -1
          ? props?.service
          : ServiceMapper.toServiceModel(
              props?.service as unknown as ServiceDTO,
              [WithoutRecursion.abonement, ...withoutRecursion],
            ),

      // @ts-ignore
      manager:
        withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
          ? props?.manager
          : EmployeeMapper.toEmployeeModel(
              props?.manager as unknown as EmployeeDTO,
              [WithoutRecursion.abonement, ...withoutRecursion],
            ),

      company:
        withoutRecursion.indexOf(WithoutRecursion.company) !== -1
          ? props?.company
          : CompanyMapper.toCompanyModel(props?.company, [
              WithoutRecursion.abonement,
              ...withoutRecursion,
            ]),
    };

    super(options);
  }

  get serviceModel(): ServiceModel {
    return this.service instanceof ServiceModel
      ? this.service
      : ServiceMapper.toServiceModel(this.service);
  }

  get managerModel(): EmployeeModel {
    return this.manager instanceof EmployeeModel
      ? this.manager
      : EmployeeMapper.toEmployeeModel(this.manager);
  }
  get companyModel(): CompanyModel {
    return this.company instanceof CompanyModel
      ? this.company
      : CompanyMapper.toCompanyModel(this.company);
  }
}
