import {
  EmployeeMapper,
  EmployeeAccrualSalaryModel,
  IEmployeeAccruedSalaryModel,
  IEmployeeAccruedSalaryModelProps,
} from '@structure';

import {
  EmployeeAccruedSalaryDTO,
  IEmployeeAccruedSalaryDTOProps,
} from '@structure/dto/EmployeeAccruedSalaryDTO';

export class EmployeeAccruedSalaryMapper {
  public static toEmployeeAccruedSalaryModel(
    accrualDTO: IEmployeeAccruedSalaryDTOProps,
  ): IEmployeeAccruedSalaryModel {
    return new EmployeeAccrualSalaryModel({
      employee: EmployeeMapper.toEmployeeModel(accrualDTO?.employee),
      stats: {
        totalAccrualSum: Number(accrualDTO?.stats?.totalSum || 0),
        totalPayoutSumOnAccrual: Number(
          accrualDTO?.stats?.totalPayoutSumForPeriod || 0,
        ),
        totalAccrualWithPayoutSum: Number(
          accrualDTO?.stats?.totalWithPayoutSum || 0,
        ),
      },
    });
  }

  public static toEmployeeAccruedSalaryDTO(
    item: IEmployeeAccruedSalaryModelProps,
  ): EmployeeAccruedSalaryDTO {
    return new EmployeeAccruedSalaryDTO({
      stats: {
        totalWithPayoutSum: item.stats?.totalAccrualWithPayoutSum,
        totalPayoutSumForPeriod: item?.stats?.totalPayoutSumOnAccrual,
        totalSum: item?.stats?.totalAccrualSum,
      },
      employee: EmployeeMapper.toEmployeeDTO(item.employee),
    });
  }
}
