import { WithoutRecursion } from '../type';
import { toFloat, correctPrice } from '@services/helpers';

import {
  IScheduleCalendarModelReturnType,
  ClientMapper,
  EmployeeMapper,
  IScheduleCalendarParentDTOProps,
  ScheduleCalendarParentDTO,
  IScheduleCalendarLatestStatusDTOProps,
  ScheduleCalendarLatestStatusDTO,
  StatusMapper,
  IProfileDTOProps,
  ProfileDTO,
  ProfileMapper,
  IClientDTOProps,
  ClientDTO,
  EmployeeDTO,
  IEmployeeDTOProps,
  ProfileModel,
  ScheduleCalendarLatestStatusModel,
  ClientModel,
  EmployeeModel,
} from '../internal';

export enum ScheduleDocumentType {
  PreInvoice = 'pre-invoice',
  ActCompletedWork = 'act-completed-work',
  Rewards = 'rewards',
}

export enum ScheduleStatusesText {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  NEW = 'NEW',
}

export interface IScheduleCalendarDTOProps {
  scheduled_date: string;
  schedule_title: string;
  status: string;
  uuid: string;
  updated_at: string;
  created_at: string;
  status_text: ScheduleStatusesText;
  price: string;
  schedule_number: string;
  status_color: string;
  sum_total: number;
  schedule_expenses_sum: string;
  comment: string;
  is_child: boolean;
  schedule_has_strict_mode: boolean;
  employee: IEmployeeDTOProps;
  client: IClientDTOProps;
  parent: IScheduleCalendarParentDTOProps;
  latest_status: IScheduleCalendarLatestStatusDTOProps;
  created_by: IProfileDTOProps;
  sum_paid: string;
}

export class ScheduleCalendarDTO {
  public scheduled_date: string;
  public schedule_title: string;

  public status: string;

  public uuid: string;

  public updated_at: string;

  public created_at: string;

  public status_text: ScheduleStatusesText;

  public price: string;

  public status_color: string;

  public schedule_number: string;

  public sum_total: number;

  public sum_related: string;

  public schedule_expenses_sum: string;
  public comment: string;

  public is_child: boolean;
  public schedule_has_strict_mode: boolean;

  public employee: EmployeeDTO;

  public client: ClientDTO;
  public parent: ScheduleCalendarParentDTO;
  public latest_status: ScheduleCalendarLatestStatusDTO;
  public created_by: ProfileDTO;
  public sum_paid: string;

  constructor(
    props: IScheduleCalendarModelReturnType | IScheduleCalendarDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.scheduled_date = props?.scheduled_date || '';
    this.schedule_title = props?.schedule_title || '';
    this.status = props?.status || '';
    this.uuid = props?.uuid || '';
    this.updated_at = props?.updated_at || '';
    this.created_at = props?.created_at || '';
    this.status_text = props?.status_text || ScheduleStatusesText.NEW;
    this.status_color = props?.status_color || '';
    this.sum_total = props?.sum_total || 0;
    this.price = toFloat(props?.price || 0);
    this.schedule_number = props?.schedule_number || '';
    this.comment = props?.comment || '';
    this.is_child = props?.is_child || false;
    this.schedule_has_strict_mode = props?.schedule_has_strict_mode || false;
    this.parent = new ScheduleCalendarParentDTO(props?.parent as any);
    this.schedule_expenses_sum = toFloat(props?.schedule_expenses_sum || 0);
    this.sum_paid = toFloat(props?.sum_paid || 0);
    this.sum_related = correctPrice(
      Number(props?.sum_total || 0) -
        Number(props?.price || 0) -
        Number(props?.schedule_expenses_sum || 0),
    );
    this.employee = withoutRecursion.indexOf(WithoutRecursion.employee)
      ? (props?.employee as EmployeeDTO)
      : EmployeeMapper.toEmployeeDTO(
          props?.employee as unknown as EmployeeModel,
          [WithoutRecursion.scheduleCalendar, ...withoutRecursion],
        );
    this.client = withoutRecursion.indexOf(WithoutRecursion.client)
      ? (props?.client as ClientDTO)
      : ClientMapper.toClientDTO(
          props?.client as unknown as ClientModel,
          false,
          [WithoutRecursion.scheduleCalendar, ...withoutRecursion],
        );

    this.latest_status = withoutRecursion.indexOf(WithoutRecursion.status)
      ? (props?.latest_status as ScheduleCalendarLatestStatusDTO)
      : StatusMapper.toScheduleCalendarLatestStatusDTO(
          props?.latest_status as unknown as ScheduleCalendarLatestStatusModel,
        );
    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(
            props?.created_by as unknown as ProfileModel,
            [WithoutRecursion.clientAbonement, ...withoutRecursion],
          );
  }
}
