import React, { useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { IDefaultTabPanelProps } from '../../../General';
import { OverviewRow } from '../../../Styled';
import {
  AclLayout,
  IAclLayoutProps,
  SystemModulesLayout,
} from '../../../Layout';
import { IEditFormProps } from '@services/types';
import OverviewLayoutDetails from './OverviewLayoutDetails';
import styled, { css } from 'styled-components';
import { AppHeader, IAppHeaderProps } from '../../AppHeader';
import { HEADER_HEIGHT } from '../../const';
import EmptyLayout from '../../EmptyLayout';
import { LoadingOutlined } from '@ant-design/icons';
import { IModulesMap, useSize } from '@hooks';
import { bigPhoneScreen } from '@services/const';

export default withTranslation()(OverviewLayout);

type UnderTheHeadingChildrenFn = (children: React.ReactNode) => React.ReactNode;

type ExtraFuncType = (disabled: boolean) => React.ReactNode[];

export interface IOverviewLayoutProps<T>
  extends WithTranslation,
    Partial<Pick<IDefaultTabPanelProps, 'tabs' | 'onTabChange'>>,
    Pick<IAclLayoutProps, 'aclItem'>,
    IAppHeaderProps {
  image?: string;
  data: T | null;
  editForm?: (value: IEditFormProps) => React.ReactNode;
  loading: boolean;
  onUploadFile?: (file: File) => Promise<string>;
  showImageUploader?: boolean;
  withImageUploaderContainer?: (node: React.ReactNode) => React.ReactNode;
  editTooltip?: string;
  headerTitle?: string | React.ReactNode;
  header?: string | React.ReactNode;
  underTheHeadingChildren?: UnderTheHeadingChildrenFn | React.ReactNode;
  tabsDefaultActiveKey?: string;
  extra?: ExtraFuncType | React.ReactNode;
  disabled?: boolean;
  onBack?: () => void;
  topTabs?: IDefaultTabPanelProps['tabs'];
  autoLayout?: boolean;
  footerContentExtra?: React.ReactNode;
  headerTitleDescription?: React.ReactNode | string;
  isHeaderFlickering?: boolean;
  underHeaderContent?: React.ReactNode;
  moduleItem?: keyof IModulesMap;
  isCalendar?: boolean;
  outsideFooterContent?: React.ReactNode;
  sideContent?: React.ReactNode;
  showWarning?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  isFullWidthItems?: string[];
}

const PADDING_INDENT = 15;

const StyledOverViewLayout = styled.div<{
  $autoLayout?: boolean;
  $headerHeight: number;
  $withPaddingBottom: boolean;
}>`
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  height: auto;
  padding-top: ${({ $headerHeight }) => `${$headerHeight + 35}px`};

  ${({ $autoLayout }) =>
    $autoLayout &&
    css`
      min-height: auto;
      padding-top: 0;
    `};

  ${({ $withPaddingBottom }) =>
    $withPaddingBottom &&
    css`
      padding-bottom: 150px;
    `};
`;

const StyledContent = styled.div<{
  $autoLayout?: boolean;
  $headerHeight: number;
  $isWrapSideContent: boolean;
}>`
  min-height: ${({ $headerHeight }) =>
    `calc(100vh - ${$headerHeight}px - ${PADDING_INDENT}px)`};
  padding-left: 24px;
  padding-right: 24px;
  position: relative;

  flex: 1;
  //flex-grow: 3;
  //flex-shrink: 1;
  //flex-basis: content;

  width: 300px;

  ${({ $autoLayout }) =>
    $autoLayout &&
    css`
      min-height: auto;
      padding-left: 0;
      padding-right: 0;
    `}

  ${({ $isWrapSideContent }) =>
    $isWrapSideContent &&
    css`
      width: 100%;
    `}
`;

const StyledLeftContent = styled.div`
  display: flex;
  flex: 1;

  width: 100%;
  min-width: 350px;

  padding-left: 10px;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 50px;
`;

const StyledOutsideContainer = styled.div<{ $width: number }>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width}px;
    `}
`;

const StyledSideContent = styled.div<{
  $isWrapSideContent: boolean;
  $correctHeight: number;
}>`
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  flex-grow: 1;
  flex-basis: 1000px;
  max-width: 360px;

  height: calc(100vh - ${HEADER_HEIGHT}px - ${PADDING_INDENT * 3}px);

  ${({ $isWrapSideContent }) =>
    $isWrapSideContent &&
    css`
      height: 200px;
      margin-bottom: 10px;
      max-width: 100%;
    `}

  ${({ $correctHeight }) =>
    $correctHeight &&
    css`
      height: calc(
        100vh - ${HEADER_HEIGHT}px - ${PADDING_INDENT * 3}px -
          ${$correctHeight * 2.2}px
      );
    `}
`;

const StyledWrapper = styled.div<{ $isWrapSideContent: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  width: 100%;

  ${({ $isWrapSideContent }) =>
    $isWrapSideContent &&
    css`
      flex-wrap: wrap;
    `}
`;

function OverviewLayout<T extends {}>({
  data = {} as any,
  tabs,
  routes,
  headerTitle,
  loading,
  header,
  aclItem,
  headerRight,
  headerLeft,
  topTabs,
  autoLayout = false,
  footerContentExtra,
  headerTitleDescription,
  isHeaderFlickering,
  underHeaderContent,
  moduleItem,
  isCalendar,
  outsideFooterContent,
  sideContent,
  showWarning,
  className,
  children,
  isFullWidthItems,
}: IOverviewLayoutProps<T>): JSX.Element {
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const innerContentRef = useRef(null);
  const outsideContentRef = useRef(null);

  const { height: headerHeight } = useSize(headerRef);
  const { height: outsideContentHeight } = useSize(outsideContentRef);
  const { width: contentWidth } = useSize(contentRef);
  const { width: innerContentWidth } = useSize(innerContentRef);

  const isWrapSideContent = innerContentWidth <= bigPhoneScreen;

  return (
    <AclLayout aclItem={aclItem}>
      <StyledOverViewLayout
        ref={contentRef}
        $withPaddingBottom={!!outsideFooterContent}
        $autoLayout={autoLayout}
        $headerHeight={headerHeight}>
        {autoLayout ? null : (
          <AppHeader
            ref={headerRef}
            headerRight={headerRight}
            headerTitle={headerTitle}
            headerLeft={headerLeft}
            routes={routes}
            headerTitleDescription={headerTitleDescription}
            isHeaderFlickering={isHeaderFlickering}
            underHeaderContent={underHeaderContent}
            isCalendar={isCalendar}
            showWarning={showWarning}
          />
        )}
        <StyledWrapper
          ref={innerContentRef}
          $isWrapSideContent={isWrapSideContent}>
          {sideContent ? (
            <StyledSideContent
              $isWrapSideContent={isWrapSideContent}
              $correctHeight={
                outsideContentHeight || outsideFooterContent ? 65 : 0
              }>
              {sideContent}
            </StyledSideContent>
          ) : null}

          <StyledContent
            $autoLayout={autoLayout}
            $headerHeight={headerHeight}
            $isWrapSideContent={!sideContent || isWrapSideContent}>
            <SystemModulesLayout moduleItem={moduleItem} height={headerHeight}>
              {loading ? (
                <EmptyLayout headerHeight={headerHeight}>
                  <StyledLoadingOutlined />
                </EmptyLayout>
              ) : (
                <>
                  <OverviewRow gutter={24}>
                    <OverviewLayoutDetails
                      isFullWidthItems={isFullWidthItems}
                      header={header}
                      data={data}
                      tabs={tabs!}
                      topTabs={topTabs}
                      footerContentExtra={footerContentExtra}
                      className={className}>
                      {children ? (
                        <StyledLeftContent>{children}</StyledLeftContent>
                      ) : null}
                    </OverviewLayoutDetails>
                  </OverviewRow>
                  {outsideFooterContent ? (
                    <StyledOutsideContainer $width={contentWidth}>
                      {React.cloneElement(outsideFooterContent as any, {
                        ref: outsideContentRef,
                      })}
                    </StyledOutsideContainer>
                  ) : null}
                </>
              )}
            </SystemModulesLayout>
          </StyledContent>
        </StyledWrapper>
      </StyledOverViewLayout>
    </AclLayout>
  );
}
