import { EmployeeModel } from '@structure';

export interface IEmployeeAccrualStats {
  totalAccrualSum: number;
  totalPayoutSumOnAccrual: number;
  totalAccrualWithPayoutSum: number;
}

export interface IEmployeeAccruedSalaryModelProps {
  employee: EmployeeModel; // @TODO replace it with interface
  stats: IEmployeeAccrualStats;
}

export interface IEmployeeAccruedSalaryModel {
  stats: IEmployeeAccrualStats;
  employee: EmployeeModel; // @TODO replace it with interface
}

export class EmployeeAccrualSalaryModel implements IEmployeeAccruedSalaryModel {
  public readonly stats: IEmployeeAccrualStats;

  public readonly employee: EmployeeModel;

  constructor({ stats, employee }: IEmployeeAccruedSalaryModelProps) {
    this.stats = stats;
    this.employee = employee;
  }
}
