import React, { useEffect, useRef, useState, useCallback } from 'react';
// @ts-ignore
import type { RangePickerProps } from 'antd/es/date-picker/generatePicker';
import { withTranslation, WithTranslation } from 'react-i18next';
import type { Moment } from 'moment';
import DatePicker from '../../General/DatePicker';
import styled, { css } from 'styled-components';
import './ListDateRange.less';
import { isFunction } from '@services/helpers';

export default withTranslation()(ListDateRange);

export interface TimePickerProps
  extends Omit<RangePickerProps<Moment>, 'picker' | 'onChange'> {}

export interface IPaymentDateRangeProps
  extends Omit<TimePickerProps, 'size'>,
    WithTranslation {
  label?: string;
  tooltipTitle?: string;
  loading?: boolean;
  fullWidth?: boolean;
  defaultDateRangeValue?: [Moment, Moment] | undefined | null;
  onChange: (value: any) => Promise<void> | void;
  size?: any;
}

const StyledRangePicker = styled(DatePicker.RangePicker)<{
  $fullWidth: boolean;
}>`
  min-width: 288px;

  & input::placeholder {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `}
`;

function ListDateRange({
  label,
  tooltipTitle,
  loading,
  t,
  fullWidth,
  defaultDateRangeValue,
  onChange,
  ...rest
}: IPaymentDateRangeProps): JSX.Element {
  const [pikerValue, setPickerValue] = useState<any>(null);

  const once = useRef(false);

  useEffect(() => {
    if (defaultDateRangeValue && !pikerValue && !once.current) {
      once.current = true;
      setPickerValue(defaultDateRangeValue);
    }
  }, [defaultDateRangeValue, pikerValue]);

  const handleChange = useCallback(
    async (value: any) => {
      setPickerValue(value);

      if (isFunction(onChange)) {
        await onChange(value as any);
      }
    },
    [onChange],
  );

  return (
    // <Label label={label} tooltipTitle={tooltipTitle} fullWidth={fullWidth}>
    <StyledRangePicker
      popupClassName="dropdown-range-picker"
      showTime
      format="YYYY-MM-DD"
      disabled={loading}
      size="middle"
      $fullWidth={!!fullWidth}
      placeholder={[t('Start'), t('End')]}
      value={pikerValue}
      onChange={handleChange}
      {...rest}
    />
    // </Label>
  );
}
