import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormListFieldData, FormListOperation } from 'antd/es/form/FormList';

import {
  EmployeeRoleType,
  EmployeeRoleSalaryManagerPermission,
} from '@structure';

export interface EmployeeRoleSalaryManagerFieldProps extends FormListOperation {
  fields: FormListFieldData[];
  loadingSubmit: boolean;
  role: EmployeeRoleType<EmployeeRoleSalaryManagerPermission>;
}

export function EmployeeRoleSalaryManagerField({
  fields,
  add,
  remove,
  loadingSubmit,
  role,
}: EmployeeRoleSalaryManagerFieldProps) {
  const { t } = useTranslation();

  const permissions: EmployeeRoleSalaryManagerPermission[] = role?.permissions;

  return <></>;
}
