import { WithoutRecursion } from '../type';

import {
  ProfileDTO,
  ProfileModel,
  ProfileMapper,
  IProfileDTOProps,
  ICategoryModelReturnType,
} from '../internal';

export interface ICategoryDTOProps {
  pos: string;
  status: number;
  uuid: string;
  title: string;
  description: string;
  created_by: IProfileDTOProps;
  updated_at: string;
  created_at: string;
}

export class CategoryDTO {
  public pos: string;

  public status: number;

  public uuid: string;

  public title: string;

  public description: string;

  public created_by: ProfileDTO;

  public updated_at: string;

  public created_at: string;

  constructor(
    props: ICategoryModelReturnType | ICategoryDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.pos = props?.pos || '';
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.description = props?.description || '';
    this.updated_at = props?.updated_at || '';
    this.created_at = props?.created_at || '';
    this.status = props?.status || 0;
    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.profile) !== -1
        ? (props?.created_by as ProfileDTO)
        : ProfileMapper.toProfileDTO(
            props?.created_by as unknown as ProfileModel,
            [WithoutRecursion.category, ...withoutRecursion],
          );
  }
}
