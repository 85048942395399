import { useAcl } from '@contex';
import { BsCashCoin } from 'react-icons/bs';
import { StorePostingTabPanel } from '../Show';
import { useTranslation } from 'react-i18next';
import { TbTruckReturn } from 'react-icons/tb';
import styled, { css, useTheme } from 'styled-components';
import { ClientDetailsView } from '../../../Clients';
import { Col, MenuProps, Popconfirm, Row } from 'antd';
import { StoreReturnUpdateButton } from '../../Return';
import { toDateByFormat } from '@services/helpers';
import { productStoreDocumentRoutes } from '../../../Products';
import { Routes, RoutesAcl } from '@services/types';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewLayout } from '@components/lib/Layout';
import { useLocation, useNavigate, useParams } from 'react-router';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { DocumentControlPanel } from '@components/lib/General';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';

import {
  Button,
  HeaderLeft,
  DetailsView,
  CommentField,
  SuspenseEmpty,
  IDetailsViewItemProps,
} from '@components/lib/DataDisplay';

import {
  useStopLoading,
  useStoredProfile,
  useStoredCompanies,
  useStoredStoreDocument,
  useStoredStoreDocumentList,
  useStateStoreDocumentItemList,
} from '@hooks';

import {
  StoreDocumentType,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentMapper,
  StorePaymentDocumentFormDTO,
  StorePaymentDocumentPostActionType,
} from '@structure';

import {
  StoreDetailsView,
  StoreDocItemsView,
  StoreDocumentPageHeader,
  StoreDocumentBottomView,
  StoreDocExtraServicesView,
} from '../../Show';

import '../../OverviewLayoutDetailsItem.less';

const CloseDocumentSideWindowLazy = React.lazy(
  () => import('../../Show/StorePaymentDocumentSideWindow'),
);

const StorePostingSideWindowLazy = React.lazy(
  () => import('../Show/StorePostingSideWindow'),
);

const StoreReturnSideWindowLazy = React.lazy(
  () => import('../../../Stores/Return/Show/StoreReturnSideWindow'),
);

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.colors.error};
`;

const StyledStoreReturnUpdateButton = styled(StoreReturnUpdateButton)<{
  disabled?: boolean;
}>`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  cursor: pointer;
  height: 44px;

  ${({ disabled }) =>
    disabled &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.disabled};
      color: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    `}

  &&&:hover,
  &&&:active,
  &&&:focus {
    border: 1px solid ${({ theme }) => theme.colors.black};
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const StyledPopconfirmButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;

  padding: 0;
  margin: 0;

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: transparent;
  }
`;

const StyledTbTruckReturn = styled(TbTruckReturn)`
  color: ${({ theme }) => theme.colors.black};
`;

export function StorePostingPage() {
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { documentId, productId } = useParams();

  const { profile } = useStoredProfile();
  const { defaultCompanyUuid } = useStoredCompanies();
  const { store: storeAccess } = useAcl((acl) => acl);
  const [deletedLoading, setDeletedLoading] = useState(false);

  const {
    document,
    loading: storeLoading,
    error: storeError,
    handleUpdateStoreDocument,
    handlePaymentStoreDocument,

    handleCreateOrUpdateStoreDocumentItem,
    handleDeleteStoreDocumentItem,
    handlePrintStoreDocument,
    handleRefreshStoreDocument,
    handleCreateStoreDocument: handleCreateStoreReturn,
    handlePrintStoreDocumentCheck,
    handleUpdateStoreDocumentComment,
  } = useStoredStoreDocument({
    documentUuid: documentId!,
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.IN,
  });

  const {
    storeDocumentItemList,
    loading: documentItemsLoading,
    refresh: storeDocumentItemListRefresh,
    limit: storeDocumentItemListLimit,
    total: storeDocumentItemListTotal,
    page: storeDocumentItemListPage,
    setStoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: documentId!,
    limit: 100,
  });

  const { handleDeleteStoreDocuments: onDeleteStoreDocuments } =
    useStoredStoreDocumentList({
      companyUuid: defaultCompanyUuid,
      documentType: StoreDocumentType.IN,
      loadOnInit: false,
    });

  const onGoToPaymentsButtonClick = useCallback(() => {
    return navigate(`${Routes.payments}/${Routes.stores}`);
  }, [navigate]);
  const navigateGoBack = useCallback(() => {
    navigate(
      location?.state?.goBack ||
        `/${Routes.app}/${Routes.stores}/${Routes.storePosting}`,
    );
  }, [location?.state?.goBack, navigate]);
  const handleDeleteStoreDocuments = useCallback(async () => {
    setDeletedLoading(true);
    await onDeleteStoreDocuments([documentId!], navigateGoBack);
    setDeletedLoading(false);
  }, [onDeleteStoreDocuments, documentId, navigateGoBack]);
  const onCreateStoreReturnButtonClick = useCallback(
    async (value: any) => {
      const storeDocument = await handleCreateStoreReturn({
        ...value,
      });
      // setTimeout(async () => {
      //   await handleRefreshStoreDocument();
      // }, 500);
      // console.log('VALUE from document -> ', storeDocument?.document);
      // return storeDocument?.document;
    },
    [handleCreateStoreReturn],
  );

  const storePayment = useMemo(
    () =>
      StoreDocumentMapper.toStorePaymentDocumentFormDTO(
        document || ({} as StoreDocumentModel),
        {
          cashier: profile?.defaultEmployeeModel,
          comment: `${t('Payment to the supplier according to the invoice')} №${
            document?.doc_local_number
          } ${t('From')} ${toDateByFormat(
            document?.doc_date,
            'DD.MM.YYYY HH:mm',
          )}`,
          payment_post_action: {
            action:
              StorePaymentDocumentPostActionType.SET_STORE_DOCUMENT_STATUS_COMMITTED,
          },
        },
      ),
    [document, profile?.defaultEmployeeModel, t],
  );

  const {
    handleCancel: storePaymentHandleCancel,
    visible: storePaymentVisible,
    handleSuccess: storePaymentHandleSuccess,
    handleOnInit: storePaymentHandleOnInit,
  } = useModal<StorePaymentDocumentFormDTO>({
    onSuccess: async (value) => {
      await handlePaymentStoreDocument(value, { with_document: true });
    },
  });

  const {
    handleOnInit: storeDocumentOnInit,
    handleCancel: storeDocumentEditCancel,
    handleSuccess: storeDocumentEditSuccess,
    visible: storeDocumentVisibility,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateStoreDocument,
  });

  const {
    handleOnInit: storeDocumentReturnOnInit,
    handleCancel: handleCancelStoreReturnDocument,
    handleSuccess: storeDocumentReturnCreateSuccess,
    visible: isCreateStoreReturnDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: onCreateStoreReturnButtonClick,
  });

  const loading = useStopLoading({
    loading: storeLoading,
    error: storeError,
    message: 'An error occurred during store posting loading',
  });

  const routes = useBreadcrumb(
    productId
      ? productStoreDocumentRoutes(productId, (startUrl: string) => ({
          path: `${startUrl}/${Routes.stores}/${Routes.storePosting}/${documentId}`,
          breadcrumbName: document?.doc_local_number || 'Show document',
        }))
      : [
          {
            path: `/${Routes.stores}/${Routes.storePosting}`,
            breadcrumbName: 'Bill of Ladings',
          },
          {
            path: `/${Routes.stores}/${Routes.storePosting}/${documentId}`,
            breadcrumbName: document?.doc_local_number || 'Show document',
          },
        ],
  );

  const isDraft: boolean = useMemo<boolean>(
    (): boolean => document?.doc_status_text === StoreDocumentStatus.DRAFT,
    [document?.doc_status_text],
  );

  const isPaymentable: boolean = useMemo<boolean>(
    (): boolean => !!document?.doc_is_paymentable,
    [document?.doc_is_paymentable],
  );

  const detailViewItems: IDetailsViewItemProps[] = [
    {
      title: 'Provider',
      className: 'client-details__view',
    },
    {
      title: 'Store',
      className: 'store-details__view',
    },
  ];
  const documentOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'product',
        disabled: !isPaymentable,
        label: <StyledTitle>{t('List of payments')}</StyledTitle>,
        icon: <BsCashCoin size={15} />,
        onClick: onGoToPaymentsButtonClick,
      },
      { type: 'divider' },
      {
        key: 'product-return',
        icon: <StyledTbTruckReturn size={15} />,
        label: t('Create return to supplier'),
        onClick: storeDocumentReturnOnInit,
      },
      { type: 'divider' },
      {
        key: 'delete-product',
        disabled: !isDraft,
        label: !isDraft ? (
          <StyledTitle>
            <StyledDeleteOutlined /> {t('Delete')}
          </StyledTitle>
        ) : (
          <Popconfirm
            title={t('Are you sure you want to delete the document?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onCancel={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();
            }}
            onConfirm={async (e) => {
              e?.preventDefault();
              e?.stopPropagation();

              await handleDeleteStoreDocuments();
            }}>
            <StyledPopconfirmButton
              type="text"
              onClick={async (e: any) => {
                if (e) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
              }}>
              {deletedLoading ? (
                <StyledLoadingOutlined />
              ) : (
                <StyledDeleteOutlined />
              )}
              <StyledTitle $color={theme.colors.error}>
                {t('Delete draft')}
              </StyledTitle>
            </StyledPopconfirmButton>
          </Popconfirm>
        ),
      },
    ],
    [
      t,
      theme,
      isDraft,
      isPaymentable,
      deletedLoading,
      storeDocumentReturnOnInit,
      onGoToPaymentsButtonClick,
      handleDeleteStoreDocuments,
    ],
  );

  return (
    <>
      <StorePostingTabPanel document={document}>
        {({ tabs }) => (
          <OverviewLayout
            className="overview-details-item-store"
            aclItem={RoutesAcl[Routes.stores]}
            headerTitle={t('Bill of Lading')}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                onEditInit={storeDocumentOnInit}
                items={documentOptions}
                isDraft={
                  document?.doc_status_text === StoreDocumentStatus.DRAFT
                }
              />
            }
            underHeaderContent={
              document?.uuid ? (
                <StoreDocumentPageHeader
                  document={document}
                  withoutPayment={true}
                  withoutScore={false}
                />
              ) : null
            }
            header={
              <Row gutter={10}>
                <Col span={24}>
                  <DetailsView items={detailViewItems}>
                    {document?.clientModel?.uuid ? (
                      <ClientDetailsView client={document?.clientModel} />
                    ) : null}
                    {document?.companyStoreModel?.uuid ? (
                      <StoreDetailsView store={document?.companyStoreModel} />
                    ) : null}
                  </DetailsView>
                </Col>
                <Col span={24}>
                  <CommentField
                    comment={document?.doc_comment || t('Please enter note')}
                    handleUpdateComment={handleUpdateStoreDocumentComment}
                    disabled={!storeAccess?.manage}
                  />
                </Col>
                <Col span={24}>
                  <StoreDocItemsView
                    productWithBalance={false}
                    document={document!}
                    handleCreateOrUpdateStoreDocumentItem={
                      handleCreateOrUpdateStoreDocumentItem
                    }
                    handleDeleteStoreDocumentItem={
                      handleDeleteStoreDocumentItem
                    }
                    loading={documentItemsLoading}
                    storeDocumentItemList={storeDocumentItemList}
                    storeDocumentItemListRefresh={storeDocumentItemListRefresh}
                    storeDocumentItemListLimit={storeDocumentItemListLimit}
                    storeDocumentItemListTotal={storeDocumentItemListTotal}
                    storeDocumentItemListPage={storeDocumentItemListPage}
                  />
                </Col>
                {Array.isArray(document?.doc_extra_services) ? (
                  <Col span={24}>
                    <StoreDocExtraServicesView document={document!} />
                  </Col>
                ) : null}
              </Row>
            }
            outsideFooterContent={
              <StoreDocumentBottomView
                document={document}
                // handlePrintStoreDocument={handlePrintStoreDocument}
                handlePaymentStoreDocument={storePaymentHandleOnInit}
                // handlePrintStoreDocumentCheck={handlePrintStoreDocumentCheck}
                // buttons={({isPhoneScreen}) => (
                // <StyledStoreReturnUpdateButton
                //   fromList={false}
                //   from={StoreDocumentType.IN}
                //   loading={loading}
                //   onSuccess={async (value) => {
                //     const storeDocument = await handleCreateStoreReturn({
                //       ...value,
                //     });
                //
                //     setTimeout(async () => {
                //       await handleRefreshStoreDocument();
                //     }, 500);
                //
                //     return storeDocument?.document;
                //   }}
                //   handlePaymentStoreDocument={handlePaymentStoreDocument}
                //   handlePaymentStoreDocument={storeDocumentReturnOnInit}
                //   icon={<StyledTbTruckReturn size={18} />}
                //   title={
                //     <>
                //       {isPhoneScreen ? t('Create return to supplier') : null}
                //     </>
                //   }
                //   document={StoreDocumentMapper.toStoreDocumentFormDTO(
                //     {
                //       documentModel: document!,
                //       companyStoreModel: document?.companyStoreModel,
                //       client: document?.client,
                //       doc_status_text: StoreDocumentStatus.DRAFT,
                //       doc_type: StoreDocumentType.RETURN,
                //     } as StoreDocumentModel,
                //     {
                //       editMode: true,
                //       default_doc_items: document?.doc_items || [],
                //     },
                //   )}
                // />
                // )}
              />
            }
            disabled={!storeAccess?.manage}
            editTooltip={t('Edit bill of lading')}
            loading={loading}
            data={null}
            routes={routes}
            tabs={tabs}
          />
        )}
      </StorePostingTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <CloseDocumentSideWindowLazy
          title={`${t('Closing posting')}`}
          payment={storePayment!}
          loading={loading}
          onSuccess={storePaymentHandleSuccess}
          onCancel={storePaymentHandleCancel}
          visible={storePaymentVisible}
        />
      </Suspense>
      <Suspense fallback={<SuspenseEmpty />}>
        <StorePostingSideWindowLazy
          editMode
          document={StoreDocumentMapper.toStoreDocumentFormDTO(document!, {
            editMode: true,
          })}
          onSuccess={storeDocumentEditSuccess}
          visible={storeDocumentVisibility}
          onCancel={storeDocumentEditCancel}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
      <Suspense fallback={<SuspenseEmpty />}>
        <StoreReturnSideWindowLazy
          editMode
          from={StoreDocumentType.IN}
          document={StoreDocumentMapper.toStoreDocumentFormDTO(
            {
              documentModel: document!,
              companyStoreModel: document?.companyStoreModel,
              client: document?.client,
              doc_status_text: StoreDocumentStatus.DRAFT,
              doc_type: StoreDocumentType.RETURN,
            } as StoreDocumentModel,
            {
              editMode: true,
              default_doc_items: document?.doc_items || [],
            },
          )}
          onCancel={handleCancelStoreReturnDocument}
          visible={isCreateStoreReturnDocumentVisible}
          onSuccess={storeDocumentReturnCreateSuccess}
          handlePaymentStoreDocument={handlePaymentStoreDocument}
          fromList={false}
        />
      </Suspense>
    </>
  );
}
