import {
  IPaymentModelReturnType,
  IClientDTOProps,
  IScheduleCalendarDTOProps,
  ClientDTO,
  ScheduleCalendarDTO,
  ClientMapper,
  ScheduleCalendarMapper,
  GroupPaymentDTO,
  IGroupPaymentDTOProps,
  GroupPaymentMapper,
  EmployeeDTO,
  EmployeeMapper,
  ClientModel,
  EmployeeModel,
  IEmployeeDTOProps,
  ICashBoxDTOProps,
  CashBoxDTO,
  CashBoxMapper,
  IPaymentableDTOProps,
  PaymentableDTO,
  PaymentableMapper,
  IStoreDocumentDTOProps,
  StoreDocumentDTO,
  StoreDocumentMapper,
  InvoiceDTO,
  InvoiceModel,
  InvoiceMapper,
} from '../internal';
import { correctPrice } from '@services/helpers';
import { OPERATION_TYPE } from '@services/types';

export enum PAYMENT_DESTINATION {
  Operation = 'operation',
  ScheduledOrder = 'scheduled_order',
  Account = 'account',
  AbonementSubscription = 'abonement_subscription',
  ClientAbonement = 'client_abonement',
  StoreDocument = 'store_document',
  Invoice = 'invoice',
  EmployeeSalary = 'employee_salary',
}

export enum PAYMENT_AGENT_TYPE {
  Employee = 'employee',
  Client = 'client',
}

export enum PAYMENT_METHOD {
  Account = 'ACCOUNT',
  Bank = 'BANK',
  Cash = 'CASHBOX',
}

export enum PAYMENT_STATUS {
  Completed = 'completed',
  Draft = 'draft',
  Cancelled = 'cancelled',
  Deferred = 'deferred',
}

export interface IPaymentDTOProps {
  uuid: string;
  payment_comment: string;
  payment_type: OPERATION_TYPE;
  payment_sum: string;
  payment_date: string;
  payment_status: PAYMENT_STATUS;
  created_by: number;
  created_at: string;
  updated_at: string;
  payment_number: string;
  payment_group: IGroupPaymentDTOProps;
  payment_method: PAYMENT_METHOD;
  payment_agent_type: PAYMENT_AGENT_TYPE;
  payment_destination: PAYMENT_DESTINATION;
  payment_trigger: PAYMENT_DESTINATION;
  payment_agent_object: IClientDTOProps | IEmployeeDTOProps;
  cashier: IEmployeeDTOProps;
  company_cashbox: ICashBoxDTOProps;
  payment_trigger_object:
    | IScheduleCalendarDTOProps
    | IStoreDocumentDTOProps
    | InvoiceDTO
    | '';
  paymentable?: IPaymentableDTOProps;
  payment_is_credit_operation: boolean;
  payment_has_fiscal_receipt: boolean;
  payment_fiscal_receipt: any;
}

export class PaymentDTO {
  public uuid: string;

  public payment_comment: string;

  public payment_type: OPERATION_TYPE;

  public payment_sum: string;

  public payment_date: string;

  public payment_status: PAYMENT_STATUS;
  public payment_number: string;

  public created_by: number;

  public created_at: string;

  public updated_at: string;
  public payment_group: GroupPaymentDTO;
  public payment_agent_type: PAYMENT_AGENT_TYPE;
  public payment_method: PAYMENT_METHOD;

  public payment_trigger: PAYMENT_DESTINATION;

  public payment_agent_object: ClientDTO | EmployeeDTO;
  public cashier: EmployeeDTO;
  public company_cashbox: CashBoxDTO;
  public payment_trigger_object:
    | ScheduleCalendarDTO
    | StoreDocumentDTO
    | InvoiceModel
    | string;
  public paymentable?: PaymentableDTO;
  public payment_is_credit_operation: boolean;
  public payment_has_fiscal_receipt: boolean;
  public payment_fiscal_receipt: any;

  constructor(props: IPaymentDTOProps | IPaymentModelReturnType) {
    this.uuid = props?.uuid || '';
    this.payment_comment = props?.payment_comment || '';
    this.payment_number = props?.payment_number || '';
    this.payment_type = props?.payment_type || OPERATION_TYPE.IN;
    this.payment_agent_type =
      props?.payment_agent_type || PAYMENT_AGENT_TYPE.Client;
    this.payment_sum = correctPrice(props?.payment_sum || 0);
    this.payment_date = props?.payment_date || '';
    this.payment_fiscal_receipt = props?.payment_fiscal_receipt || {};
    this.payment_status = props?.payment_status || PAYMENT_STATUS.Draft;
    this.created_by = props?.created_by || 0;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.payment_is_credit_operation =
      typeof props?.payment_is_credit_operation === 'boolean'
        ? props?.payment_is_credit_operation
        : false;
    this.payment_has_fiscal_receipt =
      typeof props?.payment_has_fiscal_receipt === 'boolean'
        ? !!props?.payment_has_fiscal_receipt
        : false;
    this.payment_group = GroupPaymentMapper.toGroupPaymentDTO(
      props?.payment_group,
    );

    this.cashier = EmployeeMapper.toEmployeeDTO(props?.cashier);
    this.company_cashbox = CashBoxMapper.toCashBoxDTO(props?.company_cashbox);
    this.payment_method =
      (props?.payment_method as any) || PAYMENT_METHOD.Account;
    this.payment_trigger =
      props?.payment_trigger || PAYMENT_DESTINATION.Operation;
    this.payment_agent_object = this.paymentAgentObjectFactory(
      props?.payment_agent_object,
    );
    this.payment_trigger_object = this.paymentTriggerObjectFactory(
      props?.payment_trigger_object,
    );

    this.paymentable = PaymentableMapper.toPaymentableDTO(
      props?.paymentable || ({} as any),
    );
  }

  private paymentAgentObjectFactory(
    paymentAgentObject:
      | ClientModel
      | IClientDTOProps
      | EmployeeModel
      | IEmployeeDTOProps,
  ) {
    if (this?.payment_agent_type === PAYMENT_AGENT_TYPE.Client) {
      // @ts-ignore
      return ClientMapper.toClientDTO(paymentAgentObject as ClientDTO);
    }

    // @ts-ignore
    return EmployeeMapper.toEmployeeDTO(paymentAgentObject as EmployeeDTO);
  }

  private paymentTriggerObjectFactory(paymentTriggerObject: any) {
    if (this?.payment_trigger === PAYMENT_DESTINATION.ScheduledOrder) {
      return ScheduleCalendarMapper.toScheduleCalendarDTO(
        paymentTriggerObject as IScheduleCalendarDTOProps,
      );
    }

    if (this?.payment_trigger === PAYMENT_DESTINATION.StoreDocument) {
      return StoreDocumentMapper.toStoreDocumentDTO(
        paymentTriggerObject as IStoreDocumentDTOProps,
      );
    }

    if (this?.payment_trigger === PAYMENT_DESTINATION.Invoice) {
      return InvoiceMapper.toInvoiceModel(paymentTriggerObject as InvoiceDTO);
    }

    return '';
  }
}
