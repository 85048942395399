import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { correctPrice } from '@services/helpers';
import { ArrowRightOutlined } from '@ant-design/icons';

export type TPriceItemSize = 'small' | 'medium' | 'large';

interface IPriceItemProps {
  price: number;
  size?: TPriceItemSize;
  color?: string | null;
  path?: string;
  pathTooltip?: string;
}

const StyledPriceItemContainer = styled.span<{
  $color?: string | null;
  $size?: TPriceItemSize;
}>`
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
  ${({ $size }) =>
    $size &&
    css`
      font-size: ${$size === 'medium'
        ? '20px'
        : $size === 'large'
        ? '24px'
        : '14px'};
    `}
`;

export function PriceItem(props: IPriceItemProps): React.JSX.Element {
  const {
    path,
    price = 0,
    pathTooltip,
    color = null,
    size = 'medium',
  } = props || {};
  const { t } = useTranslation();

  return (
    <StyledPriceItemContainer $color={color} $size={size}>
      {correctPrice(price)}
      {path && (
        <Tooltip title={pathTooltip || t('Go to details')}>
          <Link to={path}>
            <ArrowRightOutlined
              style={{ paddingLeft: '10px', fontSize: '12px' }}
            />
          </Link>
        </Tooltip>
      )}
    </StyledPriceItemContainer>
  );
}
