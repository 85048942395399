import React from 'react';
import { Popconfirm } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import EmployeeServicesButton from './EmployeeServicesButton';
import { DeleteButton } from '@components/lib/DataDisplay';
import { ServiceModel, ServiceParametersFormDTO } from '@structure';
import { ServiceParametersUpdateButton } from '../../Services';
import { ApiError, TIME_LIST_SERVICES } from '@services/types';
import { TableActionCell } from '@components/lib/libV2/DataDisplay';
import { IUseStateEmployeeReturnType } from '@hooks';
import { isFunction } from '@sportix/sportix-common-modules';

export default withTranslation()(EmployeeServicesEditButtons);

export interface IEmployeeServicesEditButtonsProps<T>
  extends WithTranslation,
    Pick<IUseStateEmployeeReturnType, 'employeeServiceBonuses'> {
  loadingSubmit: boolean;
  onDelete: () => void;
  handleAdd: () => void;
  handleEdit: (
    value: ServiceParametersFormDTO,
    employee_uuid?: string,
  ) => Promise<ApiError | void>;
  isOwnService: boolean;
  disabled?: boolean;
  item: ServiceParametersFormDTO;
  servicePeriod: TIME_LIST_SERVICES;
  service: ServiceModel | null;
  daleyFocus?: (ms?: number) => void;
}

function EmployeeServicesEditButtons<T>({
  t,
  loadingSubmit,
  onDelete,
  isOwnService,
  handleAdd,
  disabled,
  item,
  handleEdit,
  servicePeriod,
  service,
  employeeServiceBonuses,
  daleyFocus,
}: IEmployeeServicesEditButtonsProps<T>): JSX.Element {
  return (
    <React.Fragment>
      {isOwnService ? (
        <TableActionCell>
          <ServiceParametersUpdateButton
            parameters={item}
            onSuccess={handleEdit}
            disabled={!isOwnService || loadingSubmit || disabled}
            servicePeriod={servicePeriod}
            service={service}
            employeeServiceBonuses={employeeServiceBonuses}
            onCancel={daleyFocus}
          />
          <DeleteButton
            confirmTitle={t('Are you sure to delete service from employee?')}
            onConfirm={onDelete}
            confirmButtonText={t('Yes')}
            cancelButtonText={t('No')}
            tooltipTitle={t('Delete')}
            disabled={loadingSubmit || disabled}
          />
        </TableActionCell>
      ) : (
        <TableActionCell>
          <Popconfirm
            title={t('Are you sure to add service to employee?')}
            onConfirm={() => {
              handleAdd();
              if (isFunction(daleyFocus)) {
                daleyFocus();
              }
            }}
            okText={t('Yes')}
            cancelText={t('No')}>
            <EmployeeServicesButton
              disabled={disabled}
              type="text"
              toolTipTitle={t('Add service to employee list')}>
              <PlusOutlined />
            </EmployeeServicesButton>
          </Popconfirm>
        </TableActionCell>
      )}
    </React.Fragment>
  );
}
