import { Routes } from '@services/types';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';
import React, { useCallback, useMemo, useState } from 'react';
import { StyledDetailsView } from '@components/lib/Styled/DetailsView';

import {
  GetEmployeeBadgeState,
  IEmployeeListStatsDTOProps,
} from '@services/api/employee';

import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from 'react-i18next';

import {
  TeamOutlined,
  ScheduleOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';

import {
  EmployeeBadgeAllItem,
  EmployeeBadgeSalaryItem,
  EmployeeBadgeScheduleItem,
} from './Badges/';

export interface IEmployeeListBadgesPanelProps extends WithTranslation {
  children: (value: any) => React.ReactNode;
  stats?: IEmployeeListStatsDTOProps;
  loading?: boolean;
}

/**
 * @decr Відображення баджів на сторінці списку Співробітників
 * @param {IEmployeeListBadgesPanelProps} props
 * */
function EmployeeListBadgesPanel(props: IEmployeeListBadgesPanelProps) {
  const { children, stats, loading } = props || {};
  const theme: any = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [employeeBadgeState, setEmployeeBadgeState] =
    useState<GetEmployeeBadgeState>(GetEmployeeBadgeState.ALL);

  const navigateToSalaryAccrualPage = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.employees}/${Routes.employeeSalary}`);
  }, [navigate]);
  const employeeBadgeList: any[] = useMemo(
    () => [
      {
        title: t('Employees'),
        color: theme.colors.darkenLink,
        icon: <TeamOutlined />,
        type: GetEmployeeBadgeState.ALL,
        backgroundColor: theme.badges.all,
        onClick: () => setEmployeeBadgeState(GetEmployeeBadgeState.ALL),
        bodyContent: (
          <EmployeeBadgeAllItem
            loading={loading}
            color={theme.colors.darkenLink}
            employeeLimit={stats?.employeeLimit || 0}
            amountOfActiveEmployee={stats?.amountOfActiveEmployees || 0}
            amountOfInactiveEmployee={stats?.amountOfInactiveEmployees || 0}
            limitedAmountOfEmployee={8}
          />
        ),
      },
      {
        title: t('Employee salary'),
        icon: <MoneyCollectOutlined />,
        type: GetEmployeeBadgeState.SALARY,
        color: theme.colors.buttonPrimaryColor,
        backgroundColor: theme.badges.employee.salary,
        onClick: () => {
          setEmployeeBadgeState(GetEmployeeBadgeState.SALARY);
          return navigateToSalaryAccrualPage();
        },
        bodyContent: (
          <EmployeeBadgeSalaryItem
            loading={loading}
            color={theme.colors.buttonPrimaryColor}
            payoutSumForPeriod={stats?.payoutSumForPeriod}
            accruedSumForPeriod={stats?.accruedSumForPeriod}
            dateOfPayroll={
              stats?.dateOfPayroll ? new Date(stats?.dateOfPayroll) : null
            }
          />
        ),
      },
      {
        title: t('Employee shifts'),
        icon: <ScheduleOutlined />,
        type: GetEmployeeBadgeState.SCHEDULE,
        color: theme.colors.darkOrchid,
        backgroundColor: theme.badges.employee.schedule,
        onClick: () => setEmployeeBadgeState(GetEmployeeBadgeState.SCHEDULE),
        bodyContent: (
          <EmployeeBadgeScheduleItem
            loading={loading}
            color={theme.colors.darkOrchid}
            amountOfShifts={stats?.amountOfShifts}
            dateOfShiftStats={
              stats?.dateOfShiftStats ? new Date(stats?.dateOfShiftStats) : null
            }
          />
        ),
      },
    ],
    [
      t,
      theme,
      loading,
      stats?.employeeLimit,
      stats?.dateOfPayroll,
      stats?.amountOfShifts,
      stats?.dateOfShiftStats,
      stats?.payoutSumForPeriod,
      stats?.accruedSumForPeriod,
      navigateToSalaryAccrualPage,
      stats?.amountOfActiveEmployees,
      stats?.amountOfInactiveEmployees,
    ],
  );

  return (
    <StyledDetailsView
      titles={[]}
      selected={[
        employeeBadgeState === GetEmployeeBadgeState.ALL,
        employeeBadgeState === GetEmployeeBadgeState.SALARY,
        employeeBadgeState === GetEmployeeBadgeState.SCHEDULE,
      ]}>
      {children({ items: employeeBadgeList })}
    </StyledDetailsView>
  );
}

export default withTranslation()(EmployeeListBadgesPanel);
