import { isThereContent } from '@services/helpers';
import React from 'react';
import styled, { css } from 'styled-components';
import { StyledTitle } from '@components/lib/Styled';
import { GetEmployeeBadgeState } from '@services/api/employee';

import {
  DetailsActionItemView,
  IDetailsActionItemViewProps,
} from '@components/lib/DataDisplay';

export interface IEmployeeBadgeItemProps
  extends Omit<IDetailsActionItemViewProps, 'onClick' | 'type' | 'children'> {
  type: GetEmployeeBadgeState;
  color: string;
  backgroundColor: string;
  title: string;
  icon?: any;
  bodyContent?: any;
  selected?: boolean;
  onClick: (type: GetEmployeeBadgeState) => void;
}

const StyledEmployeeBadgeItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`;

const StyledBodyWrapper = styled.div`
  display: flex;
`;

const StyledEmployeeBadgeIconWrapper = styled.div<{ $color?: string }>`
  font-size: 25px;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  height: 100%;
`;

export function EmployeeBadgeItem({
  color,
  selected,
  type,
  title,
  icon,
  onClick,
  bodyContent,
  backgroundColor,
  ...rest
}: IEmployeeBadgeItemProps): React.JSX.Element {
  return (
    <DetailsActionItemView
      $minHeight={100}
      color={backgroundColor}
      selected={selected}
      onClick={() => onClick(type)}
      {...rest}>
      <StyledEmployeeBadgeItemContainer>
        {icon && (
          <StyledEmployeeBadgeIconWrapper $color={color}>
            {icon}
          </StyledEmployeeBadgeIconWrapper>
        )}
        <StyledInfoContainer>
          <StyledTitle $color={color}>{title}</StyledTitle>
          {isThereContent(bodyContent) ? (
            <StyledBodyWrapper>{bodyContent}</StyledBodyWrapper>
          ) : null}
        </StyledInfoContainer>
      </StyledEmployeeBadgeItemContainer>
    </DetailsActionItemView>
  );
}
