import React from 'react';
import { Moment } from 'moment';
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_DISABLED,
  SUBSCRIPTION_STATUS_PROCESSING,
  SUBSCRIPTION_STATUS_EXPIRED,
} from '@sportix/sportix-common-modules';
import { SelectValue } from 'antd/lib/select';
import { List } from 'immutable';
import { EmployeeModel, ServiceModel } from '@structure';

export interface ILiteralObj {
  [key: string]: any;
}

export type Translation = {
  t: any;
};

export type KeyOfObject<T> = {
  [P in keyof T]: T[P];
};

export type Success = {
  success: boolean;
};

export type ConcatObject<A, B> = {
  [K in keyof (A | B)]: K extends keyof B ? B[K] : A[K];
};

export type DeletePrams = {
  ids: string[];
};

export type ApiAnswer = {
  code: number;
  total: number;
  count: number;
  success: boolean;
};

export interface IChild {
  children: React.ReactNode | React.ReactNode[];
}

export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export interface FileUploader {
  uid: string;
  name: string;
  status: string;
  url: string;
}

export type EventLayout = 'vertical' | 'horizontal';

export interface RefValue<T> {
  value: T;
}

export interface RefCurrent<T> {
  current: RefValue<T>;
}

export interface RowSelection<T> {
  renderCell?: any;
  hideSelectAll?: boolean;
  selectedRowKeys: string[];
  onChange?: (selectedRowKeys: React.Key[], selectedRecords: T[]) => void;
}

export enum OPERATION_TYPE {
  IN = 'IN',
  OUT = 'OUT',
  ALL = '',
}

export type DateValue = string | Moment | Date;
export type RangeTime = [DateValue, DateValue];

export interface IEditFormProps {
  onCancel: () => void;
}

export enum DRAG_ITEM_TYPE {
  BOX = 'box',
}

export interface IFindServicesByEmployee {
  employeeUuid: string;
  date: DateValue;
  companyEmployees?: List<EmployeeModel> | null;
  handleChangeService?: (
    value: SelectValue,
    services?: List<ServiceModel>,
  ) => void;
}

export type RequiredFieldCallback<U> = <T>(value: any, data: T) => U;

export type RequiredField<T, U> = T | RequiredFieldCallback<U>;

export type ConvertTypeToRequiredField<Type> = {
  [K in keyof Type]: RequiredField<Type[K], Type[K]>;
};

export enum STATUS {
  ACTIVE = 'ACTIVE',
  REQUESTED = 'REQUESTED',
  ERROR = 'ERROR',
  UNDEFINED = 'UNDEFINED',
}

export interface GroupBy<T> {
  [key: string]: T[];
}

export interface IError extends Error {
  message: string;
  status: number;
}

export enum Routes {
  auth = 'auth',
  signup = 'signup',
  termsPolicy = 'terms-and-policy',
  authOffice = 'office',
  accessDenied = 'access-denied',
  paymentsSuccess = 'payments/success',
  dashboard = 'dashboard',
  app = 'app',
  login = 'login',
  register = 'register',
  resetPassword = 'reset-password',
  loading = 'loading',
  forgotPassword = 'forgot-password',
  privacyAndGDPRS = 'privacy-and-gdprs',
  termsAndConditions = 'terms-and-conditions',
  services = 'services',
  employees = 'employees',
  categories = 'categories',
  subCategories = 'sub-categories',
  clients = 'clients',
  clientGroups = 'clients-groups',
  payments = 'payments',
  profile = 'profile',
  companies = 'companies',
  orders = 'orders',
  settings = 'settings',
  users = 'users',
  payment_group = 'payment-group',
  template = 'template',
  calendar = 'calendar',
  approveEnter = 'approve-enter',
  employeeSignup = 'employee-signup',
  verificationForm = 'verification-form',
  waitingForCompanyCreate = 'waiting-for-company-create',
  reports = 'reports-panel',
  reportsArchive = 'archive',
  daysOff = 'days-off',
  employeeServices = 'added-services',
  employeeSalary = 'salary',
  employeeSalarySettings = 'salary-settings',
  serviceEmployees = 'added-employees',
  serviceSettings = 'service-settings',
  modules = 'modules',
  schedules = 'schedules',
  groupSessions = 'group-sessions',
  relatedServices = 'related-services',
  relatedOrders = 'related-orders',
  statuses = 'statuses',
  clientSchedules = 'schedule-list',
  clientAbonements = 'client-abonements',
  clientBalanceOperations = 'balance-operations',
  notifications = 'notifications',
  operations = 'operations',
  subscriptionExpired = 'subscription-expired',
  appInfo = 'info',
  appIntro = 'intro',
  materialsAndExpenses = 'materials-and-expenses',
  emailVerification = 'verification_by_email',
  verificationInstructions = 'verification-instructions',
  tariffModels = 'tariff-models',
  groupPayment = 'group-payments',
  mailingTemplates = 'mailing-templates',
  tariffModelGroup = 'groups',
  subscriptionListPage = 'archive',
  subscription = 'subscription',
  customStatuses = 'custom-statuses',
  products = 'materials',
  movementGoods = 'movement-goods',
  rewards = 'rewards',
  departments = 'departments',
  integrations = 'integrations',
  stores = 'stores',
  priceMargins = 'price-margins',
  storePosting = 'posting',
  storeShowOnly = 'show-only',
  storeRelated = 'related-documents',
  storeMoving = 'moving',
  storeLeftovers = 'leftovers',
  storeWriteOff = 'write-off',
  storeRealization = 'sales-invoices',
  storeReturn = 'return',
  storeReturnClient = 'return-client',
  storeOrderProvider = 'order-provider',
  storeSale = 'sales',
  categoryProducts = 'category-products',
  goods = 'goods',
  priceTags = 'price-tags',
  finances = 'finances',
  documents = 'documents',
  invoices = 'invoices',

  files = 'files',
}

export const RoutesTitle = {
  [Routes.services]: 'Services',
  [Routes.employees]: 'Employees',
  [Routes.daysOff]: 'Employee day off list',
  [Routes.employeeSalary]: 'Accrued salary',
  [Routes.employeeServices]: 'Employee service list',
  [Routes.serviceEmployees]: 'Service employee list',
  [Routes.groupSessions]: 'Service group sessions',
  [Routes.clients]: 'Counterparties',
  [Routes.clientGroups]: 'Clients groups',
  [Routes.orders]: 'Orders',
  [Routes.relatedServices]: 'Related services',
  [Routes.relatedOrders]: 'Related orders',
  [Routes.clientSchedules]: 'Client schedule list',
  [Routes.payments]: 'Cash flow',
  [Routes.categories]: 'Categories',
  [Routes.users]: 'Users',
  [Routes.settings]: 'Settings',
  [Routes.dashboard]: 'Calendar',
  [Routes.notifications]: 'Notifications',
  [Routes.operations]: 'Operations on a personal account',
  [Routes.subscriptionExpired]: 'Subscription expired',
  [Routes.appInfo]: 'App information',
  [Routes.appIntro]: 'App intro',
  [Routes.materialsAndExpenses]: 'Materials and expenses',
  [Routes.statuses]: 'Order status',
  [Routes.emailVerification]: 'Verification by email',
  [Routes.verificationInstructions]: 'Verification instructions',
  [Routes.reports]: 'Reporting',
  [Routes.tariffModels]: 'Tariff models',
  [Routes.clientAbonements]: 'Client abonements',
  [Routes.clientBalanceOperations]: 'Balance operations',
  [Routes.groupPayment]: 'Group payments',
  [Routes.mailingTemplates]: 'notification templates',
  [Routes.modules]: 'System modules',
  [Routes.tariffModelGroup]: 'Tariff model groups',
  [Routes.subscriptionListPage]: 'Archive subscription list',
  [Routes.subscription]: 'Subscription',
  [Routes.customStatuses]: 'Statuses',
  [Routes.products]: 'Goods and materials values',
  [Routes.companies]: 'Companies',
  [Routes.departments]: 'Departments / Locations',
  [Routes.stores]: 'Stores',
  [Routes.priceMargins]: 'Markups and discounts',
  [Routes.storePosting]: 'Bill of Ladings',
  [Routes.storeMoving]: 'Bill of lading for movements',
  [Routes.storeWriteOff]: 'Bill of lading for write offs',
  [Routes.storeRealization]: 'Sales invoices',
  [Routes.storeReturn]: 'Invoices for return to the supplier',
  [Routes.storeReturnClient]: 'Invoices for return to the client',
  [Routes.storeOrderProvider]: 'Invoices for order to the provider',
  [Routes.subCategories]: 'Sub categories',
  [Routes.storeLeftovers]: 'Leftovers',
  [Routes.storeSale]: 'Sales',
  [Routes.priceTags]: 'Price tags',
  [Routes.storeRelated]: 'Related documents',
  [Routes.integrations]: 'Integrations',
  [Routes.movementGoods]: 'Movement of goods',
  [Routes.accessDenied]: 'Access is denied',
  [Routes.finances]: 'Finances',
  [Routes.documents]: 'Documents',
  [Routes.invoices]: 'Invoices-T',
  [Routes.files]: 'Downloaded content',
};

export enum AclKey {
  order = 'order',
  client = 'client',
  company = 'company',
  service = 'service',
  employee = 'employee',
  employee_salary_settings = 'employee_salary_settings',
  calendar = 'calendar',
  settings = 'settings',
  template = 'template',
  payment_group = 'payment_group',
  notifications = 'notifications',
  reports = 'reports',
  abonement = 'abonement',
  status = 'status',
  product = 'product',
  department = 'department',
  store = 'store',
  priceMargin = 'priceMargin',
  payment = 'payment',
  integration = 'company_integration',
  orderStats = 'order_stats',
  invoices = 'invoices',
}

export const RoutesAcl: {
  [key: string]: AclKey;
} = {
  [Routes.services]: AclKey.service,
  [Routes.companies]: AclKey.company,
  [Routes.employees]: AclKey.employee,
  [Routes.employeeSalarySettings]: AclKey.employee_salary_settings,
  [Routes.payment_group]: AclKey.payment_group,
  [Routes.clients]: AclKey.client,
  [Routes.template]: AclKey.template,
  [Routes.orders]: AclKey.order,
  [Routes.calendar]: AclKey.calendar,
  [Routes.settings]: AclKey.settings,
  [Routes.reports]: AclKey.reports,
  [Routes.tariffModels]: AclKey.abonement,
  [Routes.clientAbonements]: AclKey.abonement,
  [Routes.customStatuses]: AclKey.status,
  [Routes.products]: AclKey.product,
  [Routes.payments]: AclKey.payment,
  [Routes.stores]: AclKey.store,
  [Routes.integrations]: AclKey.integration,
  [Routes.invoices]: AclKey.invoices,
};

export enum app_languages {
  Ukrainian = 'uk',
  English = 'en-us',
  Russian = 'ru-ru',
}

export enum REDUX_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export type ApiError = {
  message: string;
  status: number;
};

export const PLAN_STATUS = {
  NotActivated: SUBSCRIPTION_STATUS_DISABLED,
  In_processing: SUBSCRIPTION_STATUS_PROCESSING,
  Active: SUBSCRIPTION_STATUS_ACTIVE,
  Expired: SUBSCRIPTION_STATUS_EXPIRED,
};

export type KEYS_PLAN_STATUS = keyof typeof PLAN_STATUS;
export type VALUES_PLAN_STATUS = (typeof PLAN_STATUS)[KEYS_PLAN_STATUS];

export type Offset = {
  offset: number;
};

export enum PERSONALITY_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  UNSET = '',
}

export enum ClientPersonalityType {
  Individual = 'individual',
  Group = 'group',
}

export enum TIME_LIST_SERVICES {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type WithOptional<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export interface IListDateRangeOptions {
  isRangeChanged?: boolean;
  isClearDate?: boolean;
}

export interface IListProps extends IListDateRangeOptions {
  limit: number;
  offset: number;
}

export interface IListSearchProps extends IListProps {
  keywords?: string;
  sort?: string;
}

export interface IEntityProps {
  loadOnInit?: boolean;
  showLoading?: boolean;
}

export enum ErrorsStatus {
  Bad_Request = 400,
  Unauthorized = 401,
  Forbidden = 403,
  Conflict = 409,

  SubscriptionExpired = 410,
  Unprocessable_Entity = 422,
  Expired_Invalid_Token = 498,
  Server_Error = 500,
  Unknown = 600,
}

export enum MAILING_TEMPLATES_TYPE {
  SMS = 'sms',
  EMAIL = 'email',
  VIBER = 'viber',
  ALL = 'all',
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export enum ProductType {
  Product = 'products',
  Category = 'categories',
}

export enum SearchProductStatus {
  START_INPUT = 'start_input',
  PRESS_ENTER = 'press_enter',
  END_REQUEST = 'end_request',
}

export interface SearchProductStatusType {
  isEnterPress: boolean;
  status: SearchProductStatus;
}
