import { apiGet, apiPost, apiPut, apiPatch, apiDelete } from '../core/api';
import { contentApiUrl, userServiceUrl } from '../const';
import { tokenHandler } from '../core/token';
import { buildFormData } from '../helpers';
import { AclKey, ApiAnswer } from '../types';
import {
  ProfileDTO,
  IProfileDTOProps,
  ProfileMapper,
  IAuthDataDTOProps,
  IEmployeeDTOProps,
  ProfileFormDTO,
  ForgotPasswordFormDTO,
  LoginFormDTO,
  AuthDataDTO,
  Package,
  AuthDataMapper,
  ResetPasswordDTO,
  LocalizationDTO,
  EmailVerificationFormDTO,
} from '@structure';

export interface IProfileAnswer extends ApiAnswer {
  profile: IProfileDTOProps;
}

export interface IUserParams {
  identifier: string;
  password: string | number;
  invite_id?: string;
}

export interface IProfileBalanceParam {
  balance?: number | string;
  uuid?: string;
}

export interface IUserRegisterParams extends IUserParams {
  termsAccepted: boolean;
  allowNewsletters: boolean;
}

export interface IAuthorizeUserAnswer extends IAuthDataDTOProps {}

export interface IResetPasswordParam {
  password: string;
  token: string;
  id: string;
}

export interface Invitation {
  invitation: {
    uuid: string;
    status: number;
    created_at: string;
    updated_at: string;
  };
  employee: IEmployeeDTOProps;
  redirect_to_login: boolean;
}

export interface ISingUpFormDTOProps {
  name: string;
  phone: string;
  package: Package;
  password: string;
  password_confirmation: string;
}

export interface IVerificationAnswer {
  status: boolean;
  message: string;
  verification_code: string;
}

export const subscriptionPackages = [
  { uuid: Package.prof, title: Package.prof },
  { uuid: Package.trial, title: Package.trial },
  { uuid: Package.starter, title: Package.starter },
];

export const LOGIN_INITIAL_DATA = new LoginFormDTO();

export const REGISTER_INITIAL_DATA: IUserRegisterParams = {
  identifier: '',
  password: '',
  invite_id: '',
  termsAccepted: false,
  allowNewsletters: false,
};

export const PROFILE_INITIAL_DATA = new ProfileFormDTO();

const { uuid, ...rest } = PROFILE_INITIAL_DATA;

export const REQUIRED_PROFILE_FIELDS = { ...rest };

export const PROFILE_BALANCE_INITIAL_DATA: IProfileBalanceParam = {
  balance: '300.00',
};

export interface IInvitationParam {
  password: string;
  confirm_password: string;
  id: string;
}

export const PASSWORD_INITIAL_PARAM: Omit<IInvitationParam, 'id'> = {
  password: '',
  confirm_password: '',
};

export const FORGOT_PASSWORD_INITIAL_PARAM = new ForgotPasswordFormDTO();

export const acl = {
  [AclKey.order]: {
    read: true,
    manage: true,
  },
  [AclKey.client]: {
    read: true,
    manage: true,
  },
  [AclKey.company]: {
    read: true,
    manage: true,
  },
  [AclKey.service]: {
    read: true,
    manage: true,
  },
  [AclKey.employee]: {
    read: false,
    manage: true,
  },
  [AclKey.calendar]: {
    read: true,
    manage: true,
  },
  [AclKey.settings]: {
    read: true,
    manage: true,
  },
  [AclKey.template]: {
    read: true,
    manage: true,
  },
  [AclKey.payment_group]: {
    read: true,
    manage: true,
  },
  [AclKey.notifications]: {
    read: true,
    manage: true,
  },
  [AclKey.reports]: {
    read: true,
    manage: true,
  },
  [AclKey.abonement]: {
    read: true,
    manage: true,
  },
  [AclKey.status]: {
    read: true,
    manage: true,
  },
  [AclKey.product]: {
    read: true,
    manage: true,
  },
  [AclKey.department]: {
    read: true,
    manage: true,
  },
  [AclKey.store]: {
    read: true,
    manage: true,
  },
  [AclKey.priceMargin]: {
    read: true,
    manage: true,
  },
  [AclKey.payment]: {
    read: false,
    manage: false,
  },
};

export async function getProfile(
  redirect = true,
  access_token?: string,
): Promise<ProfileDTO> {
  const { profile } = await apiGet<null, IProfileAnswer>(
    `${contentApiUrl}/users/me`,
    null,
    {
      redirect,
    },
    access_token ? { Authorization: `Token ${access_token}` } : {},
  );

  return ProfileMapper.toProfileDTO(profile);
}

/**
 * @name editUser
 * @param {String} uuid
 * @param {String} first_name
 * @param {String} email
 */
export async function editUser({
  first_name,
  last_name,
  phone,
  email,
  birthday,
  default_company,
}: ProfileFormDTO & any): Promise<ProfileDTO> {
  const { profile } = await apiPut<
    Omit<ProfileFormDTO, 'uuid'> & any,
    IProfileAnswer
  >(`${contentApiUrl}/users/me`, {
    first_name,
    last_name,
    phone,
    email,
    birthday,
    default_company,
  });

  return ProfileMapper.toProfileDTO(profile);
}

export async function login({
  identifier,
  password,
  invite_id,
}: IUserParams): Promise<AuthDataDTO> {
  const res = await apiPost<IUserParams, IAuthorizeUserAnswer>(
    `${userServiceUrl}/auth`,
    {
      identifier,
      password,
      invite_id,
    },
    { ignoreRedirect: true },
  );

  // tokenHandler.setAuthTokens({
  //   refresh: res.refresh_token,
  //   access: res.access_token,
  //   is_verify_email: String(res?.user?.status),
  // });
  // tokenHandler.setRestoreFromCookiesStatus('1');

  return AuthDataMapper.toAuthDataDTO(res);
}

export async function acceptPolicy(
  access_token?: string,
): Promise<AuthDataDTO> {
  const token = access_token || tokenHandler.getAccessToken();

  const res = await apiPatch<null, IAuthorizeUserAnswer>(
    `${userServiceUrl}/users/accept-terms-and-conditions`,
    null,
    { ignoreRedirect: true },
    { Authorization: `Token ${token}` },
  );

  // tokenHandler.setVerifyEmailStatus(String(res?.user?.status));

  return AuthDataMapper.toAuthDataDTO(res);
}

export async function logout(): Promise<any> {
  const token = tokenHandler.getRefreshToken();

  return apiPost<{ token: string | null }, null>(
    `${userServiceUrl}/token/revoke`,
    {
      token,
    },
  );
}

export async function register({
  identifier,
  password,
}: IUserRegisterParams): Promise<any> {
  const { user, refresh_token, access_token, ...rest } = await apiPost<
    Partial<IUserRegisterParams>,
    IAuthorizeUserAnswer
  >(
    `${userServiceUrl}/auth`,
    {
      identifier,
      password,
    },
    { ignoreRedirect: true },
  );

  tokenHandler.setAuthTokens({
    refresh: refresh_token,
    access: access_token,
    is_verify_email: String(user?.status),
  });

  return { user, refresh_token, access_token, ...rest };
}

export async function restore({
  identifier,
}: Pick<IUserRegisterParams, 'identifier'>): Promise<any> {
  return apiPost<any, any>(
    `${userServiceUrl}/users/restore`,
    {
      identifier,
    },
    { ignoreRedirect: true },
  );
}

export interface IResetAnswer {
  status: boolean;
  message: string;
  verification: string;
}

export async function reset({
  id,
  token,
  password,
}: IResetPasswordParam): Promise<IResetAnswer> {
  return await apiPost(
    `${userServiceUrl}/users/reset`,
    {
      id,
      token,
      password,
    },
    { ignoreRedirect: true },
  );
}

export async function confirmEmail({
  id,
  token,
}: Partial<IResetPasswordParam>): Promise<any> {
  return await apiPost(
    `${userServiceUrl}/users/confirm-email`,
    {
      id,
      token,
    },
    { ignoreRedirect: true },
  );
}

export interface ISetProfilePictureProps {
  file: File;
}

export async function setProfilePicture({
  file,
}: ISetProfilePictureProps): Promise<ProfileDTO> {
  const picture = buildFormData('picture', file);

  const { profile } = await apiPost<FormData, any>(
    `${contentApiUrl}/users/me/picture`,
    picture,
    {},
    {},
    true,
  );

  return ProfileMapper.toProfileDTO(profile);
}

export async function getInvitationById({ id }: { id: string }): Promise<any> {
  return await apiGet<null, Invitation>(`${contentApiUrl}/invitations/${id}`);
}

export async function setPasswordOnInvitation({
  id,
  password,
  confirm_password,
}: IInvitationParam) {
  const { user, refresh_token, access_token, ...rest } = await apiPost<
    Omit<IInvitationParam, 'id'>,
    any
  >(`${contentApiUrl}/users/create-password-by-invitation/${id}`, {
    password,
    confirm_password,
  });

  tokenHandler.setAuthTokens({
    refresh: refresh_token,
    access: access_token,
    is_verify_email: String(user?.status),
  });

  return { user, refresh_token, access_token, ...rest };
}

export async function approveInvitations({ id }: any) {
  return await apiPost<any, any>(
    `${contentApiUrl}/invitations/${id}/approve`,
    {},
  );
}

export interface ILinkTopUpBalance {
  code: string;
  success: boolean;
  checkout_url: string;
}

export async function linkTopUpBalance(
  sum: number,
): Promise<ILinkTopUpBalance> {
  return await apiPut<{ sum: number }, any>(`${contentApiUrl}/users/balance`, {
    sum,
  });
}

export async function signup(
  value: ISingUpFormDTOProps,
  locale: Partial<LocalizationDTO>,
): Promise<AuthDataDTO> {
  const res = await apiPost<ISingUpFormDTOProps, IAuthDataDTOProps>(
    `${userServiceUrl}/auth/signup`,
    { ...value, ...locale },
  );

  return AuthDataMapper.toAuthDataDTO(res);
}

export async function verificationRestore(
  code: string,
  verification: string,
): Promise<IVerificationAnswer> {
  return await apiPost<{ code: string }, IVerificationAnswer>(
    `${userServiceUrl}/users/verification/${verification}`,
    {
      code,
    },
    { ignoreRedirect: true },
  );
}

export async function verificationSignup(code: string): Promise<AuthDataDTO> {
  const res = await apiPost<{ code: string }, IAuthDataDTOProps>(
    `${userServiceUrl}/users/verify-code-by-sms`,
    { code },
    { ignoreRedirect: true },
  );

  return AuthDataMapper.toAuthDataDTO(res);
}

export async function regenerationCode({
  identifier,
}: Pick<IUserRegisterParams, 'identifier'>): Promise<ApiAnswer> {
  return apiPost<Pick<IUserRegisterParams, 'identifier'>, ApiAnswer>(
    `${userServiceUrl}/users/generate-verification-code`,
    { identifier },
    { ignoreRedirect: true },
  );
}

export async function resetPassword(
  value: ResetPasswordDTO,
): Promise<AuthDataDTO> {
  const res = await apiPost<ResetPasswordDTO, IAuthDataDTOProps>(
    `${userServiceUrl}/users/set-password`,
    value,
    { ignoreRedirect: true },
  );
  // await isActivePlan(res?.user?.plans);
  //
  // await TokenHandler.setAuthTokens({
  //   refresh: res.refresh_token,
  //   access: res.access_token,
  //   is_verify_email: String(res?.user?.status),
  // });

  return AuthDataMapper.toAuthDataDTO(res);
}

export async function accountDeletion(): Promise<any> {
  return await apiDelete<void, any>(`${contentApiUrl}/users/me`);
}

export async function verifyByEmail(
  value: EmailVerificationFormDTO,
  access_token?: string,
  refresh_token?: string,
): Promise<AuthDataDTO> {
  const res = await apiPost<EmailVerificationFormDTO, IAuthDataDTOProps>(
    `${userServiceUrl}/users/verify-me-by-email`,
    {
      ...value,
    },
    { ignoreRedirect: true },
    access_token
      ? { Authorization: `Token ${access_token}`, refresh_token }
      : {},
  );

  return AuthDataMapper.toAuthDataDTO(res);
}
