import React from 'react';
import { IDefaultTabPanelProps } from '@components/lib/General';

export interface IProductTabPanelProps {
  children: (value: Pick<IDefaultTabPanelProps, 'tabs'>) => React.ReactNode;
}

export function ProductTabPanel({ children }: IProductTabPanelProps) {
  return <>{children({ tabs: [] })}</>;
}
