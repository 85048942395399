import { WithoutRecursion } from '../type';

import {
  CompanyDTO,
  CompanyMapper,
  ICompanyDTOProps,
  GroupPaymentFlowType,
  IGroupPaymentModelReturnType,
} from '../internal';

export interface IGroupPaymentDTOProps {
  uuid: string;
  title: string;
  description: string;
  is_cost_maker: boolean;
  is_system: boolean;
  payment_type: GroupPaymentFlowType;
  updated_at: string;
  created_at: string;
  companies: ICompanyDTOProps[];
}

export class GroupPaymentDTO {
  public uuid: string;

  public title: string;

  public description: string;

  public is_cost_maker: boolean;

  public is_system: boolean;

  public payment_type: GroupPaymentFlowType;

  public updated_at: string;

  public created_at: string;

  public companies: CompanyDTO[];

  constructor(
    props: IGroupPaymentDTOProps | IGroupPaymentModelReturnType,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.is_cost_maker = !!props?.is_cost_maker;
    this.is_system = !!props?.is_system;
    this.payment_type = props?.payment_type ?? GroupPaymentFlowType.INCOME;
    this.description = props?.description || '';
    this.updated_at = props?.updated_at || '';
    this.created_at = props?.created_at || '';
    this.companies =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? ((props?.companies || []) as CompanyDTO[])
        : CompanyMapper.toCompanyListDTO(
            // @ts-ignore
            props?.companies,
            0,
            [WithoutRecursion.groupPayment, ...withoutRecursion],
          ).companies;
  }
}
