import { LoadingOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BadgeItem } from '@components/lib/DataDisplay';

export interface IEmployeeBadgeAllItemProps {
  color: string;
  loading?: boolean;
  employeeLimit: number;
  amountOfActiveEmployee?: number;
  amountOfInactiveEmployee?: number;
  limitedAmountOfEmployee?: number;
}

const StyledInactiveEmployeeAmount = styled.span`
  font-size: 20px;
  font-weight: 500;
  padding-left: 3px;
  color: ${({ theme }) => theme.colors.error};
`;

const StyledBadgeEmployeeComment = styled.span<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export function EmployeeBadgeAllItem({
  color,
  loading = false,
  employeeLimit = 0,
  amountOfActiveEmployee = 0,
  amountOfInactiveEmployee = 0,
}: IEmployeeBadgeAllItemProps): React.JSX.Element {
  const theme: any = useTheme();
  const { t } = useTranslation();

  const allRegisteredEmployee = useMemo(
    () => Number(amountOfActiveEmployee) + Number(amountOfInactiveEmployee),
    [amountOfActiveEmployee, amountOfInactiveEmployee],
  );

  const availableAmountToCreate = useMemo(
    () => Number(employeeLimit) - allRegisteredEmployee,
    [employeeLimit, allRegisteredEmployee],
  );

  if (loading) {
    return <LoadingOutlined />;
  }

  return (
    <BadgeItem
      color={color}
      badgeStatsContent={
        <span>
          <Tooltip title={t('Amount of active employees')}>
            {amountOfActiveEmployee || '--'}
          </Tooltip>
          {amountOfInactiveEmployee > 0 && (
            <StyledInactiveEmployeeAmount>
              <Tooltip title={t('Amount of inactive employees')}>
                / {amountOfInactiveEmployee}
              </Tooltip>
            </StyledInactiveEmployeeAmount>
          )}
        </span>
      }
      badgeSubStatsComment={
        availableAmountToCreate > 0 ? (
          <StyledBadgeEmployeeComment $color={theme.colors.textSecondary}>
            {t('{{amount}} employee created out of {{limit}}', {
              amount: employeeLimit - availableAmountToCreate,
              limit: employeeLimit,
            })}
          </StyledBadgeEmployeeComment>
        ) : (
          <StyledBadgeEmployeeComment $color={theme.colors.accent}>
            {t('Employee limit exceeded({{amount}}/{{limit}})', {
              amount: allRegisteredEmployee,
              limit: employeeLimit,
            })}
          </StyledBadgeEmployeeComment>
        )
      }
    />
  );
}
