import React, { useCallback, useState } from 'react';
import { DatePicker } from '@components/lib/General';
import { Label } from '@components/lib/DataDisplay';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { toMoment, toDateByFormat } from '@services/helpers';
import { IUseStateRewardListReturnType } from '@hooks';
import { Moment } from 'moment';

export interface IRewardDatePickerProps
  extends Pick<IUseStateRewardListReturnType, 'handleRefreshRewards'> {
  fullWidth: boolean;
  loading: boolean;
  month: number;
  year: number;
}

const StyledDatePicker = styled(DatePicker)`
  min-width: 200px;

  & input {
    text-transform: capitalize;
  }
`;

export function RewardDatePicker({
  fullWidth,
  loading,
  month,
  year,
  handleRefreshRewards,
}: IRewardDatePickerProps): React.JSX.Element {
  const { t } = useTranslation();

  const [dateLoading, setDateLoading] = useState(false);

  const handleChangePicker = useCallback(
    async (date: any, dateString?: string | string[]) => {
      setDateLoading(true);

      const year = Number(toDateByFormat(date, 'YYYY'));
      const month = Number(toDateByFormat(date, 'MM'));

      await handleRefreshRewards({ year, month, page: 1 });

      setDateLoading(false);
    },
    [handleRefreshRewards],
  );

  return (
    <Label fullWidth={fullWidth}>
      <StyledDatePicker
        size="middle"
        format="MMMM YYYY"
        picker="month"
        defaultValue={toMoment(new Date()).set({ month: month - 1, year })}
        disabled={loading || dateLoading}
        placeholder={t('Date')}
        onChange={handleChangePicker}
      />
    </Label>
  );
}
