import React from 'react';
import { Routes, Route } from 'react-router';
import { EmployeeSalaryListPage } from 'views/Employees';
import { CalendarRoutes } from './CalendarRoutes';
import { AppRouteLayout } from '@components/lib/Layout';
import {
  EmployeeListPage,
  EmployeePage,
  EmployeeServiceListPage,
  EmployeeDayOffListPage,
  EmployeeCalendarPage,
  EmployeeSalarySettingsPage,
} from '../views/Employees';
import { Routes as RoutesType } from '@services/types';
import { RewardRoutesNavigator } from './RewardRoutesNavigator';

export function EmployeeRoutesNavigator(): JSX.Element {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AppRouteLayout>
            <EmployeeListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`${RoutesType.employeeSalary}`}
        element={
          <AppRouteLayout>
            <EmployeeSalaryListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path=":employeeId"
        element={
          <AppRouteLayout>
            <EmployeePage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:employeeId/${RoutesType.employeeServices}`}
        element={
          <AppRouteLayout>
            <EmployeeServiceListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:employeeId/${RoutesType.employeeSalarySettings}`}
        element={
          <AppRouteLayout>
            <EmployeeSalarySettingsPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:employeeId/${RoutesType.daysOff}`}
        element={
          <AppRouteLayout>
            <EmployeeDayOffListPage />
          </AppRouteLayout>
        }
      />
      <Route
        path={`:employeeId/${RoutesType.schedules}/*`}
        element={<CalendarRoutes CalendarComponent={EmployeeCalendarPage} />}
      />

      <Route
        path={`:employeeId/${RoutesType.rewards}/*`}
        element={<RewardRoutesNavigator />}
      />
    </Routes>
  );
}
