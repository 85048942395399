import React, { useEffect } from 'react';
import { Routes } from '@services/types';
import { isFunction } from '@services/helpers';
import { WithTranslation } from 'react-i18next';
import { IAuthRouteItemProps } from '../../Show';
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { ChildrenMustBeAFunction } from '@components/lib/Errors';

interface ITabChildrenProps {
  routes: Omit<IAuthRouteItemProps, keyof WithTranslation>[];
}

export interface IResetPasswordTabPanelProps {
  children: (value: ITabChildrenProps) => React.ReactNode;
}

export enum RESET_PASSWORD_TABS {
  Office = 'Office',
}

export function ResetPasswordTabPanel({
  children,
}: IResetPasswordTabPanelProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [routes, setRoutes] = React.useState<
    Omit<IAuthRouteItemProps, keyof WithTranslation>[]
  >([]);

  useEffect(() => {
    setRoutes([
      {
        key: RESET_PASSWORD_TABS.Office,
        title: location?.state?.backPageName || RESET_PASSWORD_TABS.Office,
        icon: <UserOutlined size={18} />,
        navigation: () => {
          navigate(
            location?.state?.from || `/${Routes.auth}/${Routes.authOffice}`,
          );
        },
      },
    ]);
  }, [location?.state?.backPageName, location?.state?.from, navigate]);

  if (!isFunction(children)) {
    throw new ChildrenMustBeAFunction('ResetPasswordTabPanel');
  }

  return <>{children({ routes })}</>;
}
