import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { BadgeItem } from '@components/lib/DataDisplay';

export interface IEmployeeBadgeScheduleItemProps {
  color?: string;
  loading?: boolean;
  amountOfShifts?: number;
  dateOfShiftStats?: Date | null;
}

export function EmployeeBadgeScheduleItem({
  color,
  loading,
  amountOfShifts,
  dateOfShiftStats,
}: IEmployeeBadgeScheduleItemProps): React.JSX.Element {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingOutlined />;
  }
  const month = dateOfShiftStats
    ? t(
        dateOfShiftStats
          ?.toLocaleString('default', { month: 'long' })
          ?.toLowerCase() || '',
      )?.toLowerCase()
    : 'N/A';

  return (
    <BadgeItem
      color={color}
      badgeStatsContent={amountOfShifts || '--'}
      badgeSubStatsComment={t('Registered for {{month}}', { month })}
    />
  );
}
