import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StyledDescription } from '../Styled/Text';
import { isFunction } from '@services/helpers';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import ANTDSegmented, {
  SegmentedProps,
  SegmentedLabeledOption,
  SegmentedValue,
} from 'antd/es/segmented';
export interface IOption {
  title: string | React.ReactNode;
  uuid: string;
  description?: string;
  disabled?: boolean;
}

export interface IOptionsProps {
  options: IOption[];
}

export interface ISegmentedProps
  extends Omit<SegmentedProps, 'options'>,
    IOptionsProps {
  disabledItem?: (item: IOption) => boolean;
  addonAfter?: React.ReactNode;
}

export const StyledContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  & > span {
    margin-top: 5px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledANTDSegmented = styled(ANTDSegmented)<{
  $withAddon: boolean;
}>`
  ${({ $withAddon }) =>
    $withAddon &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}

  & .ant-segmented-item-selected,
  & .ant-segmented-thumb-motion-appear-active {
    background-color: ${({ theme }) => theme.colors.link};
    color: ${({ theme }) => theme.colors.white};
  }

  & .ant-segmented-item-selected {
    .ant-segmented-item-label * {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Segmented = forwardRef(function Segmented(
  {
    options,
    value,
    onChange,
    ref: aRef,
    as = '',
    disabledItem,
    addonAfter,
    ...rest
  }: ISegmentedProps,
  ref: any,
): JSX.Element {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<SegmentedValue | undefined>(
    undefined,
  );

  const toSegmentedOption = useCallback(
    ({ title, uuid, disabled, ...rest }: IOption): SegmentedLabeledOption => ({
      label: typeof title === 'string' ? t(title) : title,
      value: uuid,
      disabled: isFunction(disabledItem)
        ? disabledItem({ title, uuid, disabled, ...rest })
        : disabled,
    }),
    [disabledItem, t],
  );

  const handleChange = useCallback(
    (value: any) => {
      setSelected(value);

      if (isFunction(onChange)) {
        onChange(value);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (value && selected === undefined) {
      handleChange(value);
    }
  }, [selected, value, handleChange]);

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledANTDSegmented
          {...rest}
          $withAddon={!!addonAfter}
          ref={ref}
          options={options.map(toSegmentedOption)}
          onChange={handleChange}
          value={value}
        />
        {options.map(({ description, uuid }) =>
          description && uuid === selected ? (
            <StyledDescription key={uuid}>{t(description)}</StyledDescription>
          ) : null,
        )}
      </StyledContainer>
      {addonAfter || null}
    </StyledWrapper>
  );
});
