import { WithoutRecursion } from '../type';

import {
  IStatusModelReturnType,
  IStatusCategoryDTOProps,
  StatusCategoryDTO,
  StatusMapper,
  ProfileDTO,
  IProfileDTOProps,
  ProfileMapper,
  ProfileModel,
} from '../internal';

export enum ExecuteOnSet {
  Comment = 'comment',
  Payment = 'payment',
  ClosePayment = 'close_payment',
}

export interface IStatusDTOProps {
  uuid: string;
  title: string;
  execute_on_set: ExecuteOnSet | string;
  allowed_statuses_from: string[];
  allowed_statuses_to: string[];
  created_at: string;
  updated_at: string;
  category: IStatusCategoryDTOProps;
  created_by: IProfileDTOProps;
}

export class StatusDTO {
  public uuid: string;
  public title: string;
  public execute_on_set: ExecuteOnSet | string;
  public allowed_statuses_from: string[];
  public allowed_statuses_to: string[];
  public created_at: string;
  public updated_at: string;
  public category: StatusCategoryDTO;
  public created_by: ProfileDTO;

  constructor(
    props: IStatusModelReturnType | IStatusDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title || '';
    this.execute_on_set = props?.execute_on_set || '';
    this.allowed_statuses_from = props?.allowed_statuses_from || [];
    this.allowed_statuses_to = props?.allowed_statuses_to || [];
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.category = StatusMapper.toStatusCategoryDTO(props?.category || {});
    this.created_by = ProfileMapper.toProfileDTO(
      (props?.created_by as unknown as ProfileModel) || {},
    );
  }
}
