import { MenuProps } from 'antd';
import { useAcl } from '@contex';
import { useTranslation } from 'react-i18next';
import { EmployeeActionButtons } from '../Buttons';
import { useTheme } from 'styled-components';
import EmployeeTabPanel from '../Show/EmployeeTabPanel';
import { EmployeeHeader, EmployeeOverviewSection } from '../Show';
import { Routes, RoutesAcl } from '@services/types';
import React, { Suspense, useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { EmployeeMapper, EmployeeStatuses } from '@structure';
import { DocumentControlPanel } from '@components/lib/General';
import { EMPLOYEE_REQUIRED_FIELD } from '@services/api/employee';
import { useBreadcrumb, useModal } from '@components/lib/libV2/hooks';
import { HeaderLeft, SuspenseEmpty } from '@components/lib/DataDisplay';
import { StyledTitle } from '@components/lib/Styled';
import { OverviewHeader, OverviewLayout } from '@components/lib/Layout';

import {
  FaUserLock,
  FaMoneyBill,
  FaPaintRoller,
  FaUserClock,
  FaCalendarAlt,
  FaPercent,
  FaCalendarWeek,
  FaUserShield,
  FaUserSecret,
} from 'react-icons/fa';

import {
  useRequiredFields,
  useStopLoading,
  useStoredCompanies,
  useStoreEmployee,
} from '@hooks';

const EmployeeSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeSideWindow'),
);

const EmployeeRoleSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeRoleSideWindow'),
);

const EmployeeUserDataSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeUserDataSideWindow'),
);

const EmployeeAccessManagementSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeAccessManagementWindow'),
);

const EmployeeSalaryAndBonusesSideWindowLazy = React.lazy(
  () => import('../Show/EmployeeSalaryAndBonusesSideWindow'),
);

export default function EmployeePage() {
  const theme: any = useTheme();
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ employee }) => employee);

  const {
    employee,
    loading: employeeLoading,
    error: employeeError,
    handleUpdateEmployee,
    handleSetEmployeePassword,
    handleSetEmployeeRole,
    handleBlockEmployee,
    handleEmployeeSalaryAndBonuses,
    handleAcceptEmployeeInvite,
    handleCancelEmployeeInvite,
  } = useStoreEmployee({
    employeeUuid: employeeId!,
    companyUuid: defaultCompanyUuid,
  });

  const disabled = employee?.status === EmployeeStatuses.DELETED;

  const loading = useStopLoading({
    loading: employeeLoading,
    error: employeeError,
    message: 'An error occurred during employee loading',
  });

  const breadcrumbs = useMemo(
    () => [
      {
        path: `/${Routes.employees}`,
        breadcrumbName: 'Employees',
      },
      {
        path: `/${Routes.employees}/${employeeId}`,
        breadcrumbName: employee?.fullName || t('Show Employee'),
      },
    ],
    [employeeId, employee, t],
  );
  const routes = useBreadcrumb(breadcrumbs);

  const overviewData = useRequiredFields(employee, EMPLOYEE_REQUIRED_FIELD);

  const navigateGoBack = useCallback(() => {
    navigate(location?.state?.goBack || `/${Routes.app}/${Routes.employees}`);
  }, [location?.state?.goBack, navigate]);

  const navigateToSalarySettingsPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.employeeSalarySettings}`,
    );
  }, [navigate, employeeId]);

  const navigateToEmployeeServicesPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.employeeServices}`,
      {
        state: { employeeFullName: employee?.fullName },
      },
    );
  }, [navigate, employeeId, employee?.fullName]);

  const navigateToEmployeeDaysOffPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.daysOff}`,
      {
        state: { employeeFullName: employee?.fullName },
      },
    );
  }, [navigate, employeeId, employee?.fullName]);

  const navigateToEmployeeServiceCalendarPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.schedules}`,
      {
        state: { employeeFullName: employee?.fullName },
      },
    );
  }, [navigate, employeeId, employee?.fullName]);

  const navigateToEmployeeSalaryCalculationPage = useCallback(() => {
    navigate(
      `/${Routes.app}/${Routes.employees}/${employeeId}/${Routes.rewards}`,
      {
        state: { employeeFullName: employee?.fullName },
      },
    );
  }, [navigate, employeeId, employee?.fullName]);

  const {
    handleOnInit: employeeEditOnInit,
    handleCancel: employeeEditHandleCancel,
    handleSuccess: serviceDocumentEditSuccess,
    visible: employeeEditDocumentVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleUpdateEmployee,
  });

  const {
    handleOnInit: employeeRoleEditOnInit,
    handleSuccess: employeeRoleEditHandleSuccess,
    handleCancel: employeeRoleEditHandleCancel,
    visible: employeeEditRoleDialogVisibility,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleSetEmployeeRole,
  });

  const {
    handleOnInit: handleEmployeeSetPasswordOnInit,
    handleCancel: handleSetEmployeePasswordCancel,
    handleSuccess: handleSetEmployeePasswordSuccess,
    visible: employeeChangePasswordVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleSetEmployeePassword,
  });

  const {
    handleOnInit: handleEmployeeAccessManagementOnInit,
    handleCancel: handleEmployeeAccessManagementCancel,
    handleSuccess: handleEmployeeAccessManagementSuccess,
    visible: employeeAccessManagementVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleBlockEmployee,
  });

  const {
    // handleOnInit: handleEmployeeSalaryAndBonusesOnInit,
    handleCancel: handleEmployeeSalaryAndBonusesCancel,
    handleSuccess: handleEmployeeSalaryAndBonusesSuccess,
    visible: employeeSalaryAndBonusesVisible,
  } = useModal({
    onCancel: () => {},
    onSuccess: handleEmployeeSalaryAndBonuses,
  });

  const printOptions: MenuProps['items'] = [];
  const documentOptions: MenuProps['items'] = useMemo(
    () => [
      {
        key: 'access-to-system',
        type: 'group',
        label: t('Access to the system'),
        children: [
          {
            key: 'employee-change-role',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('Change role')}</StyledTitle>,
            icon: <FaUserShield size={18} />,
            onClick: employeeRoleEditOnInit,
          },
          {
            key: 'employee-set-password',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('Set password')}</StyledTitle>,
            icon: <FaUserSecret size={18} />,
            onClick: handleEmployeeSetPasswordOnInit,
          },
          {
            key: 'employee-set-availability',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('Availability')}</StyledTitle>,
            icon: <FaUserLock size={18} color={theme.colors.primary} />,
            onClick: handleEmployeeAccessManagementOnInit,
          },
        ],
      },
      { type: 'divider' },
      {
        key: 'reports-for-employee',
        type: 'group',
        label: t('Reports'),
        children: [
          {
            key: 'employee-calendar',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('The employee calendar')}</StyledTitle>,
            icon: <FaCalendarAlt size={18} />,
            onClick: navigateToEmployeeServiceCalendarPage,
          },
          {
            key: 'employee-salary-calculation',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('Salary calculation')}</StyledTitle>,
            icon: <FaMoneyBill size={18} />,
            onClick: navigateToEmployeeSalaryCalculationPage,
          },
        ],
      },
      { type: 'divider' },
      {
        key: 'settings-of-employee',
        type: 'group',
        label: t('Settings'),
        children: [
          {
            key: 'employee-set-services',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('The employee services')}</StyledTitle>,
            icon: <FaPaintRoller size={18} />,
            onClick: navigateToEmployeeServicesPage,
          },
          {
            key: 'employee-set-schedule',
            disabled: true, //!manage || disabled,
            label: <StyledTitle>{t('Employee schedule')}</StyledTitle>,
            icon: <FaUserClock size={18} />,
            onClick: () => {},
          },
          {
            key: 'employee-set-days-off',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('Employee days off')}</StyledTitle>,
            icon: <FaCalendarWeek size={18} />,
            onClick: navigateToEmployeeDaysOffPage,
          },
          {
            key: 'employee-set-salary',
            disabled: !manage || disabled,
            label: <StyledTitle>{t('The salary settings')}</StyledTitle>,
            icon: <FaPercent size={18} />,
            // onClick: handleEmployeeSalaryAndBonusesOnInit,
            onClick: navigateToSalarySettingsPage,
          },
        ],
      },
    ],
    [
      t,
      manage,
      disabled,
      theme.colors.primary,
      employeeRoleEditOnInit,
      navigateToSalarySettingsPage,
      navigateToEmployeeDaysOffPage,
      navigateToEmployeeServicesPage,
      handleEmployeeSetPasswordOnInit,
      navigateToEmployeeServiceCalendarPage,
      navigateToEmployeeSalaryCalculationPage,
      // handleEmployeeSalaryAndBonusesOnInit,
      handleEmployeeAccessManagementOnInit,
    ],
  );

  return (
    <>
      <EmployeeTabPanel disabled={disabled} employee={employee}>
        {({ tabs }) => (
          <OverviewLayout
            aclItem={RoutesAcl[Routes.employees]}
            headerLeft={
              <HeaderLeft absolute={false} onClick={navigateGoBack} />
            }
            headerRight={
              <DocumentControlPanel
                printItems={printOptions}
                items={documentOptions}
                onEditInit={employeeEditOnInit}
                isDraft={true}
              />
            }
            headerTitle={t('Employee')}
            disabled={!manage}
            editTooltip={t('Edit Employee')}
            loading={loading}
            data={overviewData}
            routes={routes}
            tabs={tabs}
            footerContentExtra={<EmployeeOverviewSection employee={employee} />}
            header={
              <OverviewHeader
                withoutUpload
                withoutIndent
                title={''}
                description={
                  <EmployeeHeader
                    employee={employee}
                    showPhone
                    showBalance
                    explainBalance
                  />
                }
                image={employee?.picture_url}
                handleUploadImage={async () => {
                  return '';
                }}
                extra={
                  <EmployeeActionButtons
                    disabled={disabled}
                    manage={manage}
                    employee={employee}
                    handleBlockEmployee={handleBlockEmployee}
                    handleAcceptEmployeeInvite={handleAcceptEmployeeInvite}
                    handleCancelEmployeeInvite={handleCancelEmployeeInvite}
                    employeeId={employeeId}
                    handleSetEmployeePassword={handleSetEmployeePassword}
                    loading={loading}
                  />
                }
              />
            }
          />
        )}
      </EmployeeTabPanel>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeSideWindowLazy
          employee={
            (employee && EmployeeMapper.toEmployeeFormDTO(employee)) ||
            undefined
          }
          editMode
          onSuccess={serviceDocumentEditSuccess}
          visible={employeeEditDocumentVisible}
          onCancel={employeeEditHandleCancel}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeRoleSideWindowLazy
          onSuccess={employeeRoleEditHandleSuccess}
          visible={employeeEditRoleDialogVisibility}
          onCancel={employeeRoleEditHandleCancel}
          employee={
            (employee && EmployeeMapper.toEmployeeRoleFormDTO(employee)) ||
            undefined
          }
          isEmployeeOwner={!!employee?.is_owner}
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeUserDataSideWindowLazy
          onSuccess={handleSetEmployeePasswordSuccess}
          visible={employeeChangePasswordVisible}
          onCancel={handleSetEmployeePasswordCancel}
          employee={
            (employee && EmployeeMapper.toEmployeeUserDataFormDTO(employee)) ||
            undefined
          }
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeAccessManagementSideWindowLazy
          visible={employeeAccessManagementVisible}
          onCancel={handleEmployeeAccessManagementCancel}
          onSuccess={handleEmployeeAccessManagementSuccess}
          employee={
            (employee &&
              EmployeeMapper.toEmployeeAccessManagementFormDTO(employee)) ||
            undefined
          }
        />
      </Suspense>

      <Suspense fallback={<SuspenseEmpty />}>
        <EmployeeSalaryAndBonusesSideWindowLazy
          visible={employeeSalaryAndBonusesVisible}
          onCancel={handleEmployeeSalaryAndBonusesCancel}
          onSuccess={handleEmployeeSalaryAndBonusesSuccess}
          employee={
            (employee &&
              EmployeeMapper.toEmployeeSalaryAndBonusesFormDTO(employee)) ||
            undefined
          }
        />
      </Suspense>
    </>
  );
}
