import { WithoutRecursion } from '../type';
import { COLOR_GREEN, correctPrice } from '@services/helpers';
import { PERSONALITY_TYPE, TIME_LIST_SERVICES } from '@services/types';

import {
  EmployeeDTO,
  GroupDTO,
  IGroupDTOProps,
  IEmployeeServiceDTOProps,
  EmployeeServiceDTO,
  EmployeeMapper,
  EmployeeServiceMapper,
  GroupMapper,
  IServiceModelReturnType,
  IEmployeeDTOProps,
  EmployeeModel,
  EmployeeServiceModel,
} from '../internal';

export enum ServiceStatuses {
  'Blocked' = 0,
  'Active' = 1,
}

export enum ServiceMultiSessionablePeriods {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface ServiceMultiSessionable {
  index: number;
  comment: string;
  period: ServiceMultiSessionablePeriods;
  amount: number;
}

export const DEFAULT_SERVICE_MULTI_SESSIONABLE: ServiceMultiSessionable = {
  index: 0,
  comment: '',
  period: ServiceMultiSessionablePeriods.HOUR,
  amount: 1,
};

export interface IServiceDTOProps {
  uuid: string;
  title: string;
  description: string;
  comment: string;
  color_schema: string;
  personality: PERSONALITY_TYPE;
  period: TIME_LIST_SERVICES;
  created_at: string;
  updated_at: string;
  price: string;
  net_price: string;
  period_amount: number;
  employee_service?: IEmployeeServiceDTOProps;
  group: IGroupDTOProps;
  payment_group: IGroupDTOProps;
  manager: IEmployeeDTOProps;
  used_by_employees?: IEmployeeServiceDTOProps[];
  group_session: IGroupDTOProps[];
  status: ServiceStatuses;
  is_multi_sessionable: boolean;
  multi_sessions: ServiceMultiSessionable[];
}

export class ServiceDTO {
  public uuid: string;

  public title: string;

  public description: string;
  public comment: string;

  public color_schema: string;

  public created_at: string;

  public updated_at: string;

  public period_amount: number;

  public price: string;
  public net_price: string;

  public status: ServiceStatuses;

  public personality: PERSONALITY_TYPE;

  public period: TIME_LIST_SERVICES;

  public employee_service?: EmployeeServiceDTO;

  public manager: EmployeeDTO;

  public group: GroupDTO;

  public payment_group: GroupDTO;

  public used_by_employees?: EmployeeServiceDTO[];

  public group_session: GroupDTO[];
  public is_multi_sessionable: boolean;
  public multi_sessions: ServiceMultiSessionable[];

  constructor(
    props: IServiceModelReturnType | IServiceDTOProps | ServiceDTO,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.title = props?.title;
    this.description = props?.description || '';
    this.comment = props?.comment || '';
    this.color_schema = props?.color_schema || COLOR_GREEN;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.personality = props?.personality || PERSONALITY_TYPE.INDIVIDUAL;
    this.period = props?.period || TIME_LIST_SERVICES.HOUR;
    this.period_amount = props?.period_amount || 0;
    this.price = correctPrice(props?.price || 0);
    this.net_price = correctPrice(props?.net_price || 0);
    this.status = props?.status || ServiceStatuses.Active;

    this.is_multi_sessionable = props?.is_multi_sessionable ?? false;
    this.multi_sessions = props?.multi_sessions || [];

    this.employee_service = props?.employee_service
      ? EmployeeServiceMapper.toEmployeeServiceDTO(
          props?.employee_service as unknown as EmployeeServiceModel,
          [WithoutRecursion.service, ...withoutRecursion],
        )
      : undefined;

    this.group =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? (props?.group as GroupDTO)
        : GroupMapper.toGroupDTO(props?.group, [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);

    this.payment_group =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? (props?.group as GroupDTO)
        : GroupMapper.toGroupDTO(props?.payment_group, [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);
    this.group_session =
      withoutRecursion.indexOf(WithoutRecursion.group) !== -1
        ? ((props?.group_session || []) as GroupDTO[])
        : GroupMapper.toGroupListDTO(props?.group_session || [], [
            WithoutRecursion.service,
            ...withoutRecursion,
          ]);

    this.manager =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.manager as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(
            props?.manager as unknown as EmployeeModel,
            [WithoutRecursion.service, ...withoutRecursion],
          );

    this.used_by_employees = props?.used_by_employees
      ? withoutRecursion.indexOf(WithoutRecursion.employeeService) !== -1
        ? ((props?.used_by_employees || []) as EmployeeServiceDTO[])
        : EmployeeServiceMapper.toEmployeeServiceListDTO(
            // @ts-ignore
            props?.used_by_employees || [],
            0,
            [WithoutRecursion.service, ...withoutRecursion],
          ).employeeServices
      : undefined;
  }
}
