import React from 'react';
import { Tag } from 'antd';
import { EmployeeRole } from '@structure';
import styled, { css, useTheme } from 'styled-components';

export interface EmployeeRoleTagProps {
  label: EmployeeRole;
  closable?: boolean;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  value: EmployeeRole;
  className?: string;
  disabled?: boolean;
}

const StyledTag = styled(Tag)`
  display: flex;
  flex-direction: row;
  margin-right: 0;
`;

export function EmployeeRoleTag({
  label,
  value,
  closable,
  onClose,
  className,
  disabled,
  ...rest
}: EmployeeRoleTagProps) {
  const theme: any = useTheme();

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const EmployeeRoleColors = {
    [EmployeeRole.CASHIER]: 'orange',
    [EmployeeRole.Administrator]: 'geekblue',
    [EmployeeRole.Manager]: 'purple',
    [EmployeeRole.EMPLOYEE_SALARY_MANAGER]: 'green',
  };

  return (
    <StyledTag
      className={className}
      color={disabled ? theme.colors.lightDisable : EmployeeRoleColors[value]}
      onMouseDown={onPreventMouseDown}
      closable={!!closable}
      onClose={onClose}
      {...rest}>
      {label}
    </StyledTag>
  );
}
