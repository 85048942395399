import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Empty } from 'antd';
import { ListLayout } from '@components/lib/Layout';
import {
  useStoreEmployee,
  useStoredEmployeeServiceList,
  useStopLoading,
  useStoredCompanies,
  useStoreService,
  useKeyboardOpenForm,
  useSearchInputFocus,
} from '@hooks';
import { textToUpperCase } from '@services/helpers';
import { useBreadcrumb } from '@components/lib/libV2/hooks';
import {
  Routes as RoutesType,
  Routes,
  RoutesAcl,
  RoutesTitle,
} from '@services/types';
import { ServiceModel } from '@structure';
import { useAcl } from '@contex';
import { HeaderLeft, ListActions } from '@components/lib/DataDisplay';
import { useLocation, useNavigate, useParams } from 'react-router';
import { EmployeeServiceList } from '../../EmployeeService';
import { ServicesCreateButton } from '../../Services';
import { List } from 'immutable';
import styled from 'styled-components';
import { successButtonStyle } from '@components/lib/Styled';
import { PlusOutlined } from '@ant-design/icons';

const StyledServicesCreateButton = styled(ServicesCreateButton)`
  ${successButtonStyle}
`;

export function EmployeeServiceListPage(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();
  const { t } = useTranslation();

  const [selectedServices, setSelectedServices] = useState<ServiceModel[]>([]);

  const { defaultCompanyUuid } = useStoredCompanies();
  const { manage } = useAcl(({ employee }) => employee);

  const { ref: listRef } = useKeyboardOpenForm({
    className: 'employee-service-create-event',
    disabled: !manage,
  });

  const {
    employee,
    employeeServiceBonuses,
    loading: employeeLoading,
    error: employeeError,
  } = useStoreEmployee({
    companyUuid: defaultCompanyUuid,
    employeeUuid: employeeId!,
  });

  const { handleCreateService } = useStoreService({
    loadOnInit: false,
    serviceUuid: '',
    companyUuid: '',
  });

  const {
    employeeServiceList: services,
    loading: serviceLoading,
    total,
    error: serviceError,
    limit,
    page,
    loadingMore,

    handleRefreshEmployeeServices,
    handleDeleteServicesFromListEmployee: onDeleteServicesFromListEmployee,
    handleAddServiceToListEmployee,
    handleUpdateServiceFromListEmployee,
    handleSearchEmployeeServices,
  } = useStoredEmployeeServiceList({
    employeeUuid: employeeId!,
    employee,
  });

  const loading = useStopLoading({
    loading: employeeLoading || serviceLoading || loadingMore,
    error: employeeError || serviceError,
    message: 'An error occurred during employees loading',
  });

  const { inputRef, focus, daleyFocus } = useSearchInputFocus({ loading });

  const handleDeleteServicesFromListEmployee = useCallback(
    async (value: string[]) => {
      await onDeleteServicesFromListEmployee(value);
      focus();
    },
    [focus, onDeleteServicesFromListEmployee],
  );

  useEffect(() => {
    if (List.isList(services)) {
      const selected = services.filter(
        ({ employee_service }) => !!employee_service?.updated_at,
      );

      setSelectedServices(selected.toArray());
    }
  }, [employeeServiceBonuses, services]);

  const rowSelection = useMemo(
    () => ({
      selectedRowKeys: selectedServices.map(({ uuid }) => uuid),
      hideSelectAll: true,
      selections: false,
      renderCell: (
        checked: boolean,
        record: ServiceModel[],
        index: number,
        originNode: React.ReactNode,
      ): React.ReactNode => (checked ? originNode : <></>),
    }),
    [selectedServices],
  );

  const routes = useBreadcrumb([
    {
      path: `/${Routes.employees}`,
      breadcrumbName: 'Employees',
    },
    {
      path: `/${Routes.employees}/${employeeId}`,
      breadcrumbName: location?.state?.employeeFullName || 'Show Employee',
    },
    {
      path: `/${Routes.employees}/${employeeId}/${Routes.services}`,
      breadcrumbName: 'Services',
    },
  ]);

  const navigateGoBack = useCallback(() => {
    navigate(`/${Routes.app}/${Routes?.employees}/${employeeId}`);
  }, [employeeId, navigate]);

  return (
    <ListLayout
      aclItem={RoutesAcl[Routes.employees]}
      headerLeft={<HeaderLeft absolute={false} onClick={navigateGoBack} />}
      headerTitle={textToUpperCase(t(RoutesTitle[RoutesType.employeeServices]))}
      headerRight={
        <StyledServicesCreateButton
          disabled={!manage}
          loading={loading}
          type="primary"
          companyUuid={defaultCompanyUuid}
          onSuccess={handleCreateService as any}
          title={t('Create service for employee')}
          icon={<PlusOutlined />}
          onCancel={daleyFocus}
        />
      }
      routes={routes}
      headerExtra={
        <>
          <Alert
            message={t('The employee services')}
            description={t(
              'The services current Employee is allowed to provide marked as selected in the list. By default' +
                ' the price of the service is taken from the service details but it can be changed for particular' +
                ' employee in the list below or on the service page',
            )}
            type="info"
            showIcon
          />
          <br />
          <ListActions
            inputRef={inputRef}
            withoutPicker={true}
            loading={loading}
            handleSearch={handleSearchEmployeeServices}
            inputTooltip={t('Search services')}
            inputLabel={t('Search services')}
          />
        </>
      }
      empty={
        services?.size ? null : (
          <Empty
            description={t(
              `It looks like you don't have any service at the moment.`,
            )}>
            {
              <ServicesCreateButton
                type="default"
                disabled={!manage}
                companyUuid={defaultCompanyUuid}
                onSuccess={handleCreateService as any}
                loading={loading}
                onCancel={daleyFocus}>
                {t('Add company service')}
              </ServicesCreateButton>
            }
          </Empty>
        )
      }
      loading={loading && !services?.size}>
      <EmployeeServiceList
        disabled={false}
        services={services}
        loading={loading}
        handleUpdate={handleUpdateServiceFromListEmployee}
        handleDelete={handleDeleteServicesFromListEmployee}
        handleAdd={handleAddServiceToListEmployee}
        rowSelection={rowSelection}
        pageSize={limit}
        total={total}
        refresh={handleRefreshEmployeeServices}
        selectedServices={selectedServices}
        page={page}
        employeeServiceBonuses={employeeServiceBonuses}
        daleyFocus={daleyFocus}
      />
    </ListLayout>
  );
}
