import React, { useCallback, useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Breadcrumb, IBreadcrumbProps } from '../DataDisplay';
import { HeaderLeft } from '../DataDisplay/HeaderLeft';
import { MarkedBadge } from '../DataDisplay/MarkedBadge';
import { Text } from '../Format';
import { useNavigate } from 'react-router';
import { StyledDescription } from '../Styled';
import { useTranslation } from 'react-i18next';
import { Routes } from '@services/types';
import { useDropdownAlert } from '@contex';
import { AppUserHeaderBar } from './AppUserHeaderBar';
import { useInterval, useStoredPlanList } from '@hooks';
import { textToUpperCase } from '@sportix/sportix-common-modules';
import { HEADER_HEIGHT } from './const';

export interface IAppHeaderProps
  extends Partial<Pick<IBreadcrumbProps, 'routes'>> {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerTitle?: React.ReactNode | string;
  headerTitleDescription?: React.ReactNode | string;
  isCalendar?: boolean;
  isHeaderFlickering?: boolean;
  underHeaderContent?: React.ReactNode;
  className?: string;
  showWarning?: boolean;
  leftTitle?: string;
}

const FLICKER_TIME = 5000;

const StyledHeaderContainer = styled.div<{
  $isCalendar?: boolean;
  $width: null;
}>`
  position: fixed;
  top: 0;
  z-index: ${({ $isCalendar }) => ($isCalendar ? 900 : 10)};
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  height: auto;
  background-color: ${({ theme }) => theme.background.primary};
  margin-bottom: 15px;
`;

const StyledHeader = styled(Layout.Header)`
  &&& {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${HEADER_HEIGHT - 40}px;
    padding-left: 5px;
    padding-right: 12px;
    background-color: inherit;
  }
`;

const StyledHeaderLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

const StyledHeaderLeft = styled(HeaderLeft)`
  position: static;
`;

const StyledHeaderTitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 0;
  line-height: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledHeaderBreadcrumbContainer = styled.div`
  position: absolute;
  left: 23px;
  top: 0;
  display: flex;
  overflow: hidden;
`;

const StyledHeaderTitle = styled(Text)`
  font-size: 20px;
  font-weight: 550;
  text-overflow: ellipsis;
  letter-spacing: 2px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

const Description = styled(StyledDescription)`
  //display: flex;
  //flex-direction: column;
  //width: 100%;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
`;

const StyledHeaderRightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const StyledMarkedBadge = styled(MarkedBadge)`
  margin: 0 auto;
`;

const StyledMarkedBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  height: 30px;
`;

const StyledUnderHeaderContent = styled.div`
  //box-shadow: rgba(0, 0, 0, 0.25) 3px 7px 5px -1px;
`;

export const AppHeader = forwardRef(function AppHeader(
  {
    headerLeft,
    headerRight,
    headerTitle,
    routes,
    isCalendar,
    headerTitleDescription,
    isHeaderFlickering,
    underHeaderContent,
    className,
    showWarning = true,
    leftTitle,
  }: IAppHeaderProps,
  ref: any,
): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { options } = useDropdownAlert();
  // const {setDropdownOptions, } = useDropdownAlert();

  const { planList } = useStoredPlanList({ loadOnInit: false });

  const plan = planList?.first();

  const [showDescription, setShowDescription] = useState(false);

  const { intervalCallback, handleClearInterval } = useInterval(() => {
    setShowDescription((prevState) => !prevState);
  }, FLICKER_TIME);

  const navigationGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleNavigateToSubscriptionPage = useCallback(() => {
    navigate(`/${Routes.app}/${Routes.subscription}`);
  }, [navigate]);

  useEffect(() => {
    if (isHeaderFlickering && plan?.isShowSubscriptionWarning) {
      intervalCallback();
    }

    return () => {
      if (isHeaderFlickering && plan?.isShowSubscriptionWarning) {
        handleClearInterval();
      }
    };
  }, [
    handleClearInterval,
    intervalCallback,
    isHeaderFlickering,
    plan?.isShowSubscriptionWarning,
  ]);

  return (
    <StyledHeaderContainer
      className={className}
      ref={ref}
      $isCalendar={isCalendar}
      $width={options?.style?.width}>
      <AppUserHeaderBar />
      <StyledHeader>
        <StyledHeaderLeftContainer>
          {headerLeft ? (
            headerLeft
          ) : (
            <StyledHeaderLeft onClick={navigationGoBack} title={leftTitle} />
          )}
          {routes ? (
            <StyledHeaderBreadcrumbContainer>
              <Breadcrumb routes={routes} />
            </StyledHeaderBreadcrumbContainer>
          ) : null}
        </StyledHeaderLeftContainer>
        <StyledHeaderTitleContainer>
          {typeof headerTitle === 'string' ? (
            <StyledHeaderTitle>
              {textToUpperCase(headerTitle)}
            </StyledHeaderTitle>
          ) : React.isValidElement(headerTitle) ? (
            headerTitle
          ) : null}

          {showWarning ? (
            <StyledMarkedBadgeContainer>
              {plan?.isShowSubscriptionWarning && !showDescription ? (
                <StyledMarkedBadge
                  type={plan?.isTrialSubscription ? 'warning' : 'error'}
                  direction="twoWay"
                  onPress={handleNavigateToSubscriptionPage}>
                  <StyledDescription>
                    {plan?.isTrialSubscription
                      ? t('Trial subscription')
                      : plan?.isShowSubscriptionExpiredTime
                      ? `${t(
                          'Subscription will expire in ',
                        )} ${plan?.subscriptionExpiredAt(t)}`
                      : t('Current subscription is expired')}
                  </StyledDescription>
                </StyledMarkedBadge>
              ) : typeof headerTitleDescription === 'string' ? (
                <Description>{headerTitleDescription}</Description>
              ) : (
                headerTitleDescription || null
              )}
            </StyledMarkedBadgeContainer>
          ) : null}
        </StyledHeaderTitleContainer>

        <StyledHeaderRightContainer>
          {headerRight || null}
        </StyledHeaderRightContainer>
      </StyledHeader>
      {underHeaderContent ? (
        <StyledUnderHeaderContent>
          {underHeaderContent}
        </StyledUnderHeaderContent>
      ) : null}
    </StyledHeaderContainer>
  );
});
