import { EmployeeModel } from '../internal';

export enum EmployeeRole {
  Administrator = 'admin',
  Manager = 'manager',
  CASHIER = 'cashier',
  EMPLOYEE_SALARY_MANAGER = 'empl_slr_manager',
}

export enum EmployeeRoleCashierPermissionObjectType {
  CASH_BOX = 'cashbox',
}

export enum EmployeeRoleCashierAllowedOperations {
  INCOME = 'income',
  MOVE = 'move',
  OUTCOME = 'outcome',
  FISCAL = 'fiscal',
}

export interface EmployeeRoleCashierPermissionAllowedOperations {
  [EmployeeRoleCashierAllowedOperations.INCOME]: boolean;
  [EmployeeRoleCashierAllowedOperations.MOVE]: boolean;
  [EmployeeRoleCashierAllowedOperations.OUTCOME]: boolean;
  [EmployeeRoleCashierAllowedOperations.FISCAL]: boolean;
}

export interface EmployeeRoleCashierPermission {
  object_type: EmployeeRoleCashierPermissionObjectType;
  object_uuid?: string;
  object_allowed_operations: EmployeeRoleCashierPermissionAllowedOperations;
}

export interface EmployeeRoleAdminPermission {}
export interface EmployeeRoleManagerPermission {}
export interface EmployeeRoleSalaryManagerPermission {}

export interface EmployeeRoleType<T> {
  role: EmployeeRole;
  permissions: T[];
}

export const EMPLOYEE_ROLE_CASHIER_FORM_PARAMS: EmployeeRoleType<EmployeeRoleCashierPermission> =
  {
    role: EmployeeRole.CASHIER,
    permissions: [
      {
        object_type: EmployeeRoleCashierPermissionObjectType.CASH_BOX,
        object_uuid: undefined,
        object_allowed_operations: {
          income: true,
          move: true,
          outcome: true,
          fiscal: false,
        },
      },
    ],
  };

export const EMPLOYEE_ROLE_ADMIN_FORM_PARAMS: EmployeeRoleType<EmployeeRoleAdminPermission> =
  {
    role: EmployeeRole.Administrator,
    permissions: [],
  };

export const EMPLOYEE_ROLE_MANAGER_FORM_PARAMS: EmployeeRoleType<EmployeeRoleManagerPermission> =
  {
    role: EmployeeRole.Manager,
    permissions: [],
  };

export const EMPLOYEE_ROLE_SALARY_MANAGER_FORM_PARAMS: EmployeeRoleType<EmployeeRoleSalaryManagerPermission> =
  {
    role: EmployeeRole.EMPLOYEE_SALARY_MANAGER,
    permissions: [],
  };

export class EmployeeRoleFormDTO {
  public uuid: string;
  public roles: EmployeeRoleType<
    | EmployeeRoleCashierPermission
    | EmployeeRoleAdminPermission
    | EmployeeRoleManagerPermission
    | EmployeeRoleSalaryManagerPermission
  >[];
  public cashier_role?: EmployeeRoleType<EmployeeRoleCashierPermission>;
  public admin_role?: EmployeeRoleType<EmployeeRoleAdminPermission>;
  public manager_role?: EmployeeRoleType<EmployeeRoleManagerPermission>;
  public salary_manager_role?: EmployeeRoleType<EmployeeRoleSalaryManagerPermission>;
  public role_list?: EmployeeRole[];

  constructor(props?: EmployeeModel) {
    this.uuid = props?.uuid || '';
    this.roles = props?.role_permissions || [];
    this.role_list = (props?.role_permissions || []).map(({ role }) => role);
    this.cashier_role = EMPLOYEE_ROLE_CASHIER_FORM_PARAMS;
    this.admin_role = EMPLOYEE_ROLE_ADMIN_FORM_PARAMS;
    this.manager_role = EMPLOYEE_ROLE_MANAGER_FORM_PARAMS;
    this.salary_manager_role = EMPLOYEE_ROLE_SALARY_MANAGER_FORM_PARAMS;
  }
}
