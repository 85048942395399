import React from 'react';
import styled, { css } from 'styled-components';

const StyledBadgeItemContainer = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}
`;

const StyledStatData = styled.span`
  font-size: 30px;
  font-weight: 500;
`;

const StyledSubStatsComment = styled.div``;

interface IBadgeItemProps {
  color?: string;
  badgeStatsContent: any;
  badgeSubStatsComment: any;
}

export function BadgeItem(props: IBadgeItemProps): React.JSX.Element {
  const {
    color = '',
    badgeStatsContent = '',
    badgeSubStatsComment = '',
  } = props || {};

  return (
    <StyledBadgeItemContainer $color={color}>
      <StyledStatData>{badgeStatsContent}</StyledStatData>
      <StyledSubStatsComment>{badgeSubStatsComment}</StyledSubStatsComment>
    </StyledBadgeItemContainer>
  );
}
