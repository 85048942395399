import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useStateStoreDocumentItemList,
  useStopLoading,
  useStoredCompanies,
  useStoredStoreDocumentList,
} from '@hooks';
import { ITableProps, Table } from '@components/lib/libV2/DataDisplay';
import {
  StoreDocumentItemModel,
  StoreDocumentMappedType,
  StoreDocumentModel,
  StoreDocumentStatus,
  StoreDocumentType,
} from '@structure';
import {
  Button,
  DetailsView,
  ListDatePicker,
  TableIndexField,
} from '@components/lib/DataDisplay';
import {
  StoreDocItemsPriceDisabledView,
  StoreDocumentAmountTableField,
  StoreProductPriceView,
} from '../../Show';
import { StyledTitle } from '@components/lib/Styled';
import { IUseModalReturnType } from '@components/lib/libV2/hooks/useModal';
import styled from 'styled-components';
import { SessionStorageItems } from '@services/const';
import {
  correctPrice,
  head,
  last,
  toDateByFormat,
  toMoment,
} from '@services/helpers';
import {
  StoreSaleSummaryView,
  StoreRealizationInternalNumberField,
} from '../Show';
import { useStoreSale } from '../Managers';
import { List } from 'immutable';
import { SideWindow } from '@components/lib/Navigation';

import '../../../Orders/List/OrderList.less';
import '../../Forms/StoreProductListField.less';
import './StoreRealizationListModal.less';

export interface IStoreRealizationListModalProps
  extends Omit<IUseModalReturnType<StoreDocumentModel>, 'handleOnInit'> {}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 130px;

  width: 100%;
`;

const StyledDocumentTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<
  ITableProps<StoreDocumentModel>
>;

const StyledDocumentItemTable = styled(Table)`
  margin-bottom: 0;
` as React.ComponentType as React.FunctionComponent<
  ITableProps<StoreDocumentItemModel>
>;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.background.primary};
  width: 100%;
  border-radius: 7px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
`;

const StyledListDatePicker = styled(ListDatePicker)`
  min-width: 150px;
`;

const StyledDetailsView = styled(DetailsView)`
  box-shadow: none;
  border-color: #d9d9d9;
  text-align: right;

  height: auto;
`;

const StyledDetailsViewContainer = styled.div`
  position: absolute;
  bottom: 70px;
  left: 0;

  padding-left: 10px;
  padding-right: 10px;

  width: 100%;
  background-color: ${({ theme }) => theme.background.primary};
  z-index: 10;
`;

const StyledButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 7px;

  height: 70px;
  background-color: ${({ theme }) => theme.background.primary};
  width: 100%;
  z-index: 20;
`;

const StyledCancelButton = styled(Button)`
  width: fit-content;
`;

const StyledDraftButton = styled(Button)`
  width: fit-content;
  background-color: ${({ theme }) => theme.colors.success};

  &&&:hover,
  &&&:active,
  &&&:focus {
    background-color: ${({ theme }) => theme.colors.success};
    opacity: 0.7;
  }
`;

export default function StoreRealizationListModal({
  visible,
  handleCancel,
}: IStoreRealizationListModalProps): JSX.Element {
  const { defaultCompanyUuid, defaultCompany } = useStoredCompanies();
  const { t } = useTranslation();

  const { handleSelectDaftDocumentFromModal } = useStoreSale();

  const [selectedStoreDocument, setSelectedStoreDocument] =
    useState<StoreDocumentModel | null>(null);

  const orderDatesDates = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem(SessionStorageItems.storeDocumentSaleOutDates) ||
          JSON.stringify([toMoment(new Date()), toMoment(new Date())]),
      ),
    [],
  );

  const {
    documentList,
    limit,
    total,
    loading: loadingStoreDocumentList,
    error: errorStoreDocumentList,
    start_date,
    loadingMore,
    page,
    stats,

    handleRefreshStoreDocuments,
    handleSearchStoreDocuments,
  } = useStoredStoreDocumentList({
    companyUuid: defaultCompanyUuid,
    documentType: StoreDocumentType.OUT,
    mappedDocumentType: StoreDocumentMappedType.SALE_OUT,
    start_date: head(orderDatesDates),
    end_date: last(orderDatesDates),
    alwaysSendRequest: true,
    limit: 5,
    withoutSaveDates: true,
  });

  const {
    storeDocumentItemList,
    loading: storeDocumentItemListLoading,
    total: storeDocumentItemListTotal,
    limit: storeDocumentItemListLimit,
    page: storeDocumentItemListPage,
    refresh: storeDocumentItemListRefresh,
    handleSearchStoreDocumentItemList,
    setStoreDocumentItemList,
  } = useStateStoreDocumentItemList({
    documentUuid: '',
    loadOnInit: false,
    limit: 5,
  });

  const loading = useStopLoading({
    loading: loadingStoreDocumentList || loadingMore,
    error: errorStoreDocumentList,
    message: 'An error occurred during store sales invoices loading',
  });

  const columns = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className:
        'order-list-td order-list--middle-text realization-list-modal--transparent',
      fixed: 'left' as any,
      render: (document: StoreDocumentModel, items: any, index: number) => (
        <TableIndexField number={(page - 1) * limit + index + 1} />
      ),
    },
    {
      ellipsis: true,
      title: t('Internal number'),
      key: 'internal-number',
      className: 'realization-list-modal--transparent',
      fixed: 'left' as any,
      render: (document: StoreDocumentModel) => (
        <StoreRealizationInternalNumberField document={document} />
      ),
    },
    {
      ellipsis: true,
      title: `${t('Sum')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'sum',
      render: (document: StoreDocumentModel) => (
        <StoreProductPriceView
          isWarning={document?.doc_status_text === StoreDocumentStatus.DRAFT}
          document={document}
        />
      ),
    },
  ];

  const columnsItems = [
    {
      ellipsis: true,
      title: `№`,
      key: 'index',
      align: 'center' as any,
      className: 'order-list-td order-list--middle-text',
      fixed: 'left' as any,
      render: (
        documentItem: StoreDocumentItemModel,
        items: any,
        index: number,
      ) => (
        <TableIndexField
          number={
            (storeDocumentItemListPage - 1) * storeDocumentItemListLimit +
            index +
            1
          }
        />
      ),
    },
    {
      title: t('name-s'),
      key: 'name',
      render: (docItem: StoreDocumentItemModel) =>
        docItem?.product?.product_title,
    },
    {
      title: t('tAmount'),
      key: 'product_amount',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => (
        <StoreDocumentAmountTableField documentItem={docItem} />
      ),
    },
    {
      title: `${t('Price')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'price',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) => (
        <StoreDocItemsPriceDisabledView docItem={docItem} />
      ),
    },
    {
      title: `${t('Total')}, ${defaultCompany?.currency_symbol || ''}`,
      key: 'total',
      align: 'right' as any,
      render: (docItem: StoreDocumentItemModel) =>
        correctPrice(
          Number(docItem?.item_base_price || 0) *
            (docItem?.item_product_amount || 1),
        ),
    },
  ];

  return (
    <SideWindow
      title={
        <StyledTitleContainer>
          <StyledTitle fontSize={16} bold>
            {t('Sales invoices')}
          </StyledTitle>
          <StyledTitle fontSize={16} bold>{`${t('for')} ${toDateByFormat(
            start_date,
            'DD.MM.YYYY',
          )}`}</StyledTitle>
        </StyledTitleContainer>
      }
      open={visible}
      onClose={handleCancel}>
      <StyledContainer>
        <StyledHeaderContainer>
          <StyledTitle bold>{t('InvoicesT')}</StyledTitle>
          <StyledListDatePicker
            defaultDatePickerValue={
              start_date ? toMoment(start_date) : undefined
            }
            onChange={(date: any) => {
              handleSearchStoreDocuments({
                start_important: date,
                end_important: date,
                keywords: '',
                limit: 5,
              });
              sessionStorage.setItem(
                SessionStorageItems.storeDocumentSaleOutDates,
                JSON.stringify([date, date]),
              );
              setStoreDocumentItemList(List());
              setSelectedStoreDocument(null);
            }}
            loading={loading}
          />
        </StyledHeaderContainer>
        <StyledDocumentTable
          pageSize={limit}
          total={total}
          onChange={handleRefreshStoreDocuments}
          dataSource={documentList}
          columns={columns}
          loading={loading}
          page={page}
          onRow={(record: StoreDocumentModel) => ({
            className: `posting-list-td ${
              record?.uuid === selectedStoreDocument?.uuid
                ? 'realization-list-modal--selected'
                : ''
            }`,
            onClick: async () => {
              setSelectedStoreDocument(record);
              await handleSearchStoreDocumentItemList({
                documentUuid: record?.uuid,
                limit: 5,
              });
            },
          })}
        />
        <StyledHeaderContainer>
          <StyledTitle bold>{t('Positions')}</StyledTitle>
        </StyledHeaderContainer>

        <StyledDocumentItemTable
          pageSize={storeDocumentItemListLimit}
          total={storeDocumentItemListTotal}
          onChange={storeDocumentItemListRefresh}
          dataSource={storeDocumentItemList}
          columns={columnsItems}
          loading={!!selectedStoreDocument && storeDocumentItemListLoading}
          page={storeDocumentItemListPage}
          onRow={(record: StoreDocumentItemModel) => ({
            className: `posting-list-td `,
          })}
        />

        <StyledDetailsViewContainer>
          <StyledDetailsView titles={[]}>
            <StoreSaleSummaryView
              stats={stats}
              date={start_date}
              currency={defaultCompany?.currency_symbol || ''}
            />
          </StyledDetailsView>
        </StyledDetailsViewContainer>
        <StyledButtonContainer>
          <StyledCancelButton type="text" onClick={handleCancel}>
            {t('Close')}
          </StyledCancelButton>
          {selectedStoreDocument?.doc_status_text ===
          StoreDocumentStatus.DRAFT ? (
            <StyledDraftButton
              type="primary"
              onClick={() => {
                handleCancel();
                handleSelectDaftDocumentFromModal(selectedStoreDocument);
              }}>
              {t('Apply')}
            </StyledDraftButton>
          ) : null}
        </StyledButtonContainer>
      </StyledContainer>
    </SideWindow>
  );
}
