import { apiGet } from '@services/core/api';
import { contentApiUrl } from '@services/const';
import { toDateByFormat } from '@services/helpers';
import { IListSearchProps } from '@services/types';

import {
  EmployeeAccruedSalaryDTOListAnswer,
  IEmployeeAccruedSalaryDTOListAnswer,
} from '@structure/dto/EmployeeAccruedSalaryDTO';

export interface IEmployeeAccruedSalaryListProps extends IListSearchProps {
  companyUuid: string;
  month: number;
  year: number;
}

export interface IGetEmployeeAccruedSalaryListParams extends IListSearchProps {
  month: number;
  year: number;
}

/**
 * @desc Вибірка списку нарахованої заробітньої платні по співробітниках
 * @param {IEmployeeAccruedSalaryListProps} params
 * @return {Promise<IEmployeeAccruedSalaryDTOListAnswer>}
 **/
export async function getEmployeeAccruedSalaryList(
  params: IEmployeeAccruedSalaryListProps,
): Promise<IEmployeeAccruedSalaryDTOListAnswer> {
  const {
    keywords,
    offset = 0,
    limit = 10,
    companyUuid,
    month = Number(toDateByFormat(new Date(), 'MM')),
    year = Number(toDateByFormat(new Date(), 'YYYY')),
  } = params || {};

  const { accruals = [], total = 0 } = await apiGet<
    IGetEmployeeAccruedSalaryListParams,
    IEmployeeAccruedSalaryDTOListAnswer
  >(`${contentApiUrl}/companies/${companyUuid}/employees/accrued-salary`, {
    offset,
    limit,
    keywords,
    month,
    year,
  });

  return new EmployeeAccruedSalaryDTOListAnswer({
    accruals,
    total,
  });
}
