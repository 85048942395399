import React from 'react';
import { Text } from '../Format';
import { ButtonProps, Tooltip } from 'antd';
import { StyledIconButton } from '../Styled/Buttons';
import styled, { useTheme } from 'styled-components';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';

export interface IAddButtonProps extends Omit<ButtonProps, 'title' | 'color'> {
  title?: string | React.ReactNode;
  tooltipTitle?: string;
  children?: React.ReactNode;
  color?: string;
}

const StyledTooltip = styled(Tooltip)`
  width: auto;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 14px;
`;

export function AddButton({
  title,
  tooltipTitle,
  style,
  type = 'text',
  loading,
  children,
  color,
  icon,
  disabled,
  ...props
}: IAddButtonProps): JSX.Element {
  const theme: any = useTheme();

  return (
    <StyledTooltip title={tooltipTitle}>
      <StyledIconButton
        type={type}
        $useCustomStyle={type !== 'text'}
        style={style}
        loading={!!(title && loading)}
        disabled={!!loading || !!disabled}
        icon={
          loading ? (
            <StyledLoadingOutlined color={color || theme.colors.primary} />
          ) : (
            icon
          )
        }
        {...props}>
        {React.isValidElement(title) ? (
          title
        ) : title ? (
          <Text>{title}</Text>
        ) : (
          children || <PlusOutlined color={color || theme.colors.primary} />
        )}
      </StyledIconButton>
    </StyledTooltip>
  );
}
