import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  IProductCategoryDTOProps,
  IProductModelReturnType,
  IStoreDTOProps,
  ProductBarCodeFormat,
  ProductCategoryDTO,
  ProductCategoryMapper,
  ProductCategoryModel,
} from '../internal';
import { WithoutRecursion } from '../type';
import { correctPrice } from '@services/helpers';

export enum ProductType {
  DEFAULT = 'default',
}

export enum ProductMappedType {
  SALE = 'sale',
}

export const MAPPED_PRODUCT_TYPES = [
  ProductType.DEFAULT,
  ProductMappedType.SALE,
];

export const INVERT_MAPPED_PRODUCT_TYPES = {
  [ProductType.DEFAULT]: ProductType.DEFAULT,
  [ProductMappedType.SALE]: ProductType.DEFAULT,
};

export interface IProductPrice {
  price_uuid: string;
  price_value: string;
}

export interface IProductBalances {
  uuid: string;
  balance_total: number;
  created_at: string;
  updated_at: string;
  company_store: IStoreDTOProps;
}

export enum ProductStatus {
  ENABLE = 'enabled',
  DISABLED = 'disabled',
  ERROR = 'error',
  DELETED = 'deleted',
}

export interface IProductDTOProps {
  uuid: string;
  product_code: string;
  product_title: string;
  product_barcode: string;
  product_vendor_code: string;
  product_description: string;
  product_net_price: string;
  product_retail_price: string;
  created_at: string;
  updated_at: string;
  product_bar_code_format: ProductBarCodeFormat;
  category: IProductCategoryDTOProps;
  created_by: IEmployeeDTOProps;
  company: ICompanyDTOProps;
  product_prices: IProductPrice[];
  product_max_income_price: number;
  product_balances: IProductBalances[];
  product_min_amount: number;
  product_status: ProductStatus;
  product_photo: string;
}

export class ProductDTO {
  public uuid: string;
  public product_code: string;
  public product_title: string;
  public product_barcode: string;
  public product_vendor_code: string;
  public product_description: string;
  public product_net_price: string;
  public product_retail_price: string;
  public created_at: string;
  public updated_at: string;
  public category: ProductCategoryDTO;
  public created_by: EmployeeDTO;
  public company: ICompanyDTOProps;
  public product_max_income_price: number;
  public product_prices: IProductPrice[];
  public product_balances: IProductBalances[];
  public product_bar_code_format: ProductBarCodeFormat;
  public product_min_amount: number;
  public product_status: ProductStatus;
  public product_photo: string;

  constructor(
    props: IProductModelReturnType | IProductDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';
    this.product_code = props?.product_code || '';
    this.product_title = props?.product_title || '';
    this.product_barcode = props?.product_barcode || '';
    this.product_vendor_code = props?.product_vendor_code || '';
    this.product_description = props?.product_description || '';
    this.product_net_price = correctPrice(props?.product_net_price || 0);
    this.product_retail_price = correctPrice(props?.product_retail_price || 0);
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.product_max_income_price = props?.product_max_income_price || 0;
    this.product_prices = props?.product_prices || [];
    this.product_balances = props?.product_balances || [];
    this.product_min_amount = props?.product_min_amount || 0;
    this.product_status = props?.product_status || ProductStatus.DISABLED;
    this.product_photo = props?.product_photo || '';
    this.product_bar_code_format =
      props?.product_bar_code_format || ProductBarCodeFormat.EAN13;
    this.category =
      withoutRecursion.indexOf(WithoutRecursion.category) !== -1
        ? (props?.category as ProductCategoryDTO)
        : ProductCategoryMapper.toProductCategoryDTO(
            props?.category as unknown as ProductCategoryModel,
            [WithoutRecursion.product, ...withoutRecursion],
          );

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(
            props?.created_by as unknown as EmployeeModel,
            [WithoutRecursion.product, ...withoutRecursion],
          );
    // @ts-ignore
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(
            props?.company as unknown as CompanyModel,
            [WithoutRecursion.product, ...withoutRecursion],
          );
  }
}
