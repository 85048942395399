import {
  CompanyDTO,
  CompanyMapper,
  CompanyModel,
  EmployeeDTO,
  EmployeeMapper,
  EmployeeModel,
  ICompanyDTOProps,
  IEmployeeDTOProps,
  IFileModelReturnType,
  IScheduleCalendarDTOProps,
  ScheduleCalendarDTO,
  ScheduleCalendarMapper,
  ScheduleCalendarModel,
} from '../internal';
import { WithoutRecursion } from '../type';

export enum FileStatusesText {
  Enabled = 'enabled',
  Disabled = 'disabled',
  Deleted = 'deleted',
  Error = 'error',
}

export enum FileTypes {
  Schedule = 'schedule',
}

export interface IFileDTOProps {
  uuid: string;
  file_original_name: string;
  file_url: string;
  fileable_type: FileTypes;
  file_status_text: FileStatusesText;
  created_at: string;
  updated_at: string;
  fileable: IScheduleCalendarDTOProps;
  created_by: IEmployeeDTOProps;
  company: ICompanyDTOProps;
}

export class FileDTO {
  public uuid: string;
  public file_original_name: string;
  public file_url: string;
  public fileable_type: FileTypes;
  public file_status_text: FileStatusesText;
  public created_at: string;
  public updated_at: string;
  public fileable: ScheduleCalendarDTO;
  public created_by: EmployeeDTO;
  public company: ICompanyDTOProps;

  constructor(
    props: IFileModelReturnType | IFileDTOProps,
    withoutRecursion: WithoutRecursion[] = [],
  ) {
    this.uuid = props?.uuid || '';

    this.file_original_name = props?.file_original_name || '';
    this.file_url = props?.file_url || '';
    this.fileable_type = props?.fileable_type || FileTypes?.Schedule;
    this.file_status_text = props?.file_status_text || FileStatusesText.Enabled;
    this.created_at = props?.created_at || '';
    this.updated_at = props?.updated_at || '';
    this.fileable =
      withoutRecursion.indexOf(WithoutRecursion.scheduleCalendar) !== -1
        ? (props?.fileable as ScheduleCalendarDTO)
        : ScheduleCalendarMapper.toScheduleCalendarDTO(
            props?.fileable as unknown as ScheduleCalendarModel,
            [WithoutRecursion.file, ...withoutRecursion],
          );

    this.created_by =
      withoutRecursion.indexOf(WithoutRecursion.employee) !== -1
        ? (props?.created_by as EmployeeDTO)
        : EmployeeMapper.toEmployeeDTO(
            props?.created_by as unknown as EmployeeModel,
            [WithoutRecursion.product, ...withoutRecursion],
          );

    // @ts-ignore
    this.company =
      withoutRecursion.indexOf(WithoutRecursion.company) !== -1
        ? (props?.company as CompanyDTO)
        : CompanyMapper.toCompanyDTO(
            props?.company as unknown as CompanyModel,
            [WithoutRecursion.product, ...withoutRecursion],
          );
  }
}
