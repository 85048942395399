import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fontSize: '14px',
  borderRadius: '4px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',

  border: {
    baseBorderRadius: '5px',
  },

  ant: {
    colorTextHeading: 'rgba(0, 0, 0, 0.85)',
    textColor: 'rgba(0, 0, 0, 0.65)',
    textColorSecondary: 'rgba(0, 0, 0, 0.45)',
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
  },

  colors: {
    default: {
      hover: {
        color: '#4096ff',
        borderColor: '#4096ff',
      },
    },
    print: {
      color: '#ffffff',
      backgroundColor: '#4096ff',
      hover: {
        color: '#001d66',
      },
    },
    primary: '#404041',
    secondary: '#535354',
    disable: 'rgba(64, 64, 65, 0.25)',
    formText: '#737374',
    white: '#fff',
    whiteDisabled: 'rgba(255, 255, 255, .85)',
    lightenPrimary: '#8c8c8e',
    lightDisable: 'rgba(64, 64, 65, 0.3)',
    appLightDisable: 'rgba(64, 64, 65, 0.15)',
    lightError: 'rgba(254, 241, 241, 0.55)',
    link: '#4f7cac',
    yellow: '#f1d693',
    brown: '#897456',
    darkenLink: '#385778',
    info: '#1890ff',
    warning: '#d98c3a',
    success: '#05b582',
    error: '#b13c3c',
    errorBackground: '#fc7262',
    disabledError: '#c07e71',
    heading: 'rgba(64, 64, 64, 0.85)',
    text: 'rgba(64, 64, 65, 0.65)',
    textSecondary: 'rgba(64, 64, 65, 0.45)',
    disabled: '#a0a0a0',
    lightenApp: 'rgba(255, 255, 255,.8)',
    componentBackground: '#d1d4cc',
    appBorder: '#a3b4b5',
    textLighten: '#4d4d4d',
    black: 'rgba(0,0,0)',
    mask: 'rgba(64, 64, 65, 0.12)',
    background:
      'linear-gradient(to left, rgb(255, 255, 255), rgba(64, 64, 65, 0.05))',
    mainBackground: '#3e58cf',
    accent: '#E24411', //'rgb(37, 150, 190)',
    lightAccent: '#e07755',
    eventPastTime: 'rgba(64, 64, 64, 0.85)',
    eventAccentPastTime: 'rgba(255, 223, 108, 0.85)',
    green: '#4eb15b',
    slateBlue: '#6e55df',
    darkOrchid: '#9d37b5',
    mint: '#51bf97',
    lightCherry: '#d94658',
    blueGrayCrayola: '#5e95c5',
    greyBlackberry: '#6a6c73',
    yellowGold: '#cd9c37',
    thirdTextColor: '#fff',
    orange: '#EF8E02',
    buttonPrimaryColor: '#E24411',
    buttonSecondaryColor: '#EF8E02',
    buttonShadowColor: 'rgba(254, 111, 40, 0.5)',
    buttonActivePrimaryColor: '#D63C0A',
    buttonActiveSecondaryColor: '#DE8401',
    buttonActiveShadowColor: '#FE6F28',
    placeholder: 'rgba(0, 0, 0, 0.88)',
    text_colors: {
      secondary: 'rgba(64, 64, 65, 0.75)',
    },
    extraLight: '#F9F9F9',
    primaryColor: {
      background: '#05b582',
      activeBackground: 'rgba(5, 181, 130, 0.1)',
    },
  },

  colorsV2: {
    black: '#000000',
    corporatePrimary: 'rgb(226, 68, 17)',
    corporateBgColor: '#1F3044',

    linkColor: '#3e77aa',

    employeeOpenedShift: '#4EB15B',
    employeeClosedShift: '#E24411',

    buttonPrimaryTextColor: '#FFF',
    buttonPrimaryColor: '#05B582',
    buttonPrimaryActiveColor: '#25c290',
    buttonPrimaryBorderColor: '#279c7b',

    buttonSecondaryTextColor: '#05B582',
    buttonSecondaryBgColor: '#05B5821A',
    buttonSecondaryActiveColor: '#25c290',
    buttonSecondaryBorderColor: '#279c7b',

    buttonDangerBgColor: 'rgb(225 29 72 / var(--tw-bg-opacity))',
    buttonDangerActiveBgColor: 'rgb(244 63 94 / var(--tw-bg-opacity))',

    outlayBgColor: '#FFF1F2',
    allBgColor: '#FFF7ED',

    buttons: {
      activeState: '#67cd87',
      passiveState: '#ff8d8f',
    },

    sidebar: {
      title: '#FFF',
      menu: '#FFF',
      activeMenu: '#751c18',
    },
    detailsView: {
      category: {
        background: '#fafafa',
        link: 'rgb(79, 124, 172)',
      },
    },
  },

  background: {
    primary: '#fff',
    layout: '#f5f5f5',
    sidebar: '#3a1622',

    primary1: '#4b49ac',
    primary2: '#12a2b8',
    comment: '#fff8dc',
  },

  transparent: {
    error: 'rgba(177, 60, 60, 0.8)',
    warning: 'rgba(217, 140, 58, 0.8)',
    success: 'rgba(93, 146, 114,  0.8)',
    link: 'rgba(79,124,172,  0.8)',
  },

  tabs: {
    success: '#eaf9ef',
    link: '#eef7ff',
    warning: '#fff4d0',
    error: '#c98282',
    primary: '#979797',
    beige: 'rgb(240, 226, 211)',
  },

  badges: {
    all: '#eef7ff',
    employee: {
      salary: '#8fffdb',
      schedule: '#ffe0d0',
    },
  },
};

const antTheme = {};

export { theme, antTheme };
